import { Divider, Tag } from 'antd'
import { Button, Form, Input, Message, Table, Pagination } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'

/**
 * 手机白名单
 */
export default class PhoneWhitelist extends Component {

    static EmptyResult = { records: [] }

    constructor(props) {
        super(props)

        this.state = {
            columns: [
                {
                    label: "添加日期",
                    prop: "createTime",
                },
                {
                    label: "手机号",
                    prop: "mobile",
                },
                {
                    label: "备注",
                    prop: "remark"
                }
            ],
            listBody: PhoneWhitelist.EmptyResult,
            queryForm: {
                current: 1,
                mobile: '',
                size: 10
            },
            importLoading: false
        }
    }

    componentDidMount() {
        this.getList()
    }

    changeQueryForm(name, value, listRefresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })

        this.setState({ queryForm }, () => {
            if (listRefresh) this.getList()
        })
    }





    async getList() {
        const { body } = await apiV2.getMobileWhitelist({ param: this.state.queryForm })
        this.setState({
            listBody: body || PhoneWhitelist.EmptyResult
        })
    }

    /**
     * 导入接口版本
     */
    importVersion = 2;

    importWhitelist() {
        const fileInputEle = document.createElement('input')
        const that = this;
        fileInputEle.setAttribute('type', 'file')
        fileInputEle.style.display = 'none'
        fileInputEle.addEventListener('change', async () => {
            if (fileInputEle.files && fileInputEle.files.length) {
                try {
                    if (that.importVersion === 2) {
                        that.setState({ importLoading: true });
                        const { ok } = await apiV2.importWhitelistV2({ file: fileInputEle.files[0] })
                        Message({
                            message: `导入${ok ? '成功' : '失败'}`,
                            type: ok ? 'success' : 'error'
                        })
                    } else {
                        const { message, success } = await apiV2.importWhitelist({ file: fileInputEle.files[0] })
                        Message({
                            message: message,
                            type: success ? 'success' : 'error'
                        })
                    }
                } finally {
                    that.setState({ importLoading: false });
                }
                this.getList()
            }
        })
        fileInputEle.click()
    }

    dowImportWhitelist() {
        window.location.href = '/上传手机白名单模板.xlsx'
    }

    render() {
        const { queryForm, listBody, importLoading } = this.state
        return (
            <>
                <Form inline>
                    <Form.Item label="手机号">
                        <Input value={queryForm.mobile} onChange={(v) => this.changeQueryForm('mobile', v)}
                            placeholder="手机号" />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.getList()} type="primary">{'搜索'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button loading={importLoading} disabled={importLoading} onClick={() => this.importWhitelist()} type="success">{'导入白名单'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.dowImportWhitelist()} type="text">{'下载导入白名单模版'}</Button>
                    </Form.Item>
                </Form>
                <Divider orientation="left">{'已添加的手机号'}</Divider>

                {1 ? <><Table border columns={this.state.columns} data={listBody.records} />
                    <Pagination layout="total, sizes, prev, pager, next, jumper"
                        total={listBody.total} pageSizes={[10, 20, 50, 100, 200, 300, 400]}
                        pageSize={queryForm.size} currentPage={queryForm.current}
                        onSizeChange={(v) => this.changeQueryForm('size', v, true)}
                        onCurrentChange={(v) => this.changeQueryForm('current', v, true)}
                    />
                </> :
                    listBody.records.map(({ mobile }) => <Tag key={mobile} color="magenta">{mobile}</Tag>)}
            </>)
    }
}