import { Alert, Button, Message, Progress, Table, Tag } from 'element-react'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import apiV2 from '../../../api/api-v2'
import { Descriptions } from 'antd'

/**
 * 米融多头B
 */
export default class MiRongDTB extends Component {

    static propTypes = {
        userId: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = {
            result: null,
            loading: false,
            courtRiskColumns: [{
                label: '审核日期',
                prop: 'sort_time_string',
                align: 'center',
                fixed: true,
            }, {
                label: '类型',
                prop: 'data_type',
                align: 'center',
            }, {
                label: '摘要说明',
                prop: 'summary',
                align: 'center',
            }, {
                label: '匹配度',
                prop: 'compatibility',
                align: 'center',
                render: ({ compatibility }) => <Progress textInside percentage={compatibility * 100} status="success" />
            }],
            personalLoanDemandColumns: [{
                label: '期限',
                prop: 'name',
                align: 'center',
                fixed: true,
            }, {
                label: '⼀般消费分期平台',
                prop: 'common_amortize_platform',
                align: 'center',
            }, {
                label: '信⽤卡',
                prop: 'credit_card',
                align: 'center',
            }, {
                label: '⼤型消费⾦融公司',
                prop: 'large_finance_platform',
                align: 'center',
            }, {
                label: '其它类型贷款公司',
                prop: 'other_platform',
                align: 'center',
            }, {
                label: 'P2P平台',
                prop: 'p2p_platform',
                align: 'center',
            }, {
                label: '⼩额贷款公司',
                prop: 'petty_loan_platform',
                align: 'center',
            }, {
                label: '贷款需求总数',
                prop: 'platform_total',
                align: 'center',
            },],
            personalOverdueHistoryColumns: [
                {
                    label: '逾期天数 (S:单期 M:多期)',
                    prop: 'overdue_day',
                    align: 'center',
                    fixed: true,
                }, {
                    label: '逾期⾦额区间（元）',
                    prop: 'overdue_money',
                    align: 'center',
                }, {
                    label: '逾期时间',
                    prop: 'overdue_time',
                    align: 'center',
                }, {
                    label: '是否结清',
                    prop: 'settlement',
                    align: 'center',
                }
            ]
        }
    }

    componentDidMount() {
        this.getMiRongDT()
    }


    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.setState({
                dialogVisible: true
            })

            this.getMiRongDT()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async getMiRongDT(userId = this.props.userId) {
        if (!userId) {
            this.setState({ result: null })
            return
        }
        const { body } = await apiV2.getMiRongDuoTouB({ userId })
        if (body) {
            console.log(JSON.parse(body))
            this.setState({ result: JSON.parse(body) })
        } else {
            this.setState({ result: null })
        }
    }

    async refreshMiRongGZ({ userId = this.props.userId, refresh = false }= { userId: this.props.userId, refresh: false }) {
        this.setState({ loading: true })
        try {
            if (!userId) return
            const { message, success } = await apiV2.refreshMiRongDuoTouB({ userId, refresh })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            await this.getMiRongDT(userId)
        } finally {
            this.setState({ loading: false })
        }
    }

    personalLoanDemandTableBuild(name, object) {
        object = object || {}
        object.name = name
        return object
    }

    render() {
        const { courtRiskColumns, personalLoanDemandColumns, personalOverdueHistoryColumns, result, loading } = this.state
        const baseInfo = (result || {})['base_info'] || {}
        const personalLoanDemand = (result || {})['personal_loan_demand'] || {}
        const personalLoanF = (result || {})['personal_loan_f'] || {}
        const personalLoanS = (result || {})['personal_loan_s'] || {}
        const personalOverdueHistory = (result || {})['personal_overdue_history'] || {}
        const relevanceRiskCheck = (result || {})['relevance_risk_check'] || {}
        const riskListCheck = (result || {})['risk_list_check'] || {}

        const personalLoanDemandTable = [
            this.personalLoanDemandTableBuild('7天内', personalLoanDemand['wx_datad7']),
            this.personalLoanDemandTableBuild('1月内', personalLoanDemand['wx_datam1']),
            this.personalLoanDemandTableBuild('3月内', personalLoanDemand['wx_datam3'])
        ]

        return result ? (
            <>
                <Button loading={loading} style={{ marginBottom: '.5rem' }} type="success" onClick={_ => this.refreshMiRongGZ({ refresh: true })}>{'刷新最新数据'}</Button>

                <Alert title={`综合评分：${result['multiple_score']}（${result['score_norm_explain']}）, 审核建议：${result['verify_recomment']}`} />
                <Descriptions title="基本信息" bordered>
                    <Descriptions.Item label="姓名">{baseInfo.name}</Descriptions.Item>
                    <Descriptions.Item label="年龄">{baseInfo.age}</Descriptions.Item>
                    <Descriptions.Item label="身份证号码">{baseInfo.ident_number}</Descriptions.Item>
                    <Descriptions.Item label="⼿机号码">{baseInfo.phone}</Descriptions.Item>
                    <Descriptions.Item label="户籍">{baseInfo.id_card_city}</Descriptions.Item>
                    <Descriptions.Item label="号码归属地">{baseInfo.mobile_address_city}</Descriptions.Item>
                </Descriptions>
                
                <h2>{'近期贷款需求'}</h2>
                <Table columns={personalLoanDemandColumns} data={personalLoanDemandTable} />

                <Descriptions title="贷款放款信息" bordered>
                    <Descriptions.Item label="消费分期类放款机构数">{personalLoanF['cflenders']}</Descriptions.Item>
                    <Descriptions.Item label="最近⼀次距今天数">{personalLoanF['distance_now_days']}</Descriptions.Item>
                    <Descriptions.Item label="近1个⽉放款次数">{personalLoanF['lend_number1']}</Descriptions.Item>
                    <Descriptions.Item label="近3个⽉放款次数">{personalLoanF['lend_number3']}</Descriptions.Item>
                    <Descriptions.Item label="近6个⽉放款次数">{personalLoanF['lend_number6']}</Descriptions.Item>
                    <Descriptions.Item label="最近⼀次放款时间">{personalLoanF['lend_time']}</Descriptions.Item>
                    <Descriptions.Item label="近12个⽉放款机构总数">{personalLoanF['lenders']}</Descriptions.Item>
                    <Descriptions.Item label="贷款时⻓">{personalLoanF['loanday']}</Descriptions.Item>
                    <Descriptions.Item label="⽹络贷款类放款机构数">{personalLoanF['nllenders']}</Descriptions.Item>
                    <Descriptions.Item label="近1个⽉还款失败次数">{personalLoanF['repay_fail1']}</Descriptions.Item>
                    <Descriptions.Item label="近12个⽉还款失败次数">{personalLoanF['repay_fail12']}</Descriptions.Item>
                    <Descriptions.Item label="近1个⽉还款成功次数">{personalLoanF['repay_succ1']}</Descriptions.Item>
                    <Descriptions.Item label="近12个⽉还款成功次数">{personalLoanF['repay_succ12']}</Descriptions.Item>
                    <Descriptions.Item label="是否查得">{personalLoanF['type']}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="贷款申请信息" bordered>
                    <Descriptions.Item label="近12个⽉申请机构总数">{personalLoanS['apply_mechanism_number']}</Descriptions.Item>
                    <Descriptions.Item label="近1个⽉申请次数">{personalLoanS['apply_time1']}</Descriptions.Item>
                    <Descriptions.Item label="近3个⽉申请次数">{personalLoanS['apply_time3']}</Descriptions.Item>
                    <Descriptions.Item label="近6个⽉申请次数">{personalLoanS['apply_time6']}</Descriptions.Item>
                    <Descriptions.Item label="近12个⽉申请次数">{personalLoanS['apply_time12']}</Descriptions.Item>
                    <Descriptions.Item label="消费分期类申请机构数">{personalLoanS['consumer_apply_mechanism_number']}</Descriptions.Item>
                    <Descriptions.Item label="距离最近⼀次申请⽇期的天数">{personalLoanS['distance_now_days']}</Descriptions.Item>
                    <Descriptions.Item label="最近⼀次申请⽇期">{personalLoanS['last_apply_time']}</Descriptions.Item>
                    <Descriptions.Item label="⽹络贷款类申请机构数">{personalLoanS['network_loan_apply_mechanis_mnumber']}</Descriptions.Item>
                    <Descriptions.Item label="是否查得">{personalLoanS['type']}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="历史逾期记录" bordered>
                    <Descriptions.Item label="近6个⽉逾期机构总数">{personalOverdueHistory['overdue_mechanism_number']}</Descriptions.Item>
                    <Descriptions.Item label="近6个⽉逾期总次数">{personalOverdueHistory['overdue_total_counts']}</Descriptions.Item>
                    <Descriptions.Item label="近6个⽉逾期总⾦额（元）">{personalOverdueHistory['overdue_total_money']}</Descriptions.Item>
                    <Descriptions.Item label="是否查得">{personalOverdueHistory['type']}</Descriptions.Item>
                    <Descriptions.Item label="近6个⽉未结清逾期次数">{personalOverdueHistory['uncleared_counts']}</Descriptions.Item>
                    <Descriptions.Item label="逾期标志">{personalOverdueHistory['yuqi_boolean']}</Descriptions.Item>
                </Descriptions>
                <h2>{'历史逾期详情'}</h2>
                <Table columns={personalOverdueHistoryColumns} data={personalOverdueHistory['data_list']} />

                <Descriptions title="关联⻛险检测" bordered>
                    <Descriptions.Item label="3个⽉身份证关联⼿机号次数">{relevanceRiskCheck['ident_to_phone_counts']}</Descriptions.Item>
                    <Descriptions.Item label="3个⽉⼿机号关联身份证次数">{relevanceRiskCheck['phone_to_ident_counts']}</Descriptions.Item>
                </Descriptions>

                <Descriptions title="⻛险名单检测" bordered>
                    <Descriptions.Item label="⻋辆租赁违约名单">{riskListCheck['car_rental_break_contract_list']}</Descriptions.Item>
                    <Descriptions.Item label="户籍位于⾼⻛险集中地区">{riskListCheck['census_register_hign_risk_area']}</Descriptions.Item>
                    <Descriptions.Item label="法院失信名单">{riskListCheck['court_break_faith_list']}</Descriptions.Item>
                    <Descriptions.Item label="法院结案名单">{riskListCheck['court_case_list']}</Descriptions.Item>
                    <Descriptions.Item label="法院执⾏名单">{riskListCheck['court_execute_list']}</Descriptions.Item>
                    <Descriptions.Item label="信贷逾期名单">{riskListCheck['credit_overdue_list']}</Descriptions.Item>
                    <Descriptions.Item label="犯罪通缉名单">{riskListCheck['crime_manhunt_list']}</Descriptions.Item>
                    <Descriptions.Item label="⾼⻛险关注名单">{riskListCheck['hign_risk_focus_list']}</Descriptions.Item>
                    <Descriptions.Item label="⽋税公司法⼈代表名单">{riskListCheck['owing_taxes_legal_person_list']}</Descriptions.Item>
                    <Descriptions.Item label="⽋税名单">{riskListCheck['owing_taxes_list']}</Descriptions.Item>
                    <Descriptions.Item label="特殊关注名单">{riskListCheck['personal_fraud_blacklist']}</Descriptions.Item>
                    <Descriptions.Item label="故意违章乘⻋名单">{riskListCheck['riding_break_contract_list']}</Descriptions.Item>
                    <Descriptions.Item label="通信⼩号库">{riskListCheck['small_number_base']}</Descriptions.Item>
                    <Descriptions.Item label="助学贷款⽋费历史">{riskListCheck['student_loan_arrearage_list']}</Descriptions.Item>
                    <Descriptions.Item label="虚拟号码库">{riskListCheck['virtual_number_base']}</Descriptions.Item>
                </Descriptions>

                <h2>{'命中⻛险标注'}</h2>
                {(result['hit_risk_tagging'] || []).map(s => <Tag style={{ marginRight: '.5rem' }} key={s}>{s}</Tag>)}

                <h2>{'法院⻛险信息'}</h2>
                <Table columns={courtRiskColumns} data={result['court_risk_info_list']} />

            </>
        ) : <Button loading={loading} type="danger" onClick={_ => this.refreshMiRongGZ()}>{'点击获取米融多头（B）'}</Button>
    }
}