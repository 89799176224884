import { Button, Dialog, Form, Input } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../../api/api-v2'
import Paste from '../../../components/paste'
import Text from '../../../components/text'
import PropTypes from 'prop-types'

/**
 * 获取登陆短信验证码
 */
export default class GetLoginSMSCodePhone extends Component {

    static propTypes = {
        dialogVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func
    }

    constructor(props) {
        super(props)

        this.state = {
            phone: '',
            code: '-',
        }
    }


    // 从服务器获取数据
    async serverGet(phone = this.state.phone) {
        if (phone) {
            const { message, success, body } = await apiV2.getCodeByMobile({ mobile: phone })

            if (!body || !success) {
                this.setState({ code: message })
            } else {
                this.setState({ code: body })
            }
        } else {
            this.setState({ code: '-' })
        }
    }


    onCancel() {
        const onClose = this.props.onClose
        if (onClose) onClose()
    }

    render() {

        return (
            <Dialog
                title="提示"
                size="tiny"
                visible={this.props.dialogVisible}
                onCancel={() => this.onCancel()}
                lockScroll={false}
            >
                <Dialog.Body style={{ textAlign: 'center' }}>
                    <Form inline>
                        <Form.Item>
                            <Input placeholder="手机号" value={this.state.phone} onChange={phone => this.setState({ phone })} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="success" onClick={() => this.serverGet()}>{'开始获取'}</Button>
                        </Form.Item>
                    </Form>
                    <Paste content={<Text color="red" size="4rem">{this.state.code}</Text>} />
                </Dialog.Body>
            </Dialog>)
    }
}