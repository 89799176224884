export default {
    /**
     * 贷款总机构数
     */
    totalNumberLoanInstitutions: {
        '1': '<9',
        '2': '9至14',
        '3': '>14'
    },
    /**
     * 贷款已结清机构数
     */
    numberInstitutionsWithSettledLoans: {
        '1': '<5',
        '2': '5至9',
        '3': '9至14',
        '4': '14至17',
        '5': '>17'
    },
    /**
     * 消费金融类机构数
     */
    numberConsumerFinanceInstitutions: {
        '1': '<1',
        '2': '1至2',
        '3': '2至4',
        '4': '>4'
    },
    /**
    * 网络贷款类机构数(现金贷)
    */
    numberNetworkLoanInstitutions: {
        '1': '<1',
        '2': '1至5',
        '3': '5至11',
        '4': '>11'
    },
    /**
    * 历史贷款机构成功还款笔数
    */
    numberSuccessfulRepaymentsHisLoanIns: {
        '1': '<2',
        '2': '2至10',
        '3': '10至34',
        '4': '34至60',
        '5': '>60'
    },
    /**
    * 历史贷款机构交易失败笔数
    */
    numberFailRepaymentsHisLoanIns: {
        '1': '<3',
        '2': '3至5',
        '3': '5至10',
        '4': '>10'
    },
    /**
     * 信用贷款时长
     */
    creditLoanDuration: {
        '1': '<220',
        '2': '220至450',
        '3': '450至520',
        '4': '>520'
    },

    /**
     * 最近一次交易距离当前时间
     */
    distanceFromLatestTransactionCurrentTime: {
        '1': '<5',
        '2': '5至25',
        '3': '>25'
    },

    /**
    * 最近7天_交易失败次数
    */
    last7DaysTransactionFailureTimes: {
        '1': '<1',
        '2': '1到2',
        '3': '>2'
    },
    /**
    * 最近14天_交易失败次数
    */
    last14DaysTransactionFailureTimes: {
        '1': '<1',
        '2': '1到3',
        '3': '>3'
    },
    /**
    * 最近21天_交易失败次数
    */
    last21DaysTransactionFailureTimes: {
        '1': '<1',
        '2': '1到3',
        '3': '>3'
    },
    /**
    * 最近30天_交易失败次数
    */
    last30DaysTransactionFailureTimes: {
        '1': '<1',
        '2': '1到3',
        '3': '>3'
    },
    /**
    * 当前逾期机构数
    */
    currentOverdueInstitutions: {
        '1': '1到2',
        '2': '2到4',
        '3': '>4'
    },
    /**
    * 最近一次还款成功距离当前天数
    */
    lastRepaymentSucceededDaysFromCurrent: {
        '1': '<5',
        '2': '5到50',
        '3': '50到160',
        '4': '>160'
    },
    /**
    * 近20次还款中还款成功笔数
    */
    nearly20TimesNumberSuccessfulRepayments: {
        '1': '<2',
        '2': '2到11',
        '3': '11到13',
        '4': '>13',
    },

    /**
     * @deprecated Since version 1.0. Will be deleted in version 3.0. Use bar instead.
     * 近20次还款中还款成功笔数(小贷担保类)
     */
    nearly20TimesNumberSuccessfulRepaymentsSmalLoan: {
    },
    /**
     * 近5次还款中还款成功总金额
     */
    totalAmountSuccessfullyRepaidLast5Times: {
        '1': '<4000',
        '2': '4000到8000',
        '3': '>8000',
    },
    /**
     * 近20次还款中还款成功总金额
     */
    totalAmountSuccessfullyRepaidLast20Times: {
        '1': '<2000',
        '2': '2000到25000',
        '3': '>25000'
    },

    /**
    * 当前逾期金额
    */
    currentOverdueAmount: {
        '1': '<1000',
        '2': '1000到2000',
        '3': '2000到3000',
        '4': '3000到5000',
        '5': '5000到7000',
        '6': '7000到11000',
        '7': '>11000'
    },
    /**
    * 近7天小贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent7Days: {
        '1': '<1000',
        '2': '1000到1600',
        '3': '>1600',
    },
    /**
    * 近21天小贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent21Days: {
        '1': '<1000',
        '2': '1000到2000',
        '3': '>2000',
    },
    /**
    * 近30天小贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent30Days: {
        '1': '<1000',
        '2': '1000到4200',
        '3': '>4200',
    },
    /**
    * 近90天小贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent90Days: {
        '1': '<1000',
        '2': '1000到4600',
        '3': '>4600',
    },
    /**
    * 近180天小贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent180Days: {
        '1': '<1000',
        '2': '1000到4600',
        '3': '>4600',
    },
    /**
    * 近360天小贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent360Days: {
        '1': '<1000',
        '2': '1000到4800',
        '3': '>4800',
    },
    /**
    * 近5次交易小贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent5Trade: {
        '1': '<1000',
        '2': '1000到2000',
        '3': '>2000',
    },
    /**
    * 近100次交易小贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent100Trade: {
        '1': '<1000',
        '2': '1000到4600',
        '3': '>4600',
    },
    /**
    * 近360新增机构贷担保类交易金额最大值
    */
    maxAmountSmallLoanGuaTransRecent360Mech: {
        '1': '<1000',
        '2': '1000到4800',
        '3': '>4800',
    },

    /**
     * 近30天_小贷担保类_还款成功_还款金额_最大值
     */
    maxOkRepayAmountSmallLoanGuarantee30Days: {
        '1': '<1000',
        '2': '1000到1400',
        '3': '>1400',
    },
    /**
    * 近90天_小贷担保类_还款成功_还款金额_最大值
    */
    maxOkRepayAmountSmallLoanGuarantee90Days: {
        '1': '<1000',
        '2': '1000到2000',
        '3': '>2000',
    },

    /**
     * 近100次交易_小贷担保类_还款成功_还款金额_最大值
     */
    maxOkRepayAmountSmallLoanGuarantee100Trade: {
        '1': '<1000',
        '2': '1000到4600',
        '3': '>4600',
    },

    /**
     * 近90新增机构_小贷担保类_还款成功_还款金额_最大值
     */
    maxOkRepayAmountSmallLoanGuarantee90Mech: {
        '1': '<1000',
        '2': '1000到2000',
        '3': '>2000',
    },

    /**
     * 近5次交易_小贷担保类_因交易能力不足导致失败_交易金额_最 大值
     */
    last5TransactionsSmallLoanFailureDueAbilityAmountMax: {
        '1': '<1000',
        '2': '1000到1800',
        '3': '>1800',
    },

    /**
     * 近5次交易_小贷担保类_因交易能力不足导致失败_交易金额_最 小值
     */
    last5TransactionsSmallLoanFailureDueAbilityAmountMin: {
        '1': '<1000',
        '2': '1000到1600',
        '3': '>1600',
    },


    /**
    * 近20次交易_小贷担保类_因交易能力不足导致失败_交易金额_最 大值
    */
    last20TransactionsSmallLoanFailureDueAbilityAmountMax: {
        '1': '<1000',
        '2': '1000到2000',
        '3': '>2000',
    },

    /**
    * 近50次交易_小贷担保类_因交易能力不足导致失败_交易金额_最 大值
    */
    last50TransactionsSmallLoanFailureDueAbilityAmountMax: {
        '1': '<1000',
        '2': '1000到2000',
        '3': '>2000',
    },

    /**
    * 近90天_小贷担保类_因交易能力不足导致失败_交易金额_最 大值
    */
    last90DaysSmallLoanFailureDueAbilityAmountMin: {
        '1': '<300',
        '2': '300到1300',
        '3': '>1300',
    },

    /**
     * 近30天_小贷担保类_因交易能力不足导致失败_交易金额_最 大值
     * Start
     */
    last30DaysSmallLoanFailureDueAbilityAmountMin: {
        '1': '<3000',
        '2': '3000到8000',
        '3': '>8000',
    },

    /**
     * 近5次总和_小贷担保类_因交易能力不足导致失败_交易金额_最 大值
     */
    last5SumSmallLoanFailureDueAbilityAmount: {
        '1': '<3000',
        '2': '3000到8000',
        '3': '>8000',
    },

    /**
     * 近20次总和_小贷担保类_因交易能力不足导致失败_交易金额_最 大值
     */
    last20SumSmallLoanFailureDueAbilityAmount: {
        '1': '<3000',
        '2': '3000到9000',
        '3': '>9000',
    },

    /**
    * 近100笔总和_小贷担保类_因交易能力不足导致失败_交易金额_最 大值
    */
    last100SumSmallLoanFailureDueAbilityAmount: {
        '1': '<2',
        '2': '2-6',
        '3': '6-14',
        '4': '>14',
    },

    /**
     * 近180次平局_小贷担保类_因交易能力不足导致失败_交易金额
     */
    last180AveSmallLoanFailureDueAbilityAmount: {
        '1': '<300',
        '2': '300到2000',
        '3': '>2000',
    },
    /**
    * 近20次平局_小贷担保类_因交易能力不足导致失败_交易金额
    */
    last20AveSmallLoanFailureDueAbilityAmount: {
        '1': '<300',
        '2': '300到1300',
        '3': '>1300',
    },
    /**
    * 总平局_小贷担保类_因交易能力不足导致失败_交易金额
    */
    totAveSmallLoanFailureDueAbilityAmount: {
        '1': '<300',
        '2': '300到1600',
        '3': '>1600',
    },
    /**
     * 小贷担保类_因交易能力不足导致失败_交易距离当前天数_最大值
     */
    distanceDaysSmallLoanFailureDueAbilityAmountMax: {
        '1': '<50',
        '2': '50-90',
        '3': '90-180',
        '4': '180-420',
        '5': '>420',
    },

    /**
     * 小贷担保类_因交易能力不足导致失败_交易距离当前天数_最小值
     */
    distanceDaysSmallLoanFailureDueAbilityAmountMin: {
        '1': '<50',
        '2': '50-90',
        '3': '90-180',
        '4': '180-420',
        '5': '>420',
    },
    /**
     * 最近90天新增机构 _小贷担保类_因交易能力不足导致失败_交 易金额_最小值
     */
    last90DaysMecSmallLoanFailureDueAbilityAmountMin: {
        '1': '<200',
        '2': '200到1300',
        '3': '>1300',
    },

    /**
     * 最近90天新增机构 _小贷担保类_因交易能力不足导致失败_交 易金额_总计
     */
    last90DaysMecSmallLoanFailureDueAbilityAmountSum: {
        '1': '<3000',
        '2': '3000到14000',
        '3': '>14000',
    },

    /**
      * 最近360天新增机构 _小贷担保类_因交易能力不足导致失败_机 构数_去重计数
      */
    last360DaysMecSmallLoanFailureDueAbilityAmountRD: {
        '1': '<1',
        '2': '1-2',
        '3': '2-3',
        '4': '>3',
    },

    /**
     * @deprecated Since version 1.0. Will be deleted in version 3.0. Use bar instead.
     * 近30天_小贷担保类_因交易能力不足导致失败_交易金额_求和
     */
    last30DaysSmallLoanFailureDueAbilityAmountSum: {
    },

    /**
     * @deprecated Since version 1.0. Will be deleted in version 3.0. Use bar instead.
     * 近90天_小贷担保类_因交易能力不足导致失败_交易金额_求和
     */
    last90DaysSmallLoanFailureDueAbilityAmountSum: {
    },

    /**
     * @deprecated Since version 1.0. Will be deleted in version 3.0. Use bar instead.
    * 近180天_小贷担保类_因交易能力不足导致失败_交易金额_求和
    */
    last180SumSmallLoanFailureDueAbilityAmount: {
    },

    /**
     * 近90天_还款成功_还款金额_最大值
     */
    amountSuccessfulRepaymentRecent90Days: {

    },
    /**
     * 近90天_交易金额_最大值
     */
    amountTransaction90DaysMax: {},

    /**
     * 近180天_还款成功_还款金额_最大值
     */
    amountSuccessfulRepaymentRecent180Days: {

    },

    /**
     * 近360天_因交易能力不足导致失败_交易金额_最大值
     */
    maxFailureAmountDueCapacityRecent360Days: {

    },

    /**
    * 近20次_因交易能力不足导致失败_交易金额_最大值
    */
    maxFailureAmountDueCapacityRecent20Times: {

    },

    /**
     * 近5次交易_还款成功_还款金额_最大值
     */
    last5TimesTransRepayOkMaxAmount: {

    },
    /**
     * 近5次交易_交易成功_还款金额_最大值
     */
    last5TimesTransOkMaxAmount: {

    },


    /**
    * 近20次交易_还款成功_还款金额_最大值
    */
    last20TimesTransRepayOkMaxAmount: {},

    /**
     *  近50次交易_还款成功_还款金额_最大值
     */
    last50TimesTransOkMaxAmount: {

    },

    /**
     *  近100次交易最大金额
     */
    last100TimesTransOkMaxAmount: {},

    /**
     *  近100次还款最大金额
     */
    last100TimesTransRepayOkMaxAmount: {},

    /**
     * 最大还款金额
     */
    maximumRepaymentAmount: {

    },

    /**
     * 近90天因交易能力不足导致最小失败金额
     */
    minFailureAmountDueCapacityRecent90Days: {},

    /**
     * 近90天最小交易金额
     */
    amountTransaction90DaysMin: {},

    /**
     * 近180天还款最小金额
     */
    amountSuccessfulRepaymentRecent180DaysMin: {},

    /**
     * 近5次还款最小金额
     */
    last5TimesTransRepayOkMinAmount: {

    },

    /**
     * 近20次还款最小金额
     */
     last520TimesTransRepayOkMinAmount: {

    },

    /**
     * 近50次因交易能力不足导致最小失败金额
     */
    minFailureAmountDueCapacityRecent50Times:{},

    /**
     * 因交易能力不足导致最小失败金额
     */
    minFailureAmountDueCapacityRecent:{

    }

}