import moment from 'moment'
import React, { Component } from 'react'

// 待还款
import OrderRepayment from './order_repayment'
import apiV2 from '../../api/api-v2'
import PropTypes from 'prop-types'

/**
 * 明日到期
 */
export default class OrderList extends Component {
    static TimeFormat = 'YYYY-MM-DD'
    static StartAnd = false

    static propTypes = {
        readOnly: PropTypes.bool
    }

    tomorrowExpireExportExcel() {
        apiV2.tomorrowExpireExportExcel({ isDownload: true })
    }

    render() {
        const now = moment()
        const tomorrow = now.add(1, 'd')
        let startDateTime = tomorrow.format(OrderList.TimeFormat)
        const endDateTime = tomorrow.add(0, 'd').format(OrderList.TimeFormat) // 后天0点

        if (!OrderList.StartAnd) startDateTime = null

        console.log(startDateTime)

        return <OrderRepayment exportClick={() => this.tomorrowExpireExportExcel()} rquestListFunction={apiV2.todayForReimbursementOrderList}
            queryParam={{ repaymentBeginTime: endDateTime, repaymentEndTime: endDateTime }}
            orderAllocation
            readOnly={this.props.readOnly} />
    }
}
