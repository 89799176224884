import React, { Component } from 'react'
import {
  Switch,
  Message,
  Form,
  Button,
  Input,
  DateRangePicker,
  Select,
  Table,
  Tag,
} from 'element-react'
import Search from '@components/Search'
import MyPagination from '@components/MyPagination'
import apiV2 from '../../api/api-v2'
import moment from 'moment'
import GetLoginSMSCodePhone from './components/get_login_sms_code_phone'
import EnabkeStatus from '../../components/EnabkeStatus'

/**
 * 认证配置
 */
export default class AuthConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getSMS: false,
      queryFormData: {
        beginTime: "",
        cardNum: "",
        endTime: "",
        failureReason: "",
        mobile: "",
        realUserName: "",
        remark: "",
        current: 1,
        size: 10,
        status: true,
        type: null,
        tzStatus: true,
      },
      list: {
        records: [],
        total: 0,
        size: 10,
        current: 1,
        orders: [],
        optimizeCountSql: true,
        searchCount: true,
        countId: null,
        maxLimit: null,
        pages: 0,
      },
      columns: [
        {
          label: "身份证号",
          prop: "cardNum",
          width: "120",
          align: "center",
        },
        // {
        //   label: "内容",
        //   prop: "context",
        //   align: "center",
        //   width: "100",
        // },
        {
          label: "创建时间",
          prop: "createTime",
          width: "140",
          align: "center",
        },
        {
          label: "失败原因",
          prop: "failureReason",
          width: "140",
          align: "center",
        },
        {
          label: "用户ID",
          prop: "id",
          width: "140",
          align: "center",
        },
        {
          label: "手机号",
          prop: "mobile",
          width: "140",
          align: "center",
        },
        {
          label: "真实姓名",
          prop: "realUserName",
          width: "140",
          align: "center",
        },
        {
          label: "备注信息",
          prop: "remark",
          width: "140",
          align: "center",
        },
        {
          label: "通过状态",
          prop: "status",
          width: "140",
          align: "center",
          render({ status }) {
            return <EnabkeStatus enable={status} />;
          },
        },
        {
          label: "类型",
          prop: "type",
          width: "140",
          align: "center",
          render({ type }) {
            const lab = {
              1: { t: "白户", c: "primary" },
              2: { t: "正常", c: "success" },
              3: { t: "逾期", c: "warning" },
              4: { t: "异常", c: "danger" },
            };

            const c = lab[type] || { t: "未知" };

            return <Tag type={c.c}>{c.t}</Tag>;
          },
        },
        {
          label: "风控获取状态",
          prop: "tzStatus",
          width: "140",
          align: "center",
          render({ status }) {
            return <EnabkeStatus enable={status} />;
          },
        },
        {
          label: "修改时间",
          prop: "uptTime",
          width: "140",
          align: "center",
        },
      ],
    };
  }

  componentWillMount() {
    this.getMemberList();
  }

  onChangeQueryForm = (itemName) => {
    return (e) => {
      this.updateChangequeryForm(itemName, e);
    };
  };

  updateChangequeryForm(itemName, e, callback) {
    const queryFormData = Object.assign(this.state.queryFormData, {
      [itemName]: e,
    });
    this.setState(
      {
        queryFormData,
      },
      callback
    );
  }

  onChangeQueryFormDate(e) {
    const newForm = {};

    if (e && e.length > 0) {
      newForm.beginTime = e[0];
      newForm.endTime = e[1];
    } else {
      newForm.beginTime = null;
      newForm.endTime = null;
    }
    const queryFormData = Object.assign(this.state.queryFormData, newForm);
    this.setState({
      queryFormData,
    });
  }

  sizeChange(e) {
    this.updateChangequeryForm("size", e, this.getMemberList);
  }
  onCurrentChange(e) {
    this.updateChangequeryForm("current", e, this.getMemberList);
  }

  async getMemberList() {
    const formatCreate = "YYYY-MM-DD";
    const newForm = this.state.queryFormData;
    const { beginTime, endTime } = newForm;

    if (beginTime && endTime) {
      newForm.beginTime = moment(beginTime).format(formatCreate);
      newForm.endTime = moment(endTime).format(formatCreate);
    }

    const { body, message, success } = await apiV2.getRiskBatchProcessing({
      bodyQuery: newForm,
    });

    if (success) {
      this.setState({
        list: body,
      });
    } else {
      Message.error(message);
    }
  }

  closeGetSMS() {
    this.setState({ getSMS: false });
  }

  export() {
    apiV2.exportRiskBatchProcessing({
      param: this.state.queryFormData,
      isDownload: true,
    });
  }

  importTemplate() {
    window.location.href = "/ceshi.xlsx";
  }

  import() {
    const fileInputEle = document.createElement('input')
    fileInputEle.setAttribute('type', 'file')
    fileInputEle.style.display = 'none'
    fileInputEle.addEventListener('change', async () => {
      if (fileInputEle.files && fileInputEle.files.length) {
        const { message, success } = await apiV2.importRiskBatchProcessing({ file: fileInputEle.files[0] })
        Message({
          message: message,
          type: success ? 'success' : 'error'
        })
        this.getMemberList()
      }
    })
    fileInputEle.click()
  }

  render() {
    const { queryFormData, list } = this.state;

    return (
      <div>
        <Search>
          <Form.Item>
            <Input
              placeholder="手机号"
              value={queryFormData.mobile}
              onChange={this.onChangeQueryForm("mobile")}
              clearable="true"
            />
          </Form.Item>

          <Form.Item>
            <Input
              placeholder="身份证号"
              value={queryFormData.cardNum}
              onChange={this.onChangeQueryForm("cardNum")}
              clearable="true"
            />
          </Form.Item>

          <Form.Item label="真实姓名">
            <Input
              value={queryFormData.realUserName}
              onChange={this.onChangeQueryForm("realUserName")}
            />
          </Form.Item>

          <Form.Item>
            <Input
              placeholder="失败原因"
              value={queryFormData.failureReason}
              onChange={this.onChangeQueryForm("failureReason")}
              clearable="true"
            />
          </Form.Item>

          <Form.Item>
            <Input
              placeholder="备注"
              value={queryFormData.remark}
              onChange={this.onChangeQueryForm("remark")}
              clearable="true"
            />
          </Form.Item>

          <Form.Item label="是否通过">
            <Switch
              onText="开启"
              offText="关闭"
              value={queryFormData.status}
              onChange={this.onChangeQueryForm("status")}
            />
          </Form.Item>

          <Form.Item label="探针C状态">
            <Switch
              onText="开启"
              offText="关闭"
              value={queryFormData.tzStatus}
              onChange={this.onChangeQueryForm("tzStatus")}
            />
          </Form.Item>

          <Form.Item label="类型">
            <Select
              value={queryFormData.type}
              clearable
              onChange={this.onChangeQueryFormDate.bind(this, "type")}
            >
              <Select.Option value={0} label={"白户"}></Select.Option>
              <Select.Option value={1} label={"正常"}></Select.Option>
              <Select.Option value={2} label={"逾期"}></Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <DateRangePicker
              placeholder="注册时间"
              value={[queryFormData.beginTime, queryFormData.endTime]}
              onChange={this.onChangeQueryFormDate.bind(this)}
            />
          </Form.Item>

          <Form.Item>
            <Button onClick={this.getMemberList.bind(this)} type="primary">
              {"搜索"}
            </Button>
          </Form.Item>

          {false ? (
            <Form.Item>
              <Button
                onClick={() => this.setState({ getSMS: true })}
                type="primary"
              >
                {"获取验证码"}
              </Button>
            </Form.Item>
          ) : null}

          <Form.Item>
            <Button onClick={() => this.export()} type="success">
              {"导出"}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="text" onClick={() => this.importTemplate()}>
              {"下载导入模版"}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={() => this.import()} type="success">
              {"导入"}
            </Button>
          </Form.Item>
        </Search>

        <GetLoginSMSCodePhone
          dialogVisible={this.state.getSMS}
          onClose={() => this.closeGetSMS()}
        />
        <Table columns={this.state.columns} data={list.records} />

        <MyPagination
          total={list.total}
          pageSize={list.size}
          pageNumber={list.current}
          onSizeChange={this.sizeChange.bind(this)}
          onCurrentChange={this.onCurrentChange.bind(this)}
        />
      </div>
    );
  }
}
