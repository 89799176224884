import React, { Component } from 'react'

import Order from './order_pass'
import PropTypes from 'prop-types'
import apiV2 from '../../api/api-v2'

/**
 * 订单
 */
export default class OrderList extends Component {

    static propTypes = {
        queryParam: PropTypes.object,
        readOnly: PropTypes.bool,
    }

    render() {
        return <Order rquestListFunction={apiV2.getOrderWaitPaymentList}
            queryParam={this.props.queryParam} readOnly={this.props.readOnly} />
    }
}
