import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb, Col, Descriptions, Row, Table, Tag, Button } from 'antd'
import './css/mi_rong_duotou.css'
import SText from '../../components/SText'
import EnabkeStatus from '../../components/EnabkeStatus'
import apiV2 from '../../api/api-v2'
import { Message } from 'element-react'
import dict from './dict/mi_rong_duotou_dict'

import MiRongDuotouTotality from './chat/mi_rong_duotou_totality'

/**
 * 代号为多头的风控信息查看
 */
export default class RiskDuoto extends Component {

    static FieldPrefix = 'xyp_'

    static newTableRowData() {
        return [
            {
                name: '贷款机构数'
            },
            {
                name: '交易失败笔数'
            },
            {
                name: '还款成功笔数'
            },
            {
                name: '交易失败金额'
            },
            {
                name: '还款成功金额'
            }
        ]
    }

    static newTableRowData2() {
        return [{
            name: '成功还款总金额'
        },
        {
            name: '成功还款笔数'
        }]
    }

    static propTypes = {
        userId: PropTypes.number
    }

    constructor(props) {
        super(props)

        this.state = {
            refreshLoad: false,
            content: null,
            columnsRepayment5Times: [
                {
                    title: '近5次还款信息',
                    key: 'name',
                    rowKey: 'name',
                    render: ({ name }) => <a>{name}</a>,
                },
                {
                    title: '近5次',
                    key: 'near5',
                    rowKey: 'near5',
                    render: ({ near5 }) => <a>{near5}</a>,
                },
                {
                    title: '近20次',
                    key: 'near20',
                    rowKey: 'near20',
                    render: ({ near20 }) => <a>{near20}</a>,
                },
            ],
            dataRepayment5Times: RiskDuoto.newTableRowData2(),
            columns: [
                {
                    title: '近期数据类型',
                    key: 'name',
                    render: ({ name }) => <a>{name}</a>,
                    rowKey: 'name',
                },
                {
                    title: '近1天',
                    key: 'near1',
                    rowKey: 'near1',
                    render: ({ near1 }) => <a>{near1}</a>,
                },
                {
                    title: '近7天',
                    key: 'near7',
                    rowKey: 'near7',
                    render: ({ near7 }) => <a>{near7}</a>,
                },
                {
                    title: '近14天',
                    key: 'near14',
                    rowKey: 'near14',
                    render: ({ near14 }) => <a>{near14}</a>,
                },
                {
                    title: '近21天',
                    key: 'near21',
                    rowKey: 'near21',
                    render: ({ near21 }) => <a>{near21}</a>,
                },
                {
                    title: '近30天',
                    key: 'near30',
                    rowKey: 'near30',
                    render: ({ near30 }) => <a>{near30}</a>,
                },
                {
                    title: '近90天',
                    key: 'near90',
                    rowKey: 'near90',
                    render: ({ near90 }) => <a>{near90}</a>,
                },
                {
                    title: '近180天',
                    key: 'near180',
                    rowKey: 'near180',
                    render: ({ near180 }) => <a>{near180}</a>,
                },
            ],
            data: RiskDuoto.newTableRowData()
        }
    }

    componentDidMount() {
        this.getQtzByUserId()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.setState({
                dialogVisible: true
            })

            this.getQtzByUserId()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    /**
    * 构建表格2数据
    */
    makeTable2Data(content = this.state.content) {

        const tableRowData = RiskDuoto.newTableRowData2()

        if (content) {
            tableRowData[0]['near5'] = this.getFieldBool('cpl0073')
                || this.dictValue('totalAmountSuccessfullyRepaidLast5Times', 'cpl0077', { unit: '元' })
            tableRowData[1]['near5'] = this.dictValue('totalAmountSuccessfullyRepaidLast20Times', 'cpl0078', { unit: '元' })

            tableRowData[0]['near20'] = this.getFieldValue('cpl0074')
            tableRowData[1]['near20'] =
                <>
                    {'(小贷担保)'}{this.getFieldValue('cpl0075', '未知数')} {'/'}
                    {this.dictValue('nearly20TimesNumberSuccessfulRepayments', 'cpl0075', { unit: '笔' })}
                </>
        }
        this.setState({ dataRepayment5Times: tableRowData })
    }

    /**
     * 初始化
     */
    resetContent() {
        this.makeTable1Data()
        this.makeTable2Data()
    }

    /**
     * 构建表格1数据
     */
    makeTable1Data(content = this.state.content) {
        const tableRowData = RiskDuoto.newTableRowData()

        const reset = (index, _1, _7, _14, _21, _30, _90, _180) => {
            tableRowData[index]['near1'] = this.getFieldValue(_1)
            tableRowData[index]['near7'] = this.getFieldValue(_7)
            tableRowData[index]['near14'] = this.getFieldValue(_14)
            tableRowData[index]['near21'] = this.getFieldValue(_21)
            tableRowData[index]['near30'] = this.getFieldValue(_30)
            tableRowData[index]['near90'] = this.getFieldValue(_90)
            tableRowData[index]['near180'] = this.getFieldValue(_180)
        }

        if (content) {
            reset(0, 'cpl0070', 'cpl0009', 'cpl0010', 'pl0063', 'cpl0011', 'cpl0012', 'cpl0013')
            reset(1, 'cpl0016', 'cpl0018', 'cpl0020', 'pl0064', 'cpl0022', 'cpl0024', 'cpl0026')
            reset(2, 'cpl0017', 'cpl0019', 'cpl0021', 'pl0065', 'cpl0023', 'cpl0025', 'cpl0027')
            reset(3, 'cpl0032', 'cpl0034', 'cpl0036', 'pl0066', 'cpl0038', 'cpl0040', 'cpl0042')
            reset(4, 'cpl0033', 'cpl0035', 'cpl0067', 'pl0067', 'cpl0039', 'cpl0041', 'cpl0043')
        }
        this.setState({ data: tableRowData })
    }

    getFieldValue(key, defaultValue = '/') {
        const content = this.state.content || {}
        return content[`${RiskDuoto.FieldPrefix}${key}`] || defaultValue
    }

    getFieldBool(key) {
        return parseInt(this.getFieldValue(key, '0'))
    }

    async refreshQtzByUserId(userId = this.props.userId) {
        this.setState({ refreshLoad: true })
        try {
            if (userId) {
                const { success, message } = await apiV2.refreshQtzByUserId({ userId })

                if (!success) {
                    Message.error(message)
                } else {
                    await this.getQtzByUserId(userId)
                }
            }
        } finally {
            this.setState({ refreshLoad: false })
        }
    }

    async getQtzByUserId(userId = this.props.userId) {
        if (userId) {
            const { body, success, message } = await apiV2.getQtzByUserId({ userId })
            this.setState({
                content: JSON.parse(body)
            }, () => this.resetContent())

            if (!success) {
                Message.error(message)
            }
        } else {
            this.setState({
                content: null
            })
        }
    }

    dictValue(dirName, fieldName, { unit } = { unit: '个' }) {
        return (dict[dirName][this.getFieldValue(fieldName)] || '未知') + unit
    }

    dictValueUI(dirName, fieldName, { unit } = { unit: '个' }) {
        const value = this.dictValue(dirName, fieldName, { unit })
        let desc = '-'

        if (value.startsWith('>')) {
            desc = value.replace('>', '大于')
        } else if (value.startsWith('<')) {
            desc = value.replace('<', '小于')
        } else if (value) {
            desc = value
        }

        return <SText title={value} value={desc} />
    }

    render() {
        const { refreshLoad, columns, data, columnsRepayment5Times, dataRepayment5Times, content } = this.state

        return (
            <>
                {content ?
                    <Row type="flex">
                        <Col span={4}>
                            <Row className="rick_block_head" justify="space-between" type="flex">
                                <Row className="rick_block_head_name">{'借贷信息'}</Row>
                                <Row>
                                    <img alt="Icon" className="rick_block_head_icon" src={require('./icon/mr_d_base_info.svg')} />
                                </Row>
                            </Row>
                        </Col>
                        <Col span={20}>
                            <MiRongDuotouTotality />
                            <Descriptions
                                bordered
                                size="small"
                            >
                                <Descriptions.Item label="贷款总机构数">
                                    {this.dictValueUI('totalNumberLoanInstitutions', 'cpl0001')}
                                </Descriptions.Item>
                                <Descriptions.Item label="贷款已结清机构数">
                                    {this.dictValueUI('numberInstitutionsWithSettledLoans', 'cpl0002')}
                                </Descriptions.Item>
                                <Descriptions.Item label="当前是否存在逾期未结清">
                                    <EnabkeStatus enable={this.getFieldBool('cpl0044')} />
                                </Descriptions.Item>
                                <Descriptions.Item label="消费金融类机构数">
                                    {this.dictValueUI('numberConsumerFinanceInstitutions', 'cpl0007')}
                                </Descriptions.Item>
                                <Descriptions.Item label="网络贷款类机构数（现金贷）">
                                    {this.dictValueUI('numberNetworkLoanInstitutions', 'cpl0008')}
                                </Descriptions.Item>

                            </Descriptions>
                            <Table rowKey={(_, i) => i.toString()}
                                bordered size="small"
                                columns={columns}
                                dataSource={data}
                                pagination={false} />

                            <Descriptions
                                bordered
                                size="small"
                            >
                                <Descriptions.Item label="历史贷款机构成功还款笔数">
                                    {this.dictValueUI('numberSuccessfulRepaymentsHisLoanIns', 'cpl0014')}
                                </Descriptions.Item>
                                <Descriptions.Item label="历史贷款机构交易失败笔数">
                                    {this.dictValueUI('numberFailRepaymentsHisLoanIns', 'cpl0015')}
                                </Descriptions.Item>

                            </Descriptions>

                            <Descriptions
                                bordered
                                size="small"
                            >
                                <Descriptions.Item label="近期是否发生逾期" column={1}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item>
                                            <Tag color="magenta">{'(1天)'}<EnabkeStatus enable={this.getFieldBool('cpl0028')} /></Tag>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Tag color="volcano">{'(7天)'}<EnabkeStatus enable={this.getFieldBool('cpl0029')} /></Tag>
                                        </Breadcrumb.Item>

                                        <Breadcrumb.Item>
                                            <Tag color="gold">{'(14天)'}<EnabkeStatus enable={this.getFieldBool('cpl0030')} /></Tag>
                                        </Breadcrumb.Item>

                                        <Breadcrumb.Item>
                                            <Tag color="lime">{'(30天)'}<EnabkeStatus enable={this.getFieldBool('cpl0031')} /></Tag>
                                        </Breadcrumb.Item>

                                    </Breadcrumb>
                                </Descriptions.Item>
                            </Descriptions>

                            <Descriptions
                                bordered
                                size="small"
                            >
                                <Descriptions.Item label="信用贷款时长">
                                    {this.dictValueUI('creditLoanDuration', 'cpl0045', { unit: '天' })}
                                </Descriptions.Item>
                                <Descriptions.Item label="最近一次交易距离当前时间">
                                    {this.dictValueUI('distanceFromLatestTransactionCurrentTime', 'cpl0046', { unit: '天' })}
                                </Descriptions.Item>

                            </Descriptions>

                            <Descriptions bordered size="small">
                                <Descriptions.Item label="近期交易失败次数>0的机构数" column={1}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item>
                                            <Tag color="volcano">{'(7天)'}{this.dictValue('last7DaysTransactionFailureTimes', 'cpl0048')}</Tag>
                                        </Breadcrumb.Item>

                                        <Breadcrumb.Item>
                                            <Tag color="gold">{'(14天)'}{this.dictValue('last14DaysTransactionFailureTimes', 'cpl0049')}</Tag>
                                        </Breadcrumb.Item>

                                        <Breadcrumb.Item>
                                            <Tag color="lime">{'(21天)'}{this.dictValue('last21DaysTransactionFailureTimes', 'cpl0050')}</Tag>
                                        </Breadcrumb.Item>

                                        <Breadcrumb.Item>
                                            <Tag color="magenta">{'(30天)'}{this.dictValue('last30DaysTransactionFailureTimes', 'cpl0051')}</Tag>
                                        </Breadcrumb.Item>

                                    </Breadcrumb>
                                </Descriptions.Item>
                            </Descriptions>

                            <Descriptions
                                bordered
                                size="small"
                            >
                                <Descriptions.Item label="当前逾期机构数">
                                    {this.dictValueUI('currentOverdueInstitutions', 'cpl0071')}
                                </Descriptions.Item>
                                <Descriptions.Item label="最近一次还款成功距当前时间">
                                    {this.dictValueUI('lastRepaymentSucceededDaysFromCurrent', 'cpl0068', { unit: '天' })}
                                </Descriptions.Item>
                                <Descriptions.Item label="当前逾期金额">
                                    {this.dictValueUI('currentOverdueAmount', 'cpl0072', { unit: '元' })}
                                </Descriptions.Item>

                            </Descriptions>

                            <Table rowKey={(_, i) => i.toString()}
                                bordered size="small"
                                columns={columnsRepayment5Times}
                                dataSource={dataRepayment5Times}
                                pagination={false} />

                            <Descriptions
                                bordered
                                size="small"
                            >
                                <Descriptions.Item label="近90天还款总金额">
                                    <SText title={this.getFieldValue('cpl0079')} />
                                </Descriptions.Item>
                                <Descriptions.Item label="近90天内还款总笔数">
                                    <SText title={this.getFieldValue('cpl0080')} />
                                </Descriptions.Item>

                            </Descriptions>

                            <Descriptions
                                bordered
                                size="small"
                                column={1}
                            >
                                <Descriptions.Item label="小贷担保类">
                                    <Descriptions bordered size="small" column={1}>
                                        <Descriptions.Item label="交易金额最大值" >
                                            <Breadcrumb>
                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近7天)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent7Days', 't01aabzbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="gold">{'(近21天)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent21Days', 't01aadzbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="lime">{'(近1月)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent30Days', 't01aafzbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="blue">{'(近3月)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent90Days', 't01aagzbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item>
                                                    <Tag color="geekblue">{'(近半年)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent180Days', 't01aabzbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item>
                                                    <Tag color="purple">{'(近1年)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent360Days', 't01aahzbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="cyan">{'(近5次交易)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent5Trade', 't01aaizbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#f50">{'(近5次交易)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent5Trade', 't01aaizbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#2db7f5">{'(近100次交易)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent100Trade', 't01aalzbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近360天新增机构)'}{this.dictValue('maxAmountSmallLoanGuaTransRecent360Mech', 't01aazhbz', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                            </Breadcrumb>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="还款成功金额最大值" >
                                            <Breadcrumb>
                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近30天)'}{this.dictValue('maxOkRepayAmountSmallLoanGuarantee30Days', 't01aaezbc', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="lime">{'(近90天)'}{this.dictValue('maxOkRepayAmountSmallLoanGuarantee90Days', 't01aafzbc', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#2db7f5">{'(近100次交易)'}{this.dictValue('maxOkRepayAmountSmallLoanGuarantee100Trade', 't01aalzbc', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近90天新增机构)'}{this.dictValue('maxOkRepayAmountSmallLoanGuarantee90Mech', 't01aazfbc', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                            </Breadcrumb>
                                        </Descriptions.Item>

                                        <Descriptions.Item label="因交易能力不足导致失败金额" >
                                            <Breadcrumb>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近5次最大值)'}{this.dictValue('last5TransactionsSmallLoanFailureDueAbilityAmountMax', 't01aaizba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近5次最小值)'}{this.dictValue('last5TransactionsSmallLoanFailureDueAbilityAmountMin', 't01abkzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近5次总和)'}{this.dictValue('last5SumSmallLoanFailureDueAbilityAmount', 't01acizba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近5次总和)'}{this.getFieldValue('t02acizba_aczzba')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="lime">{'(近20次最大值)'}{this.dictValue('last20TransactionsSmallLoanFailureDueAbilityAmountMax', 't01aajzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近20次总和)'}{this.dictValue('last20SumSmallLoanFailureDueAbilityAmount', 't01acjzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近20次平均)'}{this.dictValue('last20AveSmallLoanFailureDueAbilityAmount', 't01adjzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#2db7f5">{'(近50次最大值)'}{this.dictValue('last50TransactionsSmallLoanFailureDueAbilityAmountMax', 't01aakzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近30天最大值)'}{this.dictValue('last30DaysSmallLoanFailureDueAbilityAmountMin', 't01acezba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近30天总计)'}{this.getFieldValue('t02acezba_acezbz')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近90天最小值)'}{this.dictValue('last90DaysSmallLoanFailureDueAbilityAmountMin', 't01abfzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近90天总计)'}{this.getFieldValue('t02acfzba_acfzbz')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近90天新增机构最小值)'}{this.dictValue('last90DaysMecSmallLoanFailureDueAbilityAmountMin', 't01abzfba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近90天新增机构总计)'}{this.dictValue('last90DaysMecSmallLoanFailureDueAbilityAmountSum', 't01aczfba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="#87d068">{'(近360天新增机构去重数)'}{this.dictValue('last360DaysMecSmallLoanFailureDueAbilityAmountRD', 't01dezhba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近100笔总计)'}{this.dictValue('last100SumSmallLoanFailureDueAbilityAmount', 't01cclzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近180次平均)'}{this.dictValue('last180AveSmallLoanFailureDueAbilityAmount', 't01adgzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近180次总和)'}{this.getFieldValue('t02acgzba_acgzbz')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近180天总和)'}{this.getFieldValue('t02ccgzba_ccgzbz')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近360天总和)'}{this.getFieldValue('t02ccgzba_ccgzbz')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近100次交易)'}{this.getFieldValue('t02cclzba_cclzbz')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(总笔数)'}{this.getFieldValue('t02cczzba_cczzbz')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近30天)'}{this.getFieldValue('t02cczzba_cczzbz')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(近90天)'}{this.getFieldValue('t02ccfzba_cczzba')}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(总平均)'}{this.dictValue('totAveSmallLoanFailureDueAbilityAmount', 't01adzzba', { unit: '元' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(距离上次时间最大值)'}{this.dictValue('distanceDaysSmallLoanFailureDueAbilityAmountMax', 't01bbzzba', { unit: '天' })}</Tag>
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item>
                                                    <Tag color="volcano">{'(距离上次时间最小值)'}{this.dictValue('distanceDaysSmallLoanFailureDueAbilityAmountMin', 't01bbzzba', { unit: '天' })}</Tag>
                                                </Breadcrumb.Item>

                                            </Breadcrumb>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Descriptions.Item>
                                <Descriptions.Item label="消费金融类">
                                </Descriptions.Item>
                                <Descriptions.Item label="总体">
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    : <Button loading={refreshLoad} onClick={() => this.refreshQtzByUserId()}>{'点击获取数据'}</Button>}
            </>
        )
    }

}