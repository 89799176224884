import { Form, DateRangePicker, Input, Select, Button, Table, Pagination, Tag } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import Paste from '../../components/paste'
import Money from '../../components/Money'
import { OrderType, OrderTypeListNoExtension as OrderTypeList, orderInfoButtonGroup, OrderStatusList, OrderStatus } from './types'
import moment from 'moment'
import NewUserForm from './new_user_switch'

import PropTypes from 'prop-types'
import OperatorInfo from '../member/operator_info'
import UserInfo from '../member/user_info'
import OrderInfo from './order_info'
import Condition from '../../components/Condition'
import { getShareOrderColumns } from '../../components/order-tools'

/**
 * 所有已打款订单
 */
export default class OrderList extends Component {

    static ListResultEmpty = { records: [], total: 0 }


    static propTypes = {
        queryParam: PropTypes.object,
        onRequestList: PropTypes.func,
        repaymentAmountField: PropTypes.string,
        deleteField: PropTypes.array,
        readOnly: PropTypes.bool,
        exportFunc: PropTypes.func
    }


    constructor(props) {
        super(props)

        const orderColumns = [
            ...getShareOrderColumns(),
            {
                label: '用户姓名',
                prop: 'userName',
                width: '100',
                align: 'center',
                render: ({ userName }) => <Paste content={userName} />,
                fixed: true
            },
            {
                label: '渠道',
                prop: 'channel',
                width: '150',
                align: 'center',
                render: ({ channel }) => <Paste content={channel} />
            },
            {
                label: '借款额度',
                width: '120',
                align: 'center',
                prop: 'quota',
                render: ({ quota }) => <Money value={quota} />
            },
            {
                label: '申请借款周期',
                width: '140',
                align: 'center',
                prop: 'deadlineDay',
                render: ({ deadlineDay }) => <Money value={deadlineDay} />
            },
            {
                label: '借款次数',
                prop: 'num',
                align: 'center',
                width: '120',
            },

            {
                label: '手机号',
                prop: 'phone',
                align: 'center',
                width: '120',
                fixed: true
            },
            {
                label: '服务费',
                prop: 'rateMoney',
                align: 'center',
                width: '120',
                render: ({ rateMoney }) => <Money value={rateMoney} />
            },
            {
                label: '订单类型',
                prop: 'type',
                width: '120',
                align: 'center',
                render: ({ type }) => {
                    const that = OrderType[type]
                    return <Tag type={that.theme}>{that.name}</Tag>
                }
            },
            {
                label: '订单状态',
                prop: 'type',
                width: '120',
                align: 'center',
                render: ({ status }) => {
                    const that = OrderStatus[status]
                    return <Tag type={that.theme}>{that.name}</Tag>
                }
            },
            {
                label: '是否新客',
                prop: 'num',
                width: '120',
                align: 'center',
                render: ({ num }) => {
                    return num > 0 ? (<i className="el-icon-close" />) : (<i color="red" className="el-icon-check" />)
                }

            },
            // {
            //     label: '雷达分',
            //     prop: 'kaiqiScore',
            //     width: '120',
            //     align: 'center',
            //     render: ({ kaiqiScore }) => <Money value={kaiqiScore} />
            // },
            // {
            //     label: '米融分',
            //     prop: 'zhimiScore',
            //     width: '120',
            //     align: 'center',
            //     render: ({ zhimiScore }) => <Money value={zhimiScore} />
            // },
            {
                label: '下单时间',
                prop: 'createTime',
                width: '140',
                align: 'center',

            },
            {
                label: '代收三方单号',
                prop: 'dsThirdNum',
                width: '120',
                align: 'center',
            },
            {
                label: '代付三方单号',
                prop: 'dfThirdNum',
                align: 'center',
                width: '130',
                fixed: true,
            },
            {
                label: '放款时间',
                prop: 'remitTime',
                width: '140',
                align: 'center',

            },
            {
                label: '审核时间',
                prop: 'auditTime',
                width: '140',
                align: 'center',
            },
            {
                label: '约定还款时间',
                prop: 'endTime',
                align: 'center',
                width: '150',
            }, {
                label: '到账金额',
                prop: 'arrivalAmount',
                align: 'center',
                width: '120',
                render: ({ arrivalAmount }) => <Money value={arrivalAmount} />
            },
            {
                label: '应还金额',
                prop: 'shouldRepayAmount',
                align: 'center',
                width: '120',
                render: ({ shouldRepayAmount }) => <Money value={shouldRepayAmount} />
            },
            {
                label: 'ID',
                prop: 'orderId',
                width: '60',
                align: 'center',
            },
            {
                label: '操作',
                prop: 'id',
                fixed: 'right',
                align: 'center',
                width: '150',
                render: ({ userId, orderId }) => {
                    return this.props.readOnly ? orderInfoButtonGroup(this, orderId, userId) : (
                        <>
                            {orderInfoButtonGroup(this, orderId, userId)}
                        </>)
                }

            },

        ]

        const deleteField = props.deleteField || []

        this.state = {
            orderColumns: orderColumns.filter(({ prop }) => !deleteField.includes(prop)),
            orderList: OrderList.ListResultEmpty,
            queryForm: {
                orderCode: '',
                size: 10,
                current: 1,
                type: '',
                isNewUser: null,
                beginRepaymentTime: null,
                endRepaymentTime: null,
                channel: '',
                phone: '',
                realRepaymentBeginTime: null,
                realRepaymentEndTime: null,
                repaymentBeginTime: null,
                repaymentEndTime: null,
                beginRemitTime: null,
                endRemitTime: null,
                beginTime: null,
                endTime: null,
                status: null
            },
            userInfoId: null,
            phoneInfoId: null,
            orderInfoId: null
        }
    }


    componentDidMount() {
        this.loadRequest()
    }

    componentDidUpdate(preProps) {
        if (preProps.queryParam !== this.props.queryParam) {
            this.loadRequest()
        }
    }

    //组件销毁前调用，清除一些事件(比如定时事件)
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    loadRequest() {
        if (!this.props.queryParam) { this.getOrderList() } else {
            this.setState(Object.assign(this.state.queryForm, this.props.queryParam), this.getOrderList)
        }

    }

    async exportList() {
        if (this.props.exportFunc) {
            await this.props.exportFunc({ param: this.state.queryForm, isDownload: true })
        } else {
            await apiV2.exportOrderPaymentCompleteList({ data: this.state.queryForm, isDownload: true })
        }
    }

    async getOrderList() {
        const onRequestList = this.props.onRequestList
        let orderResult = null
        if (onRequestList) {
            orderResult = await onRequestList(this.state.queryForm)
        } else {
            orderResult = await apiV2.getOrderPaymentCompleteList({ data: this.state.queryForm })
        }

        const { body } = orderResult
        this.setState({ orderList: body || OrderList.ListResultEmpty })
    }

    changeQueryForm(name, value, formatFn, listRefresh) {
        if (formatFn) value = formatFn(value)

        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => {
            if (listRefresh) this.getOrderList()
        })
    }

    datesAdapterElUI(date1String, date2String) {
        if (!date1String || !date2String) return [null, null]
        return [new Date(date1String), new Date(date2String)]
    }

    changeQueryFormDate(value, nameStart, nameEnd) {
        const format = 'YYYY-MM-DD'
        let start = null, end = null
        if (value && value.length > 1) {
            start = moment(value[0]).format(format)
            end = moment(value[1]).format(format)
        }

        const queryForm = Object.assign(this.state.queryForm, { [nameStart]: start, [nameEnd]: end })
        this.setState({ queryForm })

    }

    get isExported() {
        return true
    }

    render() {

        const { orderColumns, orderList, queryForm } = this.state
        const { queryParam } = this.props

        return (
            <>
                <Form inline>
                    <Form.Item label="订单号">
                        <Input value={queryForm.orderCode} onChange={(e) => this.changeQueryForm('orderCode', e)} />
                    </Form.Item>

                    <Form.Item label="手机号">
                        <Input value={queryForm.phone} onChange={(e) => this.changeQueryForm('phone', e)} />
                    </Form.Item>

                    <Form.Item label="用户姓名">
                        <Input value={queryForm.userName} onChange={(e) => this.changeQueryForm('userName', e)} />
                    </Form.Item>

                    <Form.Item label="渠道号">
                        <Input value={queryForm.channel} onChange={(e) => this.changeQueryForm('channel', e)} />
                    </Form.Item>

                    <Form.Item label="类型">
                        <Select value={queryForm.type} clearable onChange={(e) => this.changeQueryForm('type', e)}>
                            {OrderTypeList.map(({ key, name }) => <Select.Option key={key} label={name} value={key} />)}
                        </Select>
                    </Form.Item>

                    <Form.Item label="状态">
                        <Select value={queryForm.status} clearable onChange={(e) => this.changeQueryForm('status', e)}>
                            {OrderStatusList.filter(({ key }) => [4, 5, 6].includes(parseInt(key))).map(({ key, name }) =>
                                <Select.Option key={key} label={name} value={key} />)}
                        </Select>
                    </Form.Item>


                    <Form.Item label="查询新老客">
                        <NewUserForm value={queryForm.isNewUser} onChangeValue={(e) => this.changeQueryForm('isNewUser', e)} />
                    </Form.Item>

                    <Condition visible={queryParam && (!queryParam.beginRepaymentTime || !queryParam.endRepaymentTime)} tag="span">
                        <Form.Item label="约定还款时间">
                            <DateRangePicker value={this.datesAdapterElUI(queryForm.beginRepaymentTime, queryForm.endAendRepaymentTimeuditTime)}
                                onChange={(e) => this.changeQueryFormDate(e, 'beginRepaymentTime', 'endRepaymentTime')} />
                        </Form.Item>
                    </Condition>

                    <Form.Item label="放款时间">
                        <DateRangePicker value={this.datesAdapterElUI(queryForm.beginRemitTime, queryForm.endRemitTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'beginRemitTime', 'endRemitTime')} />
                    </Form.Item>

                    <Form.Item label="下单时间">
                        <DateRangePicker value={this.datesAdapterElUI(queryForm.beginTime, queryForm.endTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'beginTime', 'endTime')} />
                    </Form.Item>

                    <Form.Item label="实际还款时间">
                        <DateRangePicker value={this.datesAdapterElUI(queryForm.realRepaymentBeginTime, queryForm.realRepaymentEndTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'realRepaymentBeginTime', 'realRepaymentEndTime')} />
                    </Form.Item>

                    <Form.Item label="应还款时间">
                        <DateRangePicker value={this.datesAdapterElUI(queryForm.repaymentBeginTime, queryForm.repaymentEndTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'repaymentBeginTime', 'repaymentEndTime')} />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.getOrderList()} type="primary">{'搜索'}</Button>
                    </Form.Item>

                    {this.isExported ?
                        <Form.Item>
                            <Button onClick={() => this.exportList()} type="success">{'导出'}</Button>
                        </Form.Item> : null}

                    <Table border columns={orderColumns} data={orderList.records} />


                    <Pagination total={orderList.total} current={queryForm.current} size={queryForm.size}
                        onCurrentChange={(e) => this.changeQueryForm('current', e, null, true)}
                        onSizeChange={(e) => this.changeQueryForm('size', e, null, true)}
                    />

                    <OrderInfo orderId={this.state.orderInfoId} onClose={() => this.setState({ orderInfoId: null })} />
                    <OperatorInfo userId={this.state.phoneInfoId} onClose={() => this.setState({ phoneInfoId: null })} />
                    <UserInfo userId={this.state.userInfoId} onClose={() => this.setState({ userInfoId: null })} />
                </Form>
            </>
        )
    }
}