/**
 * 软件版本类型
 */
export const SoftVersionType = Object.freeze([{
    symbol: 2,
    label: 'IOS',
    theme: 'success'
}, {
    symbol: 1,
    label: 'ANDROID',
    theme: 'primary'
}])

/**
 * 渠道计费类型
 */
export const ChannelBillingType = Object.freeze(['cpa', 'cps', 'uv'])