import { Button, DateRangePicker, Form, Input } from 'element-react'
import React, { Component } from 'react'

import PropTypes from 'prop-types'
import AuthenticationStatusSelect from '../authentication_status_select'
import SwitchesThatCanDisabled from '../../../components/switches_that_can_disabled'
import Pagination from '../../../components/MyPagination'
import { formatDatePeriod, datesAdapterElUI } from '../../../utils/format'

/**
 * 用户列表搜索
 */
export default class UserSearch extends Component {

    static propTypes = {
        children: PropTypes.any,
        onSearch: PropTypes.func,
        onCd: PropTypes.func,
        total: PropTypes.number
    }

    constructor(props) {
        super(props)

        this.state = {
            queryFormData: {
                realUserName: '',
                'bankAuthStatus': null,
                'beginTime': '',
                'channel': '',
                'current': 1,
                'eecAuthStatus': null,
                'endTime': '',
                'idCardAuthStatus': null,
                'mobile': '',
                'mobileAuthStatus': null,
                'mobileBookStatus': null,
                'size': 10,
                'status': null
            },
            body: {}
        }
    }

    componentDidMount() {
        this.serverGetTableList()
    }

    didUpdateParam(queryFormData = this.state.queryFormData) {
        if (this.props.onCd) {
            this.props.onCd(queryFormData)
        }
    }

    // 从服务器获取数据列表
    async serverGetTableList() {
        const onSearch = this.props.onSearch
        if (onSearch) onSearch(this.state.queryFormData)
    }

    /**
     * 改变查询表单值
     * @param {*} name 属性名
     * @param {*} value 属性值
     * @param {*} listRefresh 是否刷新列表
     */
    changeQueryForm(name, value, listRefresh) {
        const queryFormData = Object.assign(this.state.queryFormData, { [name]: value })
        this.didUpdateParam(queryFormData)
        this.setState({ queryFormData }, () => { if (listRefresh) this.serverGetTableList() })
    }

    /**
     * 改变查询表单值(日期)
     * @param {*} startName 属性名1
     * @param {*} endName 属性名2
     * @param {*} dateArr 属性值
     */
    changeQueryFormDate(dateArr, startName, endName) {
        const [startValue, endValue] = formatDatePeriod(dateArr)
        const queryFormData = Object.assign(this.state.queryFormData, { [startName]: startValue, [endName]: endValue })
        this.didUpdateParam(queryFormData)
        this.setState({ queryFormData })
    }

    render() {
        const { queryFormData } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="手机号">
                        <Input value={queryFormData.mobile} placeholder="11位+86的手机号" onChange={(e) => this.changeQueryForm('mobile', e)} />
                    </Form.Item>
                    <Form.Item label="渠道">
                        <Input value={queryFormData.channel} placeholder="渠道名称" onChange={(e) => this.changeQueryForm('channel', e)} />
                    </Form.Item>

                    <Form.Item label="真实姓名">
                        <Input value={queryFormData.realUserName} onChange={(e) => this.changeQueryForm('realUserName', e)} />
                    </Form.Item>

                    <Form.Item label="注册时间">
                        <DateRangePicker value={datesAdapterElUI(queryFormData.beginTime, queryFormData.endTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'beginTime', 'endTime')} />
                    </Form.Item>

                    <Form.Item label="可用状态">
                        <SwitchesThatCanDisabled value={queryFormData.status} onChangeValue={(e) => this.changeQueryForm('status', e)} />
                    </Form.Item>

                    <Form.Item label="银行卡认证">
                        <AuthenticationStatusSelect value={queryFormData.bankAuthStatus} onChange={(e) => this.changeQueryForm('bankAuthStatus', e)} />
                    </Form.Item>
                    <Form.Item label="身份证认证">
                        <AuthenticationStatusSelect value={queryFormData.idCardAuthStatus} onChange={(e) => this.changeQueryForm('idCardAuthStatus', e)} />
                    </Form.Item>
                    <Form.Item label="手机号认证">
                        <AuthenticationStatusSelect value={queryFormData.mobileAuthStatus} onChange={(e) => this.changeQueryForm('mobileAuthStatus', e)} />
                    </Form.Item>
                    <Form.Item label="个人信息认证">
                        <AuthenticationStatusSelect value={queryFormData.eecAuthStatus} onChange={(e) => this.changeQueryForm('eecAuthStatus', e)} />
                    </Form.Item>
                    <Form.Item label="通讯录认证">
                        <AuthenticationStatusSelect value={queryFormData.mobileBookStatus} onChange={(e) => this.changeQueryForm('mobileBookStatus', e)} />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.serverGetTableList()} type="primary">{'查询'}</Button>
                    </Form.Item>

                </Form>

                {this.props.children}

                <Pagination total={this.props.total} pageNumber={queryFormData.current} pageSize={queryFormData.size}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />
            </>)
    }
}