import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'

import OrderFinish from '../loan/order_finish'
import PropTypes from 'prop-types'

/**
 * 今日已还
 */
export default class OrderList extends Component {

    static propTypes = {
        readOnly: PropTypes.bool,
    }

    async todayCompleteOrderList(queryParam) {
        return await apiV2.todayCompleteOrderListVO({ data: queryParam })
    }

    render() {
        return (
            <OrderFinish deleteField={['shouldRepayAmount']} repaymentAmountField="sumMoney"
                exportFunc={apiV2.todayCompleteOrderListExcel}
                onRequestList={this.todayCompleteOrderList} readOnly={this.props.readOnly} />
        )
    }
}