import React, { Component } from "react";
import OrderRepaymentCard from "./order_repayment_card";
import { Tabs } from "element-react";
import PropTypes from "prop-types";

/**
 * 首借逾期-只读
 */
export default class OrderRepaymentTabs extends Component {
  /**
   * 参数
   */
  static propTypes = {
    overdue: PropTypes.bool,
    fixedRightField: PropTypes.string,
    startTimeExpire: PropTypes.string,
    endTimeExpire: PropTypes.string,
    queryParam: PropTypes.object,
    exportClick: PropTypes.func,
    rquestListFunction: PropTypes.func,
    readOnly: PropTypes.bool,
    exportName: PropTypes.string,
    orderAllocation: PropTypes.bool,
  };

  render() {
    const { orderAllocation } = this.props;

    return orderAllocation ? (
      <Tabs activeName="1">
        <Tabs.Pane label="未分配管理员" name="1">
          <OrderRepaymentCard
            {...this.props}
            queryParam={{ ...this.props.queryParam, isAllocation: false }}
          />
        </Tabs.Pane>
        <Tabs.Pane label="已分配管理员" name="2">
          <OrderRepaymentCard
            {...this.props}
            queryParam={{ ...this.props.queryParam, isAllocation: true }}
          />
        </Tabs.Pane>
      </Tabs>
    ) : (
      <OrderRepaymentCard {...this.props} />
    );
  }
}
