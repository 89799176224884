import moment from 'moment'
import React, { Component } from 'react'

// 待还款
import OrderRepayment from './order_repayment'

/**
 * 今日借款
 */
export default class OrderList extends Component {
    static TimeFormat = 'YYYY-MM-DD'
    static StartAnd = false

    render() {
        const now = moment()
        let startDateTime = now.format(OrderList.TimeFormat)
        const endDateTime = now.add(0, 'd').format(OrderList.TimeFormat)

        if (!OrderList.StartAnd) startDateTime = null
        console.log(startDateTime)
        return <OrderRepayment exportName="今日借款订单"
            queryParam={{ beginRemitTime: endDateTime, endRemitTime: endDateTime }} />
    }
}
