// 报表统计-推广渠道
import { Table, Tag } from 'element-react'
import React, { Component } from 'react'
import Pagination from '../../components/MyPagination'
import apiV2 from '../../api/api-v2'
import { RiskBillType } from './types'

/**
 * 风控账单
 */
export default class RiskBills extends Component {


    static ResultEmpty = {
        records: [], total: 0
    }

    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                current: 1,
                size: 10

            },
            tableResult: RiskBills.ResultEmpty,
            tableColumns: [
                {
                    label: '产生金额',
                    prop: 'money',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '金额类型',
                    prop: 'type',
                    align: 'center',
                    render: ({ type }) => {
                        const risk = RiskBillType.find(({ key }) => key === type)
                        return <Tag type={risk.color}>{risk.name}</Tag>
                    }
                },
                {
                    label: '创建时间',
                    prop: 'createTime',
                    align: 'center',
                }
            ]
        }

        this.serverGet()
    }



    async serverGet() {
        const { body } = await apiV2.getRiskBillList({ param: this.state.queryForm })
        this.setState({ tableResult: body || RiskBills.ResultEmpty })
    }

    changeQueryForm(name, value, refresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => { if (refresh) this.serverGet() })
    }

    render() {
        const { tableResult, tableColumns, queryForm } = this.state
        return (
            <>
                {/* <Form inline>=
                    <Form.Item>
                        <Button type="primary" onClick={() => this.serverGet()}>{'查询'}</Button>
                    </Form.Item>
                </Form> */}

                <Table columns={tableColumns} data={tableResult.records} />

                <Pagination total={queryForm.total} pageNumber={queryForm.current} pageSize={queryForm.size}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />
            </>
        )
    }
}