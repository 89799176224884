import React, { Component } from 'react'

import PropTypes from 'prop-types'
import apiV2 from '../../api/api-v2'
import Text from '../../components/text'
import { Table } from 'element-react'
import { Drawer } from 'antd'
import Space from '../../components/space'

import { Tag } from 'element-react'


/**
 * 关联设备
 */
export default class UserUseEqu extends Component {

    static authDataEmpty = []

    static propTypes = {
        userId: PropTypes.number
    }

    constructor(props) {
        super(props)

        this.state = {
            authData: UserUseEqu.authDataEmpty,
            visibleDefer: false,
            phones: [],
            authColumns: [
                {
                    label: '系统类型',
                    prop: 'appType',
                    width: '100',
                    render: ({ appType }) => {
                        const uiConfig = {
                            '1': { color: 'warning', text: '安卓' },
                            '2': { color: 'danger', text: 'IOS' }
                        }

                        const that = uiConfig[appType] || { color: 'gray', text: '未知' }

                        return (<Tag type={that.color}>{that.text}</Tag>)
                    }
                }, {
                    label: '设备号',
                    prop: 'deviceNum',
                }, {
                    label: '地址',
                    prop: 'address',
                }, {
                    label: '最后登陆时间',
                    prop: 'createTime',
                }, {
                    label: '关联用户列表',
                    prop: 'phones',
                    fixed: 'right',
                    render: ({ phones }) => {
                        phones = phones || []
                        return (<Text onClick={() => this.openPhones(phones)} color={'blue'}>{phones.length}</Text>)
                    }
                }
            ]
        }
    }

    componentDidMount() {
        this.getAuthBank()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.getAuthBank()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async getAuthBank(userId = this.props.userId) {
        const { body } = await apiV2.getUserDeviceList({ userId })
        this.setState({ authData: body || UserUseEqu.authDataEmpty })
    }


    openPhones(phones) {
        this.setState({
            visibleDefer: true,
            phones
        })
    }

    render() {
        const { authColumns, authData, phones } = this.state
        return (
            <>
                <Table columns={authColumns} data={authData} />
                <Drawer placement="bottom" height="85%" title="关联设备列表" zIndex={9999999}
                    onClose={() => this.setState({ visibleDefer: false })}
                    visible={this.state.visibleDefer}
                >
                    {
                        phones.map(p => {
                            return (<Space key={p}><Tag >{p}</Tag></Space>)
                        })
                    }
                </Drawer>
            </>

        )
    }

}