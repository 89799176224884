import React, { Component } from 'react'
import PropTypes from 'prop-types'


import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use(
    [GridComponent, BarChart, CanvasRenderer]
)

/**
 * 逾期贷款笔数
 */
export default class NumberOverdueLoansChat extends Component {

    static propTypes = {
        data: PropTypes.any,
    }

    componentDidMount() {
        const myChart = echarts.init(document.getElementById('number_overdue_loans_chat'))
        this.setState({ myChart }, this.setData)
    }

    componentDidUpdate() {
        this.setData()
    }

    setData(data = this.props.data || {}) {
        const { m1, m0 } = data
        this.state.myChart.setOption({
            title: {
                text: '逾期贷款笔数'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: ['近6月', '近12月', '近24月']
            },
            series: [
                {
                    name: 'M0+',
                    type: 'bar',
                    data: m0
                }, {
                    name: 'M1+',
                    type: 'bar',
                    data: m1
                }
            ]
        })
    }

    render() {

        return (
            <>
                <div id="number_overdue_loans_chat" style={{ width: 400, height: 200 }} />
            </>)
    }
}