import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Button, Message, Tabs } from 'element-react'
import PanoramicRadar from './panoramic_radar'
import ProbeC from './probe_C'
import MiRongDuotou from '../risk/mi_rong_duotou'
import MiRongDTB from '../risk/coms/mi-rong-dt_b'
import MiRongScore from '../risk/coms/mi-rong-score'

import apiV2 from '../../api/api-v2'
import Text from '../../components/text'
import CreditScoringReport from '../risk/credit_scoring_report'
import { ENABLE_DT_1, ENABLE_DT_2, ENABLE_FR, ENABLE_GZ, ENABLE_GZ_V2 } from '../../utils/app'
import MiRongGZai from '../risk/coms/mi-rong_gzai'
import MiRongGZaiV2 from '../risk/coms/mi-rong_gzai_v2'


/**
 * 风控
 */
export default class IiskManagement extends Component {

    static propTypes = {
        userId: PropTypes.number,
        noCreditScore: PropTypes.bool,
        noRequest: PropTypes.bool,
        radarAnalysis: PropTypes.any,
        probeCAnalysis: PropTypes.any,
        miRongDuotou: PropTypes.any,

        onRefreshProbe: PropTypes.func,
        onRefreshRadar: PropTypes.func
    }

    static mScoreDefault = 0

    constructor(props) {
        super(props)

        this.state = {
            mScore: IiskManagement.mScoreDefault
        }
    }

    componentDidMount() {
        this.getZMScore()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.getZMScore()
        }
    }
    //组件销毁前调用，清除一些事件(比如定时事件)
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async getZMScore(userId = this.props.userId, hasRefresh) {
        if (this.props.noCreditScore) return
        const { body } = await apiV2.getZMScore({
            userId
        })

        this.setState({
            mScore: body || IiskManagement.mScoreDefault
        })

        if (hasRefresh) {
            const { message, success } = await apiV2.getMirongfenByUserId({ userId })
            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }
            this.getZMScore()
        }
    }

    mScoreUI() {
        if (this.props.noCreditScore) return <></>
        return (
            <>
                {'米融分数: '}
                {this.state.mScore ? <Text color="red">{this.state.mScore}</Text> : <Button onClick={() => this.getZMScore(this.props.userId, true)} type="text">{'点击获取米融分'}</Button>}

            </>
        )
    }

    render() {
        const { radarAnalysis, probeCAnalysis } = this.props

        return (
            <Tabs type="border-card" activeName="2">
                {ENABLE_GZ_V2 ?
                    <Tabs.Pane label="内部共债" name="gz_v2">
                        <MiRongGZaiV2 userId={this.props.userId} />
                    </Tabs.Pane> : null}

                <Tabs.Pane label="米融探针" name="2">
                    <ProbeC onRefresh={this.props.onRefreshProbe} analysis={probeCAnalysis} userId={this.props.userId} />
                </Tabs.Pane>

                {ENABLE_DT_2 ?
                    <Tabs.Pane label="米融多头（B）" name="dtb">
                        <MiRongDTB userId={this.props.userId} />
                    </Tabs.Pane> : null}

                {ENABLE_DT_1 ?
                    <Tabs.Pane label="米融多头" name="duoto">
                        <MiRongDuotou onRefresh={this.props.onRefreshProbe} analysis={this.state.miRongDuotou} userId={this.props.userId} />
                    </Tabs.Pane> : null}

                {ENABLE_GZ ?
                    <Tabs.Pane label="米融共债" name="gz">
                        <MiRongGZai userId={this.props.userId} />
                    </Tabs.Pane> : null}


                <Tabs.Pane label="米融雷达" name="1">
                    <PanoramicRadar onRefresh={this.props.onRefreshRadar} analysis={radarAnalysis} userId={this.props.userId} />
                </Tabs.Pane>


                {ENABLE_FR ?
                    <Tabs.Pane label="信用分数" name="allScore">
                        <MiRongScore userId={this.props.userId} />
                    </Tabs.Pane> : null}

                <Tabs.Pane label={this.mScoreUI()} name="3" >
                    <CreditScoringReport value={this.state.mScore} />
                </Tabs.Pane>
            </Tabs>
        )
    }
}