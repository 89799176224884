// 报表统计-推广渠道
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import PromotionChannels from './promotion_channels'
import Text from '../../components/text'
import { getQueryString } from '../../utils/app'
import moment from 'moment'


/**
 * 今日渠道统计
 */
export default class DayPromotionChannels extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tableColumn: [
                {
                    label: '渠道名称',
                    prop: 'channel',
                    width: 120,
                    align: 'center',
                    fixed: true,
                    render: ({ channel }) => <Text color="blue" size="1rem" bold>{channel}</Text>
                },
            ]
        }
    }

    componentDidMount() {
        const dateParam = getQueryString('c_s_d')
        if (dateParam) {
            const date = moment(dateParam.replace('/', '')).toDate()
            console.log(date)

            this.setState({
                date
            })
        }
    }

    listQueryParamHandle(param) {
        param.dayTime = param.lastFindTime
    }



    render() {
        const dateParam = getQueryString('c_s_d')
        let date = new Date()
        if (dateParam) {
            date = moment(dateParam.replace('/', '')).toDate()
        }
        return <PromotionChannels
            tableColumn={this.state.tableColumn}
            defaultParam={{ lastFindTime: date }}
            getListFunction={apiV2.getChannelStatisticsByDay}
            queryParamHandle={this.listQueryParamHandle} />
    }
}