import React, { Component } from 'react'

import { Drawer, Alert } from 'antd'
import { Form, Steps, InputNumber, Button, Tabs, Input, Layout } from 'element-react'
import PropTypes from 'prop-types'
import Condition from '../../components/Condition'
import apiV2 from '../../api/api-v2'


/**
 * 订单分期
 */
export default class OrderStages extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func,
        tab: PropTypes.any
    }

    static RemissionAmountEmpty = {
        orderId: null,
        num: 3,
    }
    static ResultEmpty = { message: '' }
    static AgingOrderPriceEmpty = { message: '', success: true, body: [] }

    constructor(props) {
        super(props)

        this.state = {
            submitLoading: false,
            visible: false,
            addFormData: OrderStages.RemissionAmountEmpty,
            cResult: OrderStages.ResultEmpty,
            result: OrderStages.ResultEmpty,
            rSubmitResult: OrderStages.ResultEmpty,
            agingOrderPriceResult: OrderStages.AgingOrderPriceEmpty,
            count: 0
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
            this.getAgingOrderPrice()
        }
    }

    open() {
        this.setState({ visible: true })
    }

    changeAddForm(name, value) {
        const addFormData = Object.assign(this.state.addFormData, { [name]: value })
        this.setState({ addFormData })

        this.getAgingOrderPrice()
    }

    async getAgingOrderPrice(num = this.state.addFormData.num) {
        if (num) {
            const agingOrderPriceResult = await apiV2.getAgingOrderPrice({ data: { orderId: this.props.orderId, num } })
            this.setState({ agingOrderPriceResult })
        } else {
            this.setState({ agingOrderPriceResult: OrderStages.AgingOrderPriceEmpty })
        }

        const cResult = await apiV2.getUnderwayOrderFqMoney({ orderId: this.props.orderId })
        this.setState({ count: this.count + 1, cResult })
    }


    async onSubmit() {
        this.setState({ loading: true })
        try {
            const { body } = this.state.agingOrderPriceResult
            const result = await apiV2.uptAgingOrder({ data: { orderId: this.props.orderId, money: body } })
            this.setState({ result })
        } finally {
            this.setState({ loading: false })
        }
    }

    close() {
        this.setState({
            visible: false,
            addFormData: OrderStages.RemissionAmountEmpty,
            cResult: OrderStages.ResultEmpty,
            result: OrderStages.ResultEmpty,
            rSubmitResult: OrderStages.ResultEmpty,
            agingOrderPriceResult: OrderStages.AgingOrderPriceEmpty
        })
        this.props.onClose()
    }

    changeSub(index, money) {
        const cResult = this.state.cResult
        cResult.body[index].money = money
        this.setState({ cResult })
    }

    async subSubmit() {
        this.setState({ loading: true })
        try {
            const rSubmitResult = await apiV2.saveUnderwayOrderFqMoney({ param: this.state.cResult.body })
            this.setState({ rSubmitResult })
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const { addFormData, result, visible, agingOrderPriceResult, count, cResult, rSubmitResult,submitLoading } = this.state

        return (
            <Drawer title="订单分期" placement="right" onClose={() => this.close()} visible={visible} width="500">

                <Tabs type="card" value={this.props.tab || '1'}>
                    <Tabs.Pane label="平均分期" name="1" onChange={() => getAgingOrderPrice()}>
                        <Form>
                            <Form.Item label="期数">
                                <InputNumber defaultValue={addFormData.num} onChange={(e) => this.changeAddForm('num', e)}
                                    min="2" max="48" />
                            </Form.Item>

                            <Condition visible={!agingOrderPriceResult.success}>
                                <Form.Item label="分期提示">
                                    <Alert message={agingOrderPriceResult.message} type={agingOrderPriceResult.success ? 'success' : 'error'} />
                                </Form.Item>
                            </Condition>

                            <Form.Item label="分期金额" labelWidth="100%">
                                <Steps space={50} direction="vertical" >
                                    {(agingOrderPriceResult.body || []).map((f, i) => <Steps.Step key={`${count}:+${i}`} title={`第${i + 1}期：需要还款${f}元人民币`}></Steps.Step>)}
                                </Steps>
                            </Form.Item>

                            <Form.Item>
                                <Button disabled={submitLoading} loading={submitLoading} onClick={() => this.onSubmit()} type="primary">{'确定'}</Button>
                            </Form.Item>

                            <Condition visible={result.message}>
                                <Form.Item>
                                    <Alert message={result.message} type={result.success ? 'success' : 'error'} />
                                </Form.Item>
                            </Condition>
                        </Form>
                    </Tabs.Pane>
                    <Tabs.Pane label="自定义分期" name="2">
                        <Form>
                            <Condition visible={!cResult.success}>
                                <Form.Item label="获取分期信息">
                                    <Alert message={cResult.message} type={cResult.success ? 'success' : 'error'} />
                                </Form.Item>
                            </Condition>

                            {cResult.body ?
                                <Form.Item label="分期金额" labelWidth="100%">
                                    <Steps space={50} direction="vertical" >
                                        {(cResult.body || []).map((f, i) => <Steps.Step key={`${count}:+${i}`} title={
                                            <Layout.Row gutter="10" type="flex">
                                                <Layout.Col span="7"><span>{`第${i + 1}期：`}</span></Layout.Col>
                                                <Layout.Col span="16"> <Input onChange={e => this.changeSub(i, e)} type="number" value={f.money} /></Layout.Col>
                                            </Layout.Row>
                                        } />)}
                                    </Steps>
                                </Form.Item> : null}

                            <Form.Item>
                                <Button disabled={submitLoading} loading={submitLoading} onClick={() => this.subSubmit()} type="primary">{'确定'}</Button>
                            </Form.Item>

                            <Condition visible={rSubmitResult.message}>
                                <Form.Item>
                                    <Alert message={rSubmitResult.message} type={rSubmitResult.success ? 'success' : 'error'} />
                                </Form.Item>
                            </Condition>
                        </Form>
                    </Tabs.Pane>
                </Tabs>
            </Drawer>)
    }
}
