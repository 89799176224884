import React, { Component } from 'react'

import OrderToday from './today_delay'

/**
 * 所有延期
 */
export default class OrderList extends Component {

    render() {

        return (
            <OrderToday showDatePick />
        )
    }
}