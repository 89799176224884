import React, { Component } from 'react'
import Text from '../text'
import PropTypes from 'prop-types'
import { ThemeConfig } from '../../utils/format'

/**
 * 数字
 */
export default class NumberText extends Component {

    static propTypes = {
        children: PropTypes.any
    }

    render() {
        return <Text color={ThemeConfig.figureTextColor} size={ThemeConfig.size} bold>{this.props.children}</Text>
    }
}