import React, { Component } from 'react'
// import { Dropdown } from 'element-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from '@containers/user/action'
import '@styles/header.less'
import apiV2 from '../api/api-v2'
import { Button, Form, Input, Message } from 'element-react'
import { Drawer, Tooltip } from 'antd'
import { APP_LOGO_SRC, REACT_APP_ENABLE_HFB_RECHARGE, APP_LOGO_TEXT, APP_NAME } from '../utils/app'

class Header extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      adminName: '',
      hfbRechargeMy: 5000,
      visible: false,
      hfbRechargeHtml: null
    }
  }
  componentWillMount() {
    // console.log('header')
    // console.log(this.props)
  }
  componentDidMount() {

    apiV2.getUserInfo()
      .then(data => {
        if (data.success !== false) {
          this.setState({
            adminName: data.name
          })
        }
      })

    // console.log(this.props.user)
    // if (this.props.user.adminName !== '') {
    //   this.setState({
    //     adminName: this.props.user.adminName
    //   })
    // }else{
    // const obj = JSON.parse(window.sessionStorage.getItem('adminInfo'))
    // this.setState({
    //   adminName: obj.adminName
    // })
    // }
  }
  // handleCommand = async e => {
  //   if(e === '0'){
  //     this.props.logout(this.props.history)
  //     // this.props.history.push('/login')
  //   }
  // }
  handleCommand = async () => {
    this.props.logout(this.props.history)
  }

  hfbRecharge = async () => {
    const { body, message, success } = await apiV2.hfbRecharge({ money: this.state.hfbRechargeMy })

    if (!success) {
      Message.error(message)
    } else {
      // Html
      this.setState({
        hfbRechargeHtml: body,
        visible: true
      })
    }
  }

  createMarkup() {
    return { __html: this.state.hfbRechargeHtml }
  }

  render() {
    // const { adminName } = this.props.user
    // const obj = JSON.parse(window.sessionStorage.getItem('adminInfo'))
    // const { adminName } = obj
    const { adminName, hfbRechargeMy, visible } = this.state
    return (
      <ul className="header-ul flex flex-direction_row justify-content_flex-justify align-items_center" >
        {APP_LOGO_SRC ? <div className='head_v2'>
          <img className="page-logo" alt="LOGO" src={APP_LOGO_SRC} />
          {APP_LOGO_TEXT ? <span className="head_v2_name">{APP_NAME}</span> : null}
        </div>
          : (
            <li className="flex flex-direction_row">
              <h4>{'管理系统'}</h4>
              <span>{'欢迎'}{adminName}</span>
            </li>)}
        <li>

          <Tooltip title={
            <Form>
              <Form.Item>
                <Input value={hfbRechargeMy} onChange={e => this.setState({ hfbRechargeMy: e })} type="number" placeholder="请输入要充值的金额" />
              </Form.Item>
              <Form.Item>
                <Button onClick={this.hfbRecharge}>{'确定'}</Button>
              </Form.Item>
            </Form>
          }>
            {REACT_APP_ENABLE_HFB_RECHARGE ? <span >{'汇付宝充值 '}</span> : null}
          </Tooltip>

          {REACT_APP_ENABLE_HFB_RECHARGE ?
            <>
              <i class="el-icon-arrow-down down-icon" />
              <div className="split-1" />
            </> : null}

          <Drawer title="汇付宝充值" placement="right" visible={visible}
            width="50rem"
            onClose={() => this.setState({ visible: false })}>
            <div dangerouslySetInnerHTML={this.createMarkup()} />
          </Drawer>

          <span onClick={this.handleCommand}>{'退出'}</span>
          {/* <Dropdown onCommand={ this.handleCommand } menu={ (
            <Dropdown.Menu>
              <Dropdown.Item command="1">{'修改'}<i className="el-icon-edit el-icon--right" /></Dropdown.Item>
              <Dropdown.Item command="0">{'退出'}</Dropdown.Item>
            </Dropdown.Menu>
            ) }
          >
            <span className="el-dropdown-link">{'设置'}<i className="el-icon-caret-bottom el-icon--right" /></span>
          </Dropdown> */}
        </li>
      </ul>
    )
  }
}
Header.propTypes = {
  propsData: PropTypes.object,
  propsDataHistory: PropTypes.object
}
const mapStateToProps = state => {
  const {
    user
  } = state
  return {
    user
  }
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ logout }, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
