import { Button, Message, Table } from 'element-react'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import apiV2 from '../../../api/api-v2'
import { Descriptions } from 'antd'
import { fmtTime, newLoanColumnsV2 } from './mi-rong_gzai.ext'

/**
 * 米融共债（内部自定义）
 */
export default class MiRongGZaiV2 extends Component {

    static propTypes = {
        userId: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = {
            result: null,
            loanColumns: newLoanColumnsV2(),
            loading: false,
            repayColumns: [
                ...newLoanColumnsV2([
                    {
                        label: '到期时间',
                        prop: 'estimatedRepayTime',
                        align: 'center',
                        render: ({ estimatedRepayTime }) => fmtTime(estimatedRepayTime)
                    }
                    // ,
                    //  {
                    //     label: '还款金额',
                    //     prop: 'quota',
                    //     align: 'center',
                    // }
                    , {
                        label: '还款时间',
                        prop: 'repaymentTime',
                        align: 'center',
                        render: ({ repaymentTime }) => fmtTime(repaymentTime)
                    },
                ]),
            ],
        }
    }

    componentDidMount() {
        this.getMiRongGZ()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.setState({
                dialogVisible: true
            })

            this.getMiRongGZ()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async getMiRongGZ(userId = this.props.userId) {
        if (!userId) {
            this.setState({ result: null })
            return
        }
        const { body } = await apiV2.getMiRongGZV2({ userId })
        if (body) {
            this.setState({ result: body })
        } else {
            this.setState({ result: null })
        }
    }

    async refreshMiRongGZ({ userId = this.props.userId, refresh = false } =
        { userId: this.props.userId, refresh: false }) {
        this.setState({ loading: true })
        try {
            if (!userId) return
            const { message, success } = await apiV2.getMiRongGZV2({ userId, refresh })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            await this.getMiRongGZ(userId)
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const { loanColumns, repayColumns, result, loading } = this.state
        const radarData = (result || {}).debtStatistics || {}

        return result ? (
            <>
                <Button loading={loading} type="success" onClick={_ => this.refreshMiRongGZ({ refresh: true })}>{'点击刷新数据'}</Button>
                <Descriptions title="公债信息" bordered>
                    <Descriptions.Item label="累计申请借款笔数">
                        {radarData.loanTotalTransactions}
                    </Descriptions.Item>
                    <Descriptions.Item label="累计借款成功笔数">
                        {radarData.loanSuccessfulTotalTransactions}
                    </Descriptions.Item>
                    <Descriptions.Item label="借款平台数">
                        {radarData.loanTotalPlatforms}
                    </Descriptions.Item>

                    <Descriptions.Item label="借款成功平台数">
                        {radarData.loanSuccessfulTotalPlatforms}
                    </Descriptions.Item>

                    <Descriptions.Item label="成功还款平台数">
                        {radarData.paymentCompletedPlatforms}
                    </Descriptions.Item>

                    <Descriptions.Item label="逾期记录平台数">
                        {radarData.paymentOverduePlatforms}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前进行订单数">
                        {radarData.totalTransCurrentProgress}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前待审核订单数">
                        {radarData.totalTransCurrentAuditing}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前拒绝订单数">
                        {radarData.totalTransCurrentRejections}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前逾期订单数">
                        {radarData.totalTransCurrentOverdue}
                    </Descriptions.Item>
                    <Descriptions.Item label="近月逾期订单数">
                        {radarData.recentMonthsOverdueTrans}
                    </Descriptions.Item>
                    <Descriptions.Item label="历史有过逾期订单数（已还清）">
                        {radarData.totalTransOverdue}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日申请订单数">
                        {radarData.todayApplyBorrowings}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日拒绝订单数">
                        {radarData.todayApplyRejections}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日放款订单数">
                        {radarData.todayApplyRepayments}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日还款订单数">
                        {radarData.todayApplyRepayments}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日逾期订单数">
                        {radarData.todayApplyOverdue}
                    </Descriptions.Item>
                </Descriptions>
                <h2>{'借款列表'}</h2>
                <Table columns={loanColumns} data={result.individualDebt.repayments} />
                <h2>{'还款列表'}</h2>
                <Table columns={repayColumns} data={result.individualDebt.borrows} />

            </>
        ) : <Button loading={loading} type="danger" onClick={_ => this.refreshMiRongGZ()}>{'点击获取共债'}</Button>
    }
}