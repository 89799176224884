import React, { Component } from 'react'

import PropTypes from 'prop-types'
import SwitchesThatCanDisabled from '../../components/switches_that_can_disabled'


/**
 * 查询新客
 */
export default class NewUserForm extends Component {

    static propTypes = {
        value: PropTypes.bool,
        onChangeValue: PropTypes.func
    }

    render() {
        return (
            <SwitchesThatCanDisabled onText="新客" offText="旧客"
                value={this.props.value} onChangeValue={this.props.onChangeValue} />
        )
    }
}
