// 报表统计-推广渠道
import { Button, DateRangePicker, Form, Input, Table } from 'element-react'
import React, { Component } from 'react'
import Pagination from '../../components/MyPagination'
import NumberText from '../../components/view/NumberText'
import Text from '../../components/text'
import Money from '../../components/Money'
import apiV2 from '../../api/api-v2'
import moment from 'moment'
import PropTypes from 'prop-types'
import { formatDatePeriod, datesAdapterElUI, ThemeConfig } from '../../utils/format'

/**
 * 还款订单数据统计
 */
export default class RepaymentStatistics extends Component {

    static propTypes = {
        tableColumn: PropTypes.array,
        defaultParam: PropTypes.object,
        getListFunction: PropTypes.func,
        queryParamHandle: PropTypes.func,
    }


    static ResultEmpty = {
        records: [], total: 0
    }

    constructor(props) {
        super(props)

        const propTableColumn = props.tableColumn || []
        const defaultParam = props.defaultParam || {}

        this.state = {
            queryForm: Object.assign({
                channel: '',
                current: 1,
                beginTime: null,
                endTime: null,
                size: 10
            }, defaultParam),
            tableResult: RepaymentStatistics.ResultEmpty,
            tableColumns: [
                ...propTableColumn,
                {
                    label: '日期',
                    prop: 'endTime',
                    width: 130,
                    align: 'center',
                    fixed: true,
                    render: ({ endTime }) => <Text color={ThemeConfig.primaryColor}>{moment(endTime).format('YYYY-MM-DD')}</Text>
                }, {
                    label: '应还单数',
                    prop: 'sum',
                    width: 100,
                    align: 'center',
                    render: ({ sum }) => <NumberText>{sum}</NumberText>
                }, {
                    label: '实还单数',
                    prop: 'overNum',
                    width: 100,
                    align: 'center',
                    render: ({ overNum }) => <NumberText>{overNum}</NumberText>
                },
                {
                    label: '未还单数',
                    prop: 'unpaidNum',
                    width: 100,
                    align: 'center',
                    render: ({ unpaidNum }) => <NumberText>{unpaidNum}</NumberText>
                },
                {
                    label: '还款率',
                    prop: 'repaymentRate',
                    width: 120,
                    align: 'center',
                    render: ({ repaymentRate }) => <Money value={repaymentRate + '%'} />
                },
                {
                    label: '全额单数',
                    prop: 'fullNum',
                    align: 'center',
                    width: 120,
                    render: ({ fullNum }) => <NumberText>{fullNum}</NumberText>
                },


                {
                    label: '全额率',
                    prop: 'fullRate',
                    width: 140,
                    align: 'center',
                    render: ({ fullRate }) => <Money value={fullRate + '%'} />
                },
                {
                    label: '续期单数',
                    prop: 'renewalNum',
                    width: 120,
                    align: 'center',
                    render: ({ renewalNum }) => <NumberText>{renewalNum}</NumberText>
                },
                {
                    label: '续期率',
                    prop: 'renewalRate',
                    width: 120,
                    align: 'center',
                    render: ({ renewalRate }) => <Money value={renewalRate + '%'} />
                },
                {
                    label: '复借单数',
                    prop: 'recoverNum',
                    align: 'center',
                    width: 100,
                    render: ({ recoverNum }) => <NumberText>{recoverNum}</NumberText>
                },

                {
                    label: '复借率',
                    prop: 'recoverRate',
                    align: 'center',
                    width: 120,
                    render: ({ recoverRate }) => <Money value={recoverRate + '%'} />
                },

                {
                    label: '复借',
                    align: 'center',
                    subColumns: [
                        {
                            label: '应还单数',
                            prop: 'recoverSum',
                            align: 'center',
                            width: 100,
                            render: ({ recoverSum }) => <NumberText>{recoverSum}</NumberText>
                        },
                        {
                            label: '已还单数',
                            prop: 'recoverOverNum',
                            align: 'center',
                            width: 100,
                            render: ({ recoverOverNum }) => <NumberText>{recoverOverNum}</NumberText>
                        }, {
                            label: '未还单数',
                            prop: 'recoverNoNum',
                            align: 'center',
                            width: 100,
                            render: ({ recoverNoNum }) => <NumberText>{recoverNoNum}</NumberText>
                        }, {
                            label: '全额还款数',
                            prop: 'recoverFullNum',
                            align: 'center',
                            width: 110,
                            render: ({ recoverFullNum }) => <NumberText>{recoverFullNum}</NumberText>
                        },
                        {
                            label: '还款率',
                            prop: 'recoverRepaymentRate',
                            align: 'center',
                            width: 100,
                            render: ({ recoverRepaymentRate }) => <Money value={recoverRepaymentRate + '%'} />
                        }
                    ]
                },
                {
                    label: '首借',
                    align: 'center',
                    subColumns: [
                        {
                            label: '应还单数',
                            prop: 'newUserNum',
                            align: 'center',
                            width: 100,
                            render: ({ newUserNum }) => <NumberText>{newUserNum}</NumberText>
                        },
                        {
                            label: '已还单数',
                            prop: 'newOverNum',
                            align: 'center',
                            width: 100,
                            render: ({ newOverNum }) => <NumberText>{newOverNum}</NumberText>
                        }, {
                            label: '未还单数',
                            prop: 'newUnpaidNum',
                            align: 'center',
                            width: 100,
                            render: ({ newUnpaidNum }) => <NumberText>{newUnpaidNum}</NumberText>
                        }, {
                            label: '全额还款数',
                            prop: 'newFullNum',
                            align: 'center',
                            width: 110,
                            render: ({ newFullNum }) => <NumberText>{newFullNum}</NumberText>
                        },
                        {
                            label: '还款率',
                            prop: 'newRepaymentRate',
                            align: 'center',
                            width: 120,
                            render: ({ newRepaymentRate }) => <Money value={newRepaymentRate + '%'} />
                        }
                    ]
                },

            ]
        }

        this.serverGet()
    }



    async serverGet() {
        const queryForm = JSON.parse(JSON.stringify(this.state.queryForm))
        if (queryForm.lastFindTime) {
            queryForm.lastFindTime = moment(queryForm.lastFindTime).format('YYYY-MM-DD')
        }
        const queryParamHandle = this.props.queryParamHandle
        if (queryParamHandle) queryParamHandle(queryForm)

        const getFunc = this.props.getListFunction || apiV2.getRepaymentStatistics
        const { body } = await getFunc({ query: queryForm })
        this.setState({ tableResult: body || RepaymentStatistics.ResultEmpty })
    }

    changeQueryForm(name, value, refresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => { if (refresh) this.serverGet() })
    }

    changeQueryFormDate(dateArr, startName, endName) {
        const [startValue, endValue] = formatDatePeriod(dateArr)
        const queryForm = Object.assign(this.state.queryForm, { [startName]: startValue, [endName]: endValue })
        this.setState({ queryForm })
    }

    render() {
        const { tableResult, tableColumns, queryForm } = this.state
        return (
            <>
                <Form inline>
                    <Form.Item label="渠道名称">
                        <Input value={queryForm.channel}
                            onChange={(e) => this.changeQueryForm('channel', e)} />
                    </Form.Item>
                    <Form.Item label="统计日期">
                        <DateRangePicker value={datesAdapterElUI(queryForm.beginTime, queryForm.endTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'beginTime', 'endTime')} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => this.serverGet()}>{'查询'}</Button>
                    </Form.Item>
                </Form>

                <Table border columns={tableColumns} data={tableResult.records} />

                <Pagination total={tableResult.total} pageNumber={queryForm.current} pageSize={queryForm.size}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />
            </>
        )
    }
}