import { Drawer } from 'antd'
import { Button, Form, Input, Alert, InputNumber } from 'element-react'
import React, { Component } from 'react'

import PropTypes from 'prop-types'
import apiV2 from '../../../api/api-v2'
import Condition from '../../../components/Condition'



/**
 * 客服表单
 */
export default class ContactCustomerServiceForm extends Component {

    static propTypes = {
        show: PropTypes.bool,
        onClose: PropTypes.func,
        data: PropTypes.object
    }

    static AddEmpty = {
        content: null,
        sort: 1,
        title: null,
    }

    constructor(props) {
        super(props)

        this.state = {
            submitLoading: false,
            visible: props.show,
            formData: ContactCustomerServiceForm.AddEmpty,
            result: { message: '' }
        }
    }



    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show) {
            const formData = this.props.data
            this.setState({ visible: true, formData: formData ? formData : ContactCustomerServiceForm.AddEmpty })
        }
    }



    onClose() {
        this.setState({ visible: false, result: { message: '' } })
        const onClose = this.props.onClose
        if (onClose) onClose()
    }

    changeFormData(name, value) {
        const formData = Object.assign(this.state.formData, { [name]: value })
        this.setState({ formData })
    }

    async onSubmit() {
        this.setState({ loading: true })
        try {
            const result = await apiV2.saveContactCustomerServiceList({ param: this.state.formData })
            this.setState({ result })
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const { formData, result, visible,submitLoading } = this.state

        return (
            <Drawer title="联系客服信息" visible={visible} width="600" onClose={() => this.onClose()}>
                <Form>
                    <Form.Item label="标题">
                        <Input type="textarea" value={formData.title} onChange={e => this.changeFormData('title', e)} />
                    </Form.Item>
                    <Form.Item label="内容">
                        <Input type="textarea" value={formData.content} onChange={e => this.changeFormData('content', e)} />
                    </Form.Item>
                    <Form.Item label="排序">
                        <InputNumber defaultValue={formData.sort} onChange={e => this.changeFormData('sort', e)} />
                    </Form.Item>

                    <Form.Item >
                        <Button onClick={() => this.onSubmit()} type="primary">{'提交'}</Button>
                    </Form.Item>

                    <Condition visible={result.message}>
                        <Form.Item>
                            <Alert disabled={submitLoading} loading={submitLoading} title={result.message} type={result.success ? 'success' : 'error'} />
                        </Form.Item>
                    </Condition>
                </Form>
            </Drawer>
        )
    }
}
