import React, { Component } from 'react'

import apiV2 from '../../api/api-v2'
import { Descriptions, Result } from 'antd'
import { Tag } from 'element-react'
import Image from '../../components/Image'

import PropTypes from 'prop-types'

/**
 * 认证信息
 */
export default class IdCardAuth extends Component {

    static authDataEmpty = {}

    static propTypes = {
        userId: PropTypes.number,
    }

    constructor(props) {
        super(props)

        this.state = {
            authData: IdCardAuth.authDataEmpty
        }
    }

    componentDidMount() {
        this.getAuthEmergency()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.getAuthEmergency()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async getAuthEmergency(userId = this.props.userId) {
        const { body } = await apiV2.getIdCardAuth({ userId })

        this.setState({
            authData: body || IdCardAuth.authDataEmpty
        })
    }

    authStatusUI(value) {
        const uiConfig = {
            '2': { color: 'warning', text: '审核中' },
            '3': { color: 'success', text: '认证成功' },
            '4': { color: 'danger', text: '审核失败' }
        }

        const that = uiConfig[value] || { text: '未认证' }

        return (<Tag type={that.color}>{that.text}</Tag>)
    }

    datectResultCodeUI(value) {
        const uiConfig = {
            '0': { color: 'warning', text: '不匹配' },
            '1': { color: 'success', text: '匹配成功' },
            '2': { color: 'danger', text: '其他错误' }
        }

        const that = uiConfig[value] || { text: '无信息' }

        return (<Tag type={that.color}>{that.text}</Tag>)
    }

    render() {
        const data = this.state.authData

        return (
            <>
                <Descriptions bordered>
                    <Descriptions.Item label="认证状态"> {this.authStatusUI(data.authStatus)}</Descriptions.Item>
                    <Descriptions.Item label="真实姓名">{data.realUserName}</Descriptions.Item>
                    <Descriptions.Item label="身份证号">{data.cardNum}</Descriptions.Item>
                    <Descriptions.Item label="人脸认证">{this.datectResultCodeUI(data.datectResultCode)}</Descriptions.Item>
                    <Descriptions.Item label="活体错误">{data.errorMessage}</Descriptions.Item>
                    <Descriptions.Item label="身份证正面"><Image src={data.frontUrl} width="3rem" /></Descriptions.Item>
                    <Descriptions.Item label="活体照片"><Image src={data.livingBodyImg} width="3rem" /></Descriptions.Item>
                    <Descriptions.Item label="身份证反面"><Image src={data.reverseUrl} width="3rem" /></Descriptions.Item>
                    <Descriptions.Item label="身份一致性">
                        <Result style={{ padding: 0 }} status={data.validity ? 'success' : 'warning'} />{ }
                    </Descriptions.Item>
                    <Descriptions.Item label="自拍照"><Image src={data.autodyneUrl} width="3rem" /></Descriptions.Item>
                    <Descriptions.Item label="首次认证时间">{data.createTime}</Descriptions.Item>
                    <Descriptions.Item label="最后一次认证时间">{data.uptTime}</Descriptions.Item>
                </Descriptions>
            </>
        )
    }
}