import { Drawer } from 'antd'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RiskManagement from '../member/risk_management'

export default class RiskPopup extends Component {

    static propTypes = {
        radarData: PropTypes.any,
        probeCData: PropTypes.any,

        onRefreshProbe: PropTypes.func,
        onRefreshRadar: PropTypes.func
    }

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            radarData: null,
            probeCData: null
        }

    }

    open() {
        this.setState({ visible: true })
    }

    render() {
        const { visible } = this.state
        const { radarData, probeCData } = this.props

        return (
            <Drawer visible={visible} onClose={() => this.setState({ visible: false })} placement="bottom" height="80%">
                <RiskManagement noCreditScore radarAnalysis={radarData} probeCAnalysis={probeCData} noRequest
                    onRefreshProbe={this.props.onRefreshProbe} onRefreshRadar={this.props.onRefreshRadar}
                />
            </Drawer>
        )
    }
}