import { Form, DateRangePicker, Button, Table, Tag } from 'element-react'
import React, { Component } from 'react'
import { datesAdapterElUI, formatDatePeriod } from '../../utils/format'
import { OrderBillMoneyType } from '../loan/types'
import api from '../../api/api-v2'
import Text from '../../components/text'
import Money from '../../components/Money'

// 交易账单统计
export default class TransBillStatistics extends Component {

    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                beginTime: null,
                endTime: null
            },
            columns: [{
                label: '总笔数',
                prop: 'num',
                width: 140,
                align: 'center',
                render: ({ num }) => <Text color="blue">{num}</Text>
            }, {
                label: '总金额',
                prop: 'sumMoney',
                align: 'center',
                render: ({ sumMoney }) => <Money value={sumMoney} />
            }, {
                label: '账单类型',
                prop: 'type',
                align: 'center',
                fixed: true,
                render: ({ type }) => <Tag type={OrderBillMoneyType[type].color}>{OrderBillMoneyType[type].name}</Tag>
            },
            {
                label: '支付宝(金额/笔数)',
                align: 'center',
                render: ({ aliMoney, aliNum }) => <Text color="blue">{aliMoney}{'/'}{aliNum}</Text>
            }, {
                label: '线下(金额/笔数)',
                align: 'center',
                render: ({ offlineNum, offlineMoney }) => <Text color="blue">{offlineMoney}{'/'}{offlineNum}</Text>
            }, {
                label: '微信(金额/笔数)',
                align: 'center',
                render: ({ wxMoney, wxNum }) => <Text color="blue">{wxMoney}{'/'}{wxNum}</Text>
            }, {
                label: '银行卡(金额/笔数)',
                align: 'center',
                render: ({ bankMoney, bankNum }) => <Text color="blue">{bankMoney}{'/'}{bankNum}</Text>
            }, {
                label: '汇支付宝(金额/笔数)',
                align: 'center',
                render: ({ hfAliMoney, hfAliNum }) => <Text color="blue">{hfAliMoney}{'/'}{hfAliNum}</Text>
            }, {
                label: '汇微信(金额/笔数)',
                align: 'center',
                render: ({ hfWxMoney, hfWxNum }) => <Text color="blue">{hfWxMoney}{'/'}{hfWxNum}</Text>
            }],
            result: null
        }
    }

    componentDidMount() {
        this.fetchResult()
    }

    // 修改查询表单参数
    editQueryForm(name, resultRefresh) {
        return v => {
            const queryForm = Object.assign(this.state.queryForm, { [name]: v })
            this.setState(queryForm, () => {
                if (resultRefresh) this.fetchResult()
            })
        }
    }

    // 获取数据
    async fetchResult() {
        const { body } = await api.fetchTransBillStatistics({ param: this.state.queryForm })
        this.setState({
            result: body
        })
    }

    // 修改查询表单参数-时间区间
    editQueryFormDate(startName, endName) {
        return v => {
            const [startValue, endValue] = formatDatePeriod(v)
            const queryForm = Object.assign(this.state.queryForm, { [startName]: startValue, [endName]: endValue })
            this.setState(queryForm)
        }
    }

    render() {
        const { queryForm, columns, result } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="日期">
                        <DateRangePicker value={datesAdapterElUI(queryForm.beginTime, queryForm.endTime)}
                            onChange={this.editQueryFormDate('beginTime', 'endTime')} />
                    </Form.Item>
                    <Form.Item >
                        <Button onClick={() => this.fetchResult()}>{'查询'}</Button>
                    </Form.Item>
                </Form>
                {result ?
                    <>
                        <Table columns={columns} data={result} />
                    </> : null}

            </>)
    }
}