import React, { Component } from 'react'
import PropTypes from 'prop-types'


import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use(
    [GridComponent, BarChart, CanvasRenderer]
)

/**
 * 贷款机构数
 */
export default class LendersChat extends Component {

    static propTypes = {
        data: PropTypes.any,
    }

    componentDidMount() {
        const myChart = echarts.init(document.getElementById('lenders_chat'))
        this.setState({ myChart }, this.setData)
    }

    componentDidUpdate() {
        this.setData()
    }

    setData(data = this.props.data || {}) {
        const { auriferous, onlineLoan } = data
        this.state.myChart.setOption({
            title: {
                text: '贷款机构数'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: ['近12月', '近24月']
            },
            series: [
                {
                    name: '消金类',
                    type: 'bar',
                    data: auriferous
                }, {
                    name: '网贷类',
                    type: 'bar',
                    data: onlineLoan
                }
            ]
        })
    }

    render() {

        return (
            <>
                <div id="lenders_chat" style={{ width: 400, height: 200 }} />
            </>)
    }
}