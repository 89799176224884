import React, { Component } from 'react'
import PropTypes from 'prop-types'


import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use(
    [GridComponent, BarChart, CanvasRenderer]
)

/**
 * 评分报告
 */
export default class QueryStatisticsCreditInstitutions extends Component {

    static propTypes = {
        data: PropTypes.array,
    }

    componentDidMount() {
        const myChart = echarts.init(document.getElementById('query_statistics_credit_institutions_main'))
        this.setState({ myChart }, this.setData)
    }

    componentDidUpdate() {
        this.setData()
    }

    setData(data = this.props.data) {
        this.state.myChart.setOption({
            title: {
                text: '机构查询笔数'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: ['3个月', '6个月', '12个月']
            },
            series: [
                {
                    name: '笔数',
                    type: 'bar',
                    data
                }
            ]
        })
    }

    render() {

        return (
            <>
                <div id="query_statistics_credit_institutions_main" style={{ width: 400, height: 200 }} />
            </>)
    }
}