import React, { Component } from 'react'

import OrderDuaTodayReadOnly from '../loan/order_due_today'

/**
 * 只读
 */
export default class OrderList extends Component {

    render() {

        return (
            <OrderDuaTodayReadOnly readOnly />
        )
    }
}