// 报表统计-推广渠道
import { Drawer } from 'antd'
import { Button, Form, Input, Message } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import PropTypes from 'prop-types'

export default class RidkUserForm extends Component {

    static propTypes = {
        onSubmittedSuccessfully: PropTypes.func
    }

    static FormEmpty = {
        idCardNum: '',
        mobile: '',
        name: ''
    }

    constructor(props) {
        super(props)

        this.state = {
            dataForm: RidkUserForm.FormEmpty,
            visible: false
        }

    }

    async submit() {
        const { success, message } = await apiV2.addAdminUserRisk({ form: this.state.dataForm })

        if (success) {
            Message.success(message)
            this.setState({ dataForm: RidkUserForm.FormEmpty })
            if (this.props.onSubmittedSuccessfully) this.props.onSubmittedSuccessfully()
        } else {
            Message.error(message)
        }
    }

    changeDataForm(name, value) {
        const dataForm = Object.assign(this.state.dataForm, { [name]: value })
        this.setState({ dataForm })
    }

    open() {
        this.setState({ visible: true })
    }

    render() {
        const { dataForm, visible } = this.state
        return (
            <Drawer visible={visible} onClose={() => this.setState({ visible: false })}>
                <Form>
                    <Form.Item label="身份证号码">
                        <Input value={dataForm.idCardNum}
                            onChange={(e) => this.changeDataForm('idCardNum', e)} />
                    </Form.Item>
                    <Form.Item label="真实姓名">
                        <Input value={dataForm.name}
                            onChange={(e) => this.changeDataForm('name', e)} />
                    </Form.Item>
                    <Form.Item label="手机号">
                        <Input value={dataForm.mobile}
                            onChange={(e) => this.changeDataForm('mobile', e)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={() => this.submit()}>{'提交'}</Button>
                    </Form.Item>
                </Form>
            </Drawer>
        )
    }
}