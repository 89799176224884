import { Button, Message, Table } from 'element-react'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import apiV2 from '../../../api/api-v2'
import { Descriptions } from 'antd'
import { newLoanColumns } from './mi-rong_gzai.ext'

/**
 * 米融共债
 */
export default class MiRongGZai extends Component {

    static propTypes = {
        userId: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = {
            result: null,
            loanColumns: newLoanColumns(),
            loading: false,
            repayColumns: [
                ...newLoanColumns(),
                {
                    label: '到期时间',
                    prop: 'dueDate',
                    align: 'center',
                    fixed: true,
                }, {
                    label: '还款金额',
                    prop: 'repAmount',
                    align: 'center',
                }, {
                    label: '还款时间',
                    prop: 'repTime',
                    align: 'center',
                }
            ],
        }
    }

    componentDidMount() {
        this.getMiRongGZ()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.setState({
                dialogVisible: true
            })

            this.getMiRongGZ()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async getMiRongGZ(userId = this.props.userId) {
        if (!userId) {
            this.setState({ result: null })
            return
        }
        const { body } = await apiV2.getMiRongGZ({ userId })
        if (body) {
            this.setState({ result: JSON.parse(body) })
        } else {
            this.setState({ result: null })
        }
    }

    async refreshMiRongGZ({ userId = this.props.userId, refresh = false } = { userId: this.props.userId, refresh: false }) {
        this.setState({ loading: true })
        try {
            if (!userId) return
            const { message, success } = await apiV2.refreshMiRongGZ({ userId, refresh })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            await this.getMiRongGZ(userId)
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const { loanColumns, repayColumns, result, loading } = this.state
        const radarData = (result || {}).radarData || {}

        return result ? (
            <>
                <Button loading={loading} type="success" onClick={_ => this.refreshMiRongGZ({ refresh: true })}>{'点击刷新数据'}</Button>
                <Descriptions title="公债信息" bordered>
                    <Descriptions.Item label="累计申请借款笔数">
                        {radarData.loanCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="累计借款成功笔数">
                        {radarData.loanSucCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="借款平台数">
                        {radarData.loanPrCount}
                    </Descriptions.Item>

                    <Descriptions.Item label="借款成功平台数">
                        {radarData.loanSucPrCount}
                    </Descriptions.Item>

                    <Descriptions.Item label="成功还款平台数">
                        {radarData.repSucPrCount}
                    </Descriptions.Item>

                    <Descriptions.Item label="逾期记录平台数">
                        {radarData.ovePrCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前进行订单数">
                        {radarData.curOrderCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前待审核订单数">
                        {radarData.curWAuthCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前拒绝订单数">
                        {radarData.curRefCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前逾期订单数">
                        {radarData.curOveCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="近月逾期订单数">
                        {radarData.monOveCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="历史有过逾期订单数（已还清）">
                        {radarData.totalOveRepCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日申请订单数">
                        {radarData.todayLoanCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日拒绝订单数">
                        {radarData.todayRefCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日放款订单数">
                        {radarData.todayLoanSucCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日还款订单数">
                        {radarData.todayRepSucCount}
                    </Descriptions.Item>
                    <Descriptions.Item label="今日逾期订单数">
                        {radarData.todayOveCount}
                    </Descriptions.Item>
                </Descriptions>
                <h2>{'借款列表'}</h2>
                <Table columns={loanColumns} data={result.loanList} />
                <h2>{'还款列表'}</h2>
                <Table columns={repayColumns} data={result.repList} />

            </>
        ) : <Button loading={loading} type="danger" onClick={_ => this.refreshMiRongGZ()}>{'点击获取米融共债'}</Button>
    }
}