import React, { Component } from "react";

import OrderOverdue from "../loan/order_overdue";
import apiV2 from "../../api/api-v2";
import PropTypes from "prop-types";

/**
 * 首借逾期
 */
export default class OrderList extends Component {
  static propTypes = {
    readOnly: PropTypes.bool,
  };

  newOverExportExcel() {
    apiV2.newOverExportExcel({ isDownload: true });
  }

  render() {
    return (
      <OrderOverdue
        rquestListFunction={apiV2.todayForReimbursementOrderList}
        readOnly={this.props.readOnly}
        orderAllocation
        exportClick={() => this.newOverExportExcel()}
        queryParam={{ isNewUser: true }}
      />
    );
  }
}
