import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'element-react'
import apiV2 from '../api/api-v2'

/**
* 金额
*/
export default class ManageUserSelect extends Component {

    static propTypes = {
        value: PropTypes.any,
        onChange: PropTypes.func
    }

    constructor(props) {
        super(props)
        this.state = {
            list: [],
        };
    }

    async getSelectData() {
        const { body: { records } } = await apiV2.getSysUserList({ pageSize: 10000 });
        this.setState({ list: records });
    }

    componentDidMount() {
        this.getSelectData();
    }

    render() {
        return (
            <Select placeholder='请选择管理员' value={this.props.value} onChange={this.props.onChange}
                filterable clearable>
                {this.state.list.map(({ id, name }) => {
                    return <Select.Option value={id} label={name}>{name}</Select.Option>
                })}
            </Select>
        )
    }
}
