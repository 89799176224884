import React, { Component } from 'react'

import { Table, Button, Form, Pagination, Dialog, Input, Message } from 'element-react'
import apiV2 from '../../api/api-v2'
import { Statistic, Drawer } from 'antd'
import LoanQuotaDefer from './quota_defer'
import { MoneyStatisticsStyle } from '../../utils/format'

/**
 * 借款额度
 */
export default class LoanQuota extends Component {

    static propTypes = {}
    static quotaDataEmpty = { total: 0, records: [] }
    static quotaUpdateEmpty = { money: 0, isDelete: false }

    constructor(props) {
        super(props)

        this.state = {
            deferQuotaId: null,
            visibleDefer: false,
            quotaUpdate: LoanQuota.quotaUpdateEmpty,
            dialogVisible: false,
            quotaColumns: [{
                label: '#',
                prop: 'quotaId',
                fixed: true,
                width: '60'
            }, {
                label: '额度',
                prop: 'money',
                render: (row) => {
                    return (
                        <Statistic value={row.money+' '} precision={2}
                            valueStyle={MoneyStatisticsStyle}
                            suffix={<i size="mini" className="el-icon-edit" onClick={this.openEditQuota.bind(this, row)} />} />
                    )
                }
            }, {
                label: '添加时间',
                prop: 'createTime',
            }, {
                label: '修改时间',
                prop: 'uptTime',
            }, {
                label: '操作',
                prop: 'row',
                fixed: 'right',
                render: (row) => {
                    return (
                        <>
                            <Button onClick={() => this.quotaSubmitState(row)}
                                size="mini" type={row.isDelete ? 'danger' : 'success'}>{row.isDelete ? '启用' : '禁用'}
                            </Button>
                            <Button size="mini" type="text" onClick={() => this.openDeferQuota(row.quotaId)}>{'周期管理'}</Button>
                        </>)
                }
            }],
            quotaData: LoanQuota.quotaDataEmpty,
            quotaQuery: {
                current: 1,
                size: 10
            }
        }
    }

    componentWillMount() {
        this.getQuotaList()
    }

    async getQuotaList() {
        const { body } = await apiV2.getQuotaList({ query: this.state.quotaQuery })

        if (body) {
            this.setState({
                quotaData: body
            })
        }
    }

    openEditQuota(quotaUpdate) {
        this.setState({
            quotaUpdate,
            dialogVisible: true,
        })
    }


    changeCurrentQuota(current) {
        const quotaQuery = Object.assign(this.state.quotaQuery, { current })
        this.setState({
            quotaQuery
        }, this.getQuotaList)
    }

    updateFormQuota(name, value, row = this.state.quotaUpdate) {
        const quotaUpdate = Object.assign(row, { [name]: value })
        this.setState({
            quotaUpdate
        })
    }

    async quotaSubmit(data = this.state.quotaUpdate) {
        try {
            const { message, success } = await apiV2.saveQuota({ data })
            if (success) {
                await this.getQuotaList()
                Message.success(message || '修改成功')
            } else {
                Message.error(message)
            }
        } finally {
            this.setState({
                quotaUpdate: LoanQuota.quotaUpdateEmpty,
                dialogVisible: false
            })
        }
    }

    async quotaSubmitState(row) {
        this.updateFormQuota('isDelete', !row.isDelete, row)
        await this.quotaSubmit(row)
    }

    openDeferQuota(deferQuotaId) {
        this.setState({
            visibleDefer: true,
            deferQuotaId
        })
    }

    render() {
        const { quotaColumns, quotaQuery: { current, size }, quotaData, quotaUpdate } = this.state
        return (
            <>
                <Form>
                    <Form.Item>
                        <Button onClick={() => this.openEditQuota(LoanQuota.quotaUpdateEmpty)} type="primary">{'添加'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={quotaColumns} data={quotaData.records} />

                <Pagination total={quotaData.total} current={current} size={size}
                    onCurrentChange={this.changeCurrentQuota.bind(this)} />

                <Dialog
                    title="额度表单"
                    visible={this.state.dialogVisible}
                    onCancel={() => this.setState({ dialogVisible: false })}
                >
                    <Dialog.Body>
                        <Form>
                            <Form.Item>
                                <Input onChange={this.updateFormQuota.bind(this, 'money')} value={quotaUpdate.money} placeholder="请输入额度" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="danger" onClick={() => this.quotaSubmit()} size="small">{'提交'}</Button>
                            </Form.Item>
                        </Form>
                    </Dialog.Body>
                </Dialog>
                <Drawer title="额度延期管理" placement="bottom" height="80%"
                    onClose={() => this.setState({ visibleDefer: false })}
                    visible={this.state.visibleDefer}>
                    <LoanQuotaDefer quotaId={this.state.deferQuotaId} />
                </Drawer>
            </>)
    }
}