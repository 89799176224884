import React, { Component } from 'react'
import apiV2 from '../../../api/api-v2'

import PropTypes from 'prop-types'
import { Drawer } from 'antd'
import { Alert, Button, Form, Input, InputNumber } from 'element-react'


export default class Order extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func
    }

    constructor(props) {
        super(props)

        this.state = {
            formData: {
                deadlineDay: 7,
                quota: 0,
                rate: 0.2
            },
            submitResult: null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
        }
    }
    //组件销毁前调用，清除一些事件(比如定时事件)
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    open() {
        this.setState({ show: true })
    }

    close() {
        this.setState({ show: false, submitResult: null })
        const onClose = this.props.onClose
        if (onClose) onClose()
    }

    changeQueryParam(name, value) {
        const formData = Object.assign(this.state.formData, { [name]: value })
        this.setState(formData)
    }

    async submit() {
        this.setState({ loading: true })
        try {
            const param = this.state.formData
            param.orderId = this.props.orderId
            const submitResult = await apiV2.uptOrderToOnly({ param })
            this.setState({ submitResult })
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const { show, formData, submitResult,submitLoading } = this.state

        return (
            <Drawer visible={show} onClose={() => this.close()}>
                <Form>
                    <Form.Item label="周期(天)">
                        <InputNumber min={1} defaultValue={formData.deadlineDay} onChange={e => this.changeQueryParam('deadlineDay', e)} />
                    </Form.Item>
                    <Form.Item label="借款额度">
                        <Input type="number" value={formData.quota} onChange={e => this.changeQueryParam('quota', e)} />
                    </Form.Item>
                    <Form.Item label="服务费率：0-1之间">
                        <Input type="number" value={formData.rate} onChange={e => this.changeQueryParam('rate', e)} />
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={submitLoading} loading={submitLoading} type="primary" onClick={() => this.submit()}>{'提交'}</Button>
                    </Form.Item>
                    {submitResult ?
                        <Form.Item>
                            <Alert type="warning" title={submitResult.message} />
                        </Form.Item> : null}
                </Form>
            </Drawer>)
    }

}