import React, { Component } from 'react'

import { Table, Button, Form, Pagination, Input, Message } from 'element-react'
import apiV2 from '../../api/api-v2'
import { Statistic, Drawer } from 'antd'
import { MoneyStatisticsStyle } from '../../utils/format'


/**
 * 延期额度
 */
export default class LoanExtensionRate extends Component {

    static rateDataEmpty = { total: 0, records: [] }
    static quotaUpdateEmpty = { isDelete: false, dayNum: 0, deferRate: 0.03 }

    constructor(props) {
        super(props)

        this.state = {
            saveVisible: false,
            quotaUpdate: LoanExtensionRate.quotaUpdateEmpty,
            rateColumns: [{
                label: '#',
                prop: 'deferId',
                fixed: true,
                width: '60'
            }, {
                label: '延期天数',
                prop: 'dayNum',
                render: (row) => (<Statistic valueStyle={MoneyStatisticsStyle} value={row.dayNum} />)
            }, {
                label: '添加时间',
                prop: 'createTime',
            }, {
                label: '费率',
                prop: 'deferRate',
                render: (row) => (<Statistic valueStyle={MoneyStatisticsStyle} value={row.deferRate * 100 + '%'} />)
            }, {
                label: '修改时间',
                prop: 'uptTime',
            }, {
                label: '操作',
                prop: 'row',
                fixed: 'right',
                render: (row) => {
                    return (
                        <>
                            <Button onClick={() => this.quotaSubmitState(row)}
                                size="mini" type={row.isDelete ? 'danger' : 'success'}>{row.isDelete ? '启用' : '禁用'}
                            </Button>
                            <Button onClick={() => this.openEditQuota(row)} size="mini" type="text">{'编辑'}</Button>

                        </>)
                }
            }],
            rateData: LoanExtensionRate.rateDataEmpty,
            query: {
                current: 1,
                size: 10
            }
        }
    }

    componentDidMount() {
        this.getRateList()
    }

    async getRateList() {
        const { body } = await apiV2.getQuotaDeferList({ data: this.state.query })

        if (body) {
            this.setState({
                rateData: body
            })
        }
    }

    changeCurrentQuery(current) {
        const query = Object.assign(this.state.query, { current })
        this.setState({
            query
        }, this.getRateList)
    }

    updateFormQuota(name, value, row = this.state.quotaUpdate) {
        const quotaUpdate = Object.assign(row, { [name]: value })
        this.setState({
            quotaUpdate
        })
    }

    async quotaSubmit(data = this.state.quotaUpdate) {
        try {
            const { message, success } = await apiV2.saveQuotaDefer({ data })
            if (success) {
                await this.getRateList()
                Message.success(message || '修改成功')
            } else {
                Message.error(message)
            }
        } finally {
            this.setState({
                quotaUpdate: LoanExtensionRate.quotaUpdateEmpty,
                saveVisible: false
            })

            await this.getRateList()
        }
    }

    openEditQuota(quotaUpdate) {
        this.setState({
            quotaUpdate,
            saveVisible: true,
        })
    }

    async quotaSubmitState(row) {
        this.updateFormQuota('isDelete', !row.isDelete, row)
        await this.quotaSubmit(row)
    }



    render() {
        const { rateColumns, rateData, query: { current, size }, quotaUpdate, saveVisible } = this.state
        return (
            <>
                <Form>
                    <Form.Item>
                        <Button onClick={() => this.openEditQuota(LoanExtensionRate.quotaUpdateEmpty)} type="primary">{'添加'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={rateColumns} data={rateData.records} />

                <Pagination total={rateData.total} current={current} size={size}
                    onCurrentChange={this.changeCurrentQuery.bind(this)} />

                <Drawer width="30rem" visible={saveVisible} title="保存数据至服务端" onClose={() => this.setState({ saveVisible: false })}>
                    <Form>
                        <Form.Item label="期限">
                            <Input onChange={this.updateFormQuota.bind(this, 'dayNum')} value={quotaUpdate.dayNum}
                                placeholder="借款期限（天）" />
                        </Form.Item>
                        <Form.Item label="费率">
                            <Input onChange={this.updateFormQuota.bind(this, 'deferRate')} value={quotaUpdate.deferRate}
                                placeholder="费率（百分比小数）: 0.003表示千分之3" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="danger" onClick={() => this.quotaSubmit()} size="small">{'提交'}</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </>)
    }
}