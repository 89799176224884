import React, { Component } from 'react'

import { Drawer, Alert } from 'antd'
import { Radio, Form, Input, Tag } from 'element-react'
import { Button } from 'element-react/next'
import PropTypes from 'prop-types'
import Condition from '../../components/Condition'
import apiV2 from '../../api/api-v2'


/**
 * 订单还款
 */
export default class OrderReduction extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func,
        showRepaymentMethod: PropTypes.bool
    }

    static RemissionAmountEmpty = {
        orderId: null,
        remissionAmount: 0,
        remissionOverAmount: 0,
        paymentType: 1,
    }
    static ResultEmpty = { message: '' }

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            addFormData: OrderReduction.RemissionAmountEmpty,
            result: OrderReduction.ResultEmpty,
            originalAmount: { allRepaymentMoney: 0, agingRepaymentMoney: 0, overMoney: 0 }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
            this.getUnderwayOrderMoney()
        }
    }

    open() {
        this.setState({ visible: true })
    }

    changeAddForm(name, value) {
        const addFormData = Object.assign(this.state.addFormData, { [name]: value })
        this.setState({ addFormData })
    }

    async getUnderwayOrderMoney() {
        const { body } = await apiV2.getUnderwayOrderMoney({ orderId: this.props.orderId })
        this.setState({ originalAmount: body || {} })
    }

    async onSubmit() {
        const data = this.state.addFormData
        data.orderId = this.props.orderId
        const result = await apiV2.repaymentOrder({ data })
        this.setState({ result })
    }

    close() {
        this.setState({ visible: false })
        this.props.onClose()
    }

    render() {
        const { addFormData, result, visible, originalAmount } = this.state

        return (
            <Drawer title="订单还款" placement="right" onClose={() => this.close()} visible={visible}>
                <Form labelPosition="top">
                    {this.props.showRepaymentMethod ?
                        <Form.Item label="还款方式">
                            <Radio.Group value={addFormData.paymentType} onChange={(e) => this.changeAddForm('paymentType', e)}>
                                <Radio value={1}>{'全款'}</Radio>
                                <Radio value={2}>{'分期'}</Radio>
                            </Radio.Group>
                        </Form.Item> : null}

                    <Form.Item label="减免还款金额">
                        <Tag type="warning">{`还款金额：${(addFormData.paymentType === 1 ? originalAmount.allRepaymentMoney : originalAmount.agingRepaymentMoney) || 0}`}</Tag>
                        <Input value={addFormData.remissionAmount} onChange={(e) => this.changeAddForm('remissionAmount', e)}
                            placeholder="精确到分" />
                    </Form.Item>

                    <Form.Item label="减免逾期金额">
                        <Tag type="warning">{`逾期金额：${originalAmount.overMoney || 0}`}</Tag>
                        <Input value={addFormData.remissionOverAmount} onChange={(e) => this.changeAddForm('remissionOverAmount', e)}
                            placeholder="精确到分" />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.onSubmit()} type="primary">{'确定'}</Button>
                    </Form.Item>

                    <Condition visible={result.message}>
                        <Form.Item>
                            <Alert message={result.message} type={result.success ? 'success' : 'error'} />
                        </Form.Item>
                    </Condition>
                </Form>
            </Drawer>)
    }
}
