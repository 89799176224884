import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * 启用，停用
 */
export default class Status extends Component {

    static propTypes = {
        enable: PropTypes.any,
        style: PropTypes.object
    }

    render() {
        const style = this.props.style
        return (this.props.enable ? (<i style={style} className="el-icon-check" />) : (<i style={style} className="el-icon-close" />))
    }
}