import { Button, DateRangePicker, Form, Table, Input } from 'element-react'
import React, { Component } from 'react'
import Space from '../../components/space'
import Pagination from '../../components/MyPagination'
import Money from '../../components/Money'
import Paste from '../../components/paste'
import EnabkeStatus from '../../components/EnabkeStatus'
import SwitchesThatCanDisabled from '../../components/switches_that_can_disabled'
import { formatDatePeriod, datesAdapterElUI } from '../../utils/format'
import apiV2 from '../../api/api-v2'
import PromotionChannelsForm from './components/promotion_channels_form'


/**
 * 渠道管理
 */
export default class Bills extends Component {

    constructor(props) {
        super(props)

        this.state = {
            body: {},
            queryFormData: {
                status: null,
                channel: '',
                beginTime: null,
                endTime: null,
                current: 1,
                size: 10
            },
            result: {},
            billColumns: [
                {
                    label: '#',
                    prop: 'id',
                    width: '80',
                    fixed: true
                },
                {
                    label: '创建时间',
                    prop: 'createTime',
                    width: '180',
                },


                {
                    label: '渠道名称',
                    prop: 'channel',
                    width: '120',
                },
                {
                    label: '渠道类型',
                    prop: 'type',
                    width: '120',
                },
                {
                    label: '推广地址',
                    prop: 'url',
                    width: '180',
                    render: ({ url }) => <Paste rich content={url}/>
                },
                {
                    label: '是否对库',
                    prop: 'isPool',
                    width: '100',
                    render: ({ isPool }) => <EnabkeStatus enable={isPool} />
                },
                {
                    label: '开启',
                    prop: 'status',
                    width: '80',
                    fixed: 'right',
                    align: 'center',
                    render: ({ status }) => <EnabkeStatus enable={status} />
                },

                {
                    label: '价格',
                    prop: 'price',
                    width: '120',
                    render: ({ price }) => <Money value={price} />
                },
                {
                    label: '授信额度',
                    prop: 'quota',
                    width: '120',
                    render: ({ quota }) => <Money value={quota} />
                },
                {
                    label: '修改时间',
                    prop: 'uptTime',
                    width: '180',
                },
                {
                    label: '备注',
                    prop: 'remark',
                },
                {
                    label: '操作',
                    prop: 'row',
                    width: '80',
                    fixed: 'right',
                    render: (formData) => {
                        return <Button onClick={() => this.setState({ formData, show: true })} type="text">{'编辑'}</Button>
                    }
                },
            ],
            formData: null,
            show: false
        }
    }

    componentDidMount() {
        this.serverFetchList()
    }


    //组件销毁前调用，清除一些事件(比如定时事件)
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async serverFetchList() {
        const { body } = await apiV2.getPromotionChannels({ data: this.state.queryFormData })
        this.setState({ result: body || {} })
    }

    changeQueryForm(name, value, queryLoad) {
        const queryFormData = Object.assign(this.state.queryFormData, { [name]: value })
        this.setState({ queryFormData }, () => { if (queryLoad) this.serverFetchList() })
    }

    changeQueryFormDate(dateArr, startName, endName) {
        const [startValue, endValue] = formatDatePeriod(dateArr)
        const queryFormData = Object.assign(this.state.queryFormData, { [startName]: startValue, [endName]: endValue })
        this.setState({ queryFormData })
    }

    onChannelsFormClose(){
        this.setState({ formData: null, show: false })
        this.serverFetchList()
    }

    render() {

        const { result, billColumns, queryFormData, formData, show } = this.state

        return (
            <>
                <Space>
                    <Form inline>
                        <Form.Item label="渠道名称">
                            <Input value={queryFormData.channel} onChange={e => this.changeQueryForm('channel', e)} />
                        </Form.Item>

                        <Form.Item label="激活状态">
                            <SwitchesThatCanDisabled value={queryFormData.status} onChangeValue={e => this.changeQueryForm('status', e)} />
                        </Form.Item>

                        <Form.Item label="产生日期">
                            <DateRangePicker value={datesAdapterElUI(queryFormData.beginTime, queryFormData.endTime)}
                                onChange={(e) => this.changeQueryFormDate(e, 'beginTime', 'endTime')}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={() => this.serverFetchList()} type="primary">{'搜索'}</Button>
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={() => this.setState({ formData: null, show: true })} type="primary">{'添加'}</Button>
                        </Form.Item>
                    </Form>
                </Space>

                <Table columns={billColumns} data={result.records} />

                <Pagination total={result.total} pageNumber={queryFormData.current} pageSize={queryFormData.size}
                    onCurrentChange={e => this.changeQueryForm('current', e, true)}
                    onSizeChange={e => this.changeQueryForm('size', e, true)} />

                <PromotionChannelsForm show={show} data={formData} onClose={() => this.onChannelsFormClose()} />
            </>)
    }
}
