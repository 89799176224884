/*
 * @Author: Your Name you@example.com
 * @Date: 2023-09-30 18:18:13
 * @LastEditors: Your Name you@example.com
 * @LastEditTime: 2023-09-30 19:47:24
 * @FilePath: \credit-loan-admin-repeat\src\components\risk_h5.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Tabs } from 'element-react'
import PanoramicRadar from '../containers/member/panoramic_radar'
import apiV2 from '../api/api-v2'
import ProbeC from '../containers/member/probe_C'


class RiskH5 extends Component {
    static propTypes = {
        userId: PropTypes.number,
        radarAnalysis: PropTypes.any,
        onClose: PropTypes.func
    }
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        const { radarAnalysis } = this.props
        return (
            <Dialog visible={this.props.userId}
                lockScroll={false}
                title="风控详细信息"
                size="large"
                onCancel={this.props.onClose}>
                <Dialog.Body>
                    <Tabs activeName="1" >
                        <Tabs.Pane label="米融雷达" name="1">
                            <PanoramicRadar
                                getReq={apiV2.getRiskRadarH5}
                                refreshReq={apiV2.getRiskRadarRefresh}
                                analysis={radarAnalysis}
                                userId={this.props.userId} />
                        </Tabs.Pane>
                        <Tabs.Pane label="米融探针" name="2">
                            <ProbeC refreshReq={apiV2.getRiskProbeCRefresh}
                                getReq={apiV2.getRiskProbeCH5}
                                userId={this.props.userId} />
                        </Tabs.Pane>
                    </Tabs>
                </Dialog.Body>
            </Dialog>)
    }
}
export default RiskH5
