import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'

import PropTypes from 'prop-types'

import { Descriptions } from 'antd'

/**
 * 运营商详情
 */
export default class AuthEmergency extends Component {

    static propTypes = {
        userId: PropTypes.number,
    }

    static authDataEmpty = {}



    constructor(props) {
        super(props)

        this.state = {
            authData: AuthEmergency.authDataEmpty
        }
    }

    componentDidMount() {
        this.getAuthEmergency()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.getAuthEmergency()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }


    async getAuthEmergency(userId = this.props.userId) {
        const { body } = await apiV2.getAuthEmergency({ userId })

        this.setState({
            authData: body || AuthEmergency.authDataEmpty
        })
    }

    conversationDescriptions(jsonString) {
        if (!jsonString) return null
        const data = JSON.parse(jsonString)
        return (
            <>
                <Descriptions.Item label="通话地点">{data.city}</Descriptions.Item>
                <Descriptions.Item label="通话号码">{data.peerNum}</Descriptions.Item>
                <Descriptions.Item label="近1周通话次数">{data.callCnt1w}</Descriptions.Item>
                <Descriptions.Item label="近1月通话次数">{data.callCnt1m}</Descriptions.Item>
                <Descriptions.Item label="近3月通话次数">{data.callCnt3m}</Descriptions.Item>
                <Descriptions.Item label="近6月通话次数">{data.callCnt6m}</Descriptions.Item>
                <Descriptions.Item label="第一次通话时间">{data.transStart}</Descriptions.Item>
                <Descriptions.Item label="最后一次通话时间">{data.transEnd}</Descriptions.Item>
            </>
        )
    }

    render() {
        const data = this.state.authData
        return (
            <>
                <Descriptions bordered>
                    <Descriptions.Item label="所在省份">{data.province}</Descriptions.Item>
                    <Descriptions.Item label="所在城市">{data.city}</Descriptions.Item>
                    <Descriptions.Item label="所在区域">{data.district}</Descriptions.Item>
                    <Descriptions.Item label="详细地址">{data.address}</Descriptions.Item>
                    <Descriptions.Item label="婚姻状况">{data.marriageType}</Descriptions.Item>
                    <Descriptions.Item label="学历">{data.educationType}</Descriptions.Item>
                    <Descriptions.Item label="首次认证时间">{data.createTime}</Descriptions.Item>
                    <Descriptions.Item label="最后一次修改时间">{data.uptTime}</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered title="紧急联系人（一）">
                    <Descriptions.Item label="姓名">{data.oneName}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{data.oneMobile}</Descriptions.Item>
                    <Descriptions.Item label="关系">{data.oneRelation}</Descriptions.Item>
                    {
                        this.conversationDescriptions(data.oneNum)
                    }
                </Descriptions>
                <Descriptions bordered title="紧急联系人（二）">
                    <Descriptions.Item label="姓名">{data.twoName}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{data.twoMobile}</Descriptions.Item>
                    <Descriptions.Item label="关系">{data.twoRelation}</Descriptions.Item>
                    {
                        this.conversationDescriptions(data.twoNum)
                    }
                </Descriptions>
            </>
        )
    }
}