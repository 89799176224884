import React, { Component } from 'react'
import { Button, Message, Upload } from 'element-react'
import PropTypes from 'prop-types'
import apiV2 from '../api/api-v2'
import Image from './Image'
import Condition from './Condition'

/**
 * 图片上传
 */
export default class ImageUploadV1 extends Component {

    static propTypes = {
        onChange: PropTypes.func,
        url: PropTypes.string
    }



    async beforeAvatarUpload(file) {
        const { success, body, message } = await apiV2.uploadFile({ file })

        if (success) {
            this.notify(body)
        } else {
            Message.error(message)
        }
    }

    notify(body) {
        const onChange = this.props.onChange
        if (onChange) onChange(body)
    }



    render() {
        const { url } = this.props
        return (
            <>
                <Upload
                    action="/upload"
                    listType="picture"
                    beforeUpload={file => this.beforeAvatarUpload(file)}
                >
                    <Condition visible={!url}>
                        <Button type="text" size="mini">{'上传'}</Button>
                    </Condition>
                </Upload>
                <Condition visible={url}>
                    <Image src={url} width="7rem" />
                    <Button onClick={() => this.notify(null)} type="text" size="mini">{'删除'}</Button>
                </Condition>
            </>
        )
    }

}
