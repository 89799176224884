import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'

import PropTypes from 'prop-types'
import OrderOverdue from '../loan/order_overdue'

/**
 * 复借逾期
 */
export default class OrderList extends Component {

    static propTypes = {
        readOnly: PropTypes.bool,
    }

    oldOverExcelExportExcel() {
        apiV2.oldOverExcelExportExcel({ isDownload: true })
    }

    render() {

        return (
            <OrderOverdue rquestListFunction={apiV2.todayForReimbursementOrderList} readOnly={this.props.readOnly}
            orderAllocation
                exportClick={() => this.oldOverExcelExportExcel()} queryParam={{ isNewUser: false }} />
        )
    }
}