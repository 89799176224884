import React, { Component } from 'react'

import apiV2 from '../../api/api-v2'
import Pagination from '../../components/MyPagination'
import PropTypes from 'prop-types'
import { Alert, Button, Form, Input, Message, Switch, Table, TimePicker } from 'element-react'
import Image from '../../components/Image'
import EnabkeStatus from '../../components/EnabkeStatus'
import Paste from '../../components/paste'
import Money from '../../components/Money'
import ImageUploadV1 from '../../components/ImageUploadV1'
import Popconfirm from '../../components/MyPopconfirm'
import SwitchesThatCanDisabled from '../../components/switches_that_can_disabled'

import { Drawer } from 'antd'
import moment from 'moment'

/**
 * 贷超
 */
export default class LoanSupermarket extends Component {

    static ListResultEmpty = { records: [], total: 0 }
    static SaveResultEmpty = {
        caption: '',
        logoUrl: null,
        maxQuota: 0,
        miniQuota: 0,
        name: '',
        openingHours: new Date(2000, 1, 1, 9, 0, 0),
        passNum: 0,
        passingRate: 0,
        sort: 0,
        status: true,
        url: null
    }

    static propTypes = {
        readOnly: PropTypes.bool,
    }



    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                current: 1,
                size: 10,
                name: '',
                status: null
            },

            result: LoanSupermarket.ListResultEmpty,
            columns: [
                {
                    label: '#',
                    prop: 'id',
                    width: '100',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '名称',
                    prop: 'name',
                    width: '100',
                    align: 'center',
                    fixed: true,
                    render: ({ name }) => <Paste content={name} />
                },

                {
                    label: '标题',
                    prop: 'caption',
                    width: '100',
                    align: 'center',
                },
                {
                    label: 'LOGO',
                    prop: 'logo',
                    width: '100',
                    align: 'center',
                    render: ({ logoUrl }) => <Image src={logoUrl} width="3rem" />
                },
                {
                    label: '最大额度',
                    prop: 'maxQuota',
                    width: '120',
                    align: 'center',
                    render: ({ maxQuota }) => <Money color="red" value={maxQuota} />
                },
                {
                    label: '最小额度',
                    prop: 'miniQuota',
                    width: '120',
                    align: 'center',
                    render: ({ miniQuota }) => <Money color="red" value={miniQuota} />
                },

                {
                    label: '开放时间',
                    prop: 'openingHours',
                    width: '120',
                    align: 'center',
                    render: ({ openingHours }) => <Money color="green" value={moment(openingHours).format('hh:mm:ss')} />
                },
                {
                    label: '通过率',
                    prop: 'passingRate',
                    width: '120',
                    align: 'center',
                    render: ({ passingRate }) => <Money color="green" value={(passingRate * 100).toFixed(2) + '%'} />
                },

                {
                    label: '通过人数',
                    prop: 'passNum',
                    width: '120',
                    align: 'center',
                },
                {
                    label: '落地页地址',
                    prop: 'url',
                    width: '200',
                    align: 'center',
                    render: ({ url }) => <Paste content={url} />
                },

                {
                    label: '排序',
                    prop: 'sort',
                    width: '80',
                    align: 'center',
                    render: ({ sort }) => <Money value={sort} />
                },
                {
                    label: '是否启用',
                    prop: 'status',
                    width: '150',
                    align: 'center',
                    render: ({ status }) => <EnabkeStatus enable={status} />
                },

                {
                    label: '添加时间',
                    prop: 'createTime',
                    width: '200',
                    align: 'center',
                },

                {
                    label: '修改时间',
                    prop: 'uptTime',
                    width: '200',
                    align: 'center',
                },
                {
                    label: '操作',
                    prop: 'row',
                    width: '120',
                    align: 'center',
                    fixed: 'right',
                    render: row => {
                        return (
                            <>
                                <Button onClick={() => this.openSave(row)} type="primary" size="mini">{'编辑'}</Button>
                                <Popconfirm onConfirm={() => this.delRow(row)}>
                                    <Button type="danger" size="mini">{'删除'}</Button>
                                </Popconfirm>
                            </>
                        )
                    }
                }
            ],
            saveForm: LoanSupermarket.SaveResultEmpty,
            saveFormVisible: false,
            saveResult: null
        }


    }

    componentDidMount() {
        this.getList()
    }

    componentWillUnmount() {
        return true
    }

    async getList() {
        const { body } = await apiV2.getLoanSupermarketData({ param: this.state.queryForm })
        this.setState({ result: body || LoanSupermarket.ListResultEmpty })
    }

    updateQueryForm(name, value, offListRefresh = false) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => { if (!offListRefresh) this.getList() })
    }

    updateSaveForm(name, value) {
        const saveForm = Object.assign(this.state.saveForm, { [name]: value })
        this.setState({ saveForm })
    }

    openSave(oldData) {
        const saveForm = oldData || LoanSupermarket.SaveResultEmpty

        if (saveForm.openingHours) {
            saveForm.openingHours = moment(saveForm.openingHours).toDate()
        }

        this.setState({ saveForm, saveFormVisible: true })
    }

    async delRow({ id }) {
        const { success, message } = await apiV2.delLoanSupermarketData({ id })
        if (success) { Message.success(message) } else { Message.error(message) }

        this.getList() // 刷新
    }

    async saveData() {
        const param = this.state.saveForm

        if (param.openingHours) {
            param.openingHours = moment(param.openingHours).format('YYYY-MM-DD HH:mm:ss')
        }

        const saveResult = await apiV2.saveLoanSupermarketData({ param })
        this.setState({ saveResult })
        this.getList() // 刷新
    }

    render() {
        const { queryForm, result, columns, saveFormVisible, saveForm, saveResult } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="名称">
                        <Input value={queryForm.name} onChange={e => this.updateQueryForm('name', e, true)} />
                    </Form.Item>
                    <Form.Item label="是否启用">
                        <SwitchesThatCanDisabled value={queryForm.status} onChangeValue={e => this.updateQueryForm('status', e, true)} />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => this.getList()} type="primary">{'查询'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.openSave()} type="primary">{'添加'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={columns} data={result.records} />

                <Pagination pageSize={queryForm.size} total={result.total} pageNumber={queryForm.current}
                    onCurrentChange={e => this.updateQueryForm('current', e)}
                    onSizeChange={e => this.updateQueryForm('size', e)} />


                <Drawer width={500} visible={saveFormVisible} onClose={() => this.setState({ saveFormVisible: false, saveResult: null })}>

                    <Form>
                        <Form.Item label="名称">
                            <Input value={saveForm.name} onChange={e => this.updateSaveForm('name', e)} />
                        </Form.Item>

                        <Form.Item label="标题">
                            <Input value={saveForm.caption} onChange={e => this.updateSaveForm('caption', e)} />
                        </Form.Item>

                        <Form.Item label="最小额度">
                            <Input value={saveForm.miniQuota} onChange={e => this.updateSaveForm('miniQuota', e)} />
                        </Form.Item>

                        <Form.Item label="最大额度">
                            <Input value={saveForm.maxQuota} onChange={e => this.updateSaveForm('maxQuota', e)} />
                        </Form.Item>

                        <Form.Item label="通过率">
                            <Input value={saveForm.passingRate} onChange={e => this.updateSaveForm('passingRate', e)} />
                        </Form.Item>

                        <Form.Item label="通过人数">
                            <Input value={saveForm.passNum} onChange={e => this.updateSaveForm('passNum', e)} />
                        </Form.Item>

                        <Form.Item label="开放时间">
                            <TimePicker value={saveForm.openingHours} onChange={e => this.updateSaveForm('openingHours', e)} />
                        </Form.Item>

                        <Form.Item label="LOGO">
                            <ImageUploadV1 url={saveForm.logoUrl} onChange={e => this.updateSaveForm('logoUrl', e)} />
                        </Form.Item>

                        <Form.Item label="排序">
                            <Input placeholder="数字越大，优先级越高" value={saveForm.sort} onChange={e => this.updateSaveForm('sort', e)} />
                        </Form.Item>

                        <Form.Item label="是否启用">
                            <Switch value={saveForm.status} onChange={e => this.updateSaveForm('status', e)} />
                        </Form.Item>

                        <Form.Item label="落地页地址">
                            <Input type="textarea" value={saveForm.url} onChange={e => this.updateSaveForm('url', e)} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => this.saveData()}>{'提交'}</Button>
                        </Form.Item>

                        {saveResult ? (
                            <Form.Item>
                                <Alert title={saveResult.message} type={saveResult.success ? 'success' : 'error'} />
                            </Form.Item>
                        ) : null}
                    </Form>
                </Drawer>

            </>
        )
    }
}