import React, { Component } from 'react'

import { Table, Button, Tag, Popover, Message, Form, Input } from 'element-react'

import apiV2 from '../../../api/api-v2'

import PropTypes from 'prop-types'
import MyPopconfirm from '@components/MyPopconfirm'
import Condition from '@components/Condition'
import OperatorInfo from '../operator_info'
import UserInfo from '../user_info'
import { AuthenticationConfig } from '../types'
import { Drawer } from 'antd'
import GetLoginSMSCode from './get_login_sms_code'

/**
 * 用户信息表格
 */
export default class UserTable extends Component {

    static propTypes = {
        list: PropTypes.object.isRequired,
        onRefresh: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            getSMSCodeUser: null,
            editQuotaForm: {},
            editVisible: false,
            operatorUser: 3859, // 测试
            userInfoId: null,
            columns: [{
                label: '真实姓名',
                prop: 'is userName realName realUserName',
                width: '120',
                align: 'center',
                fixed: true,
                render: ({ userName, realName, realUserName }) => realUserName || userName || realName || '无'
            }, {
                label: '#',
                prop: 'userId',
                align: 'center',
                width: '100',
            }, {
                label: '手机号',
                prop: 'mobile',
                width: '140',
                align: 'center',
                fixed: true
            }, {
                label: '信用额',
                prop: 'userQuota',
                align: 'center',
                width: '80'
            }, {
                label: '渠道',
                prop: 'channel',
                align: 'center',
            }, {
                label: '渠道来源',
                prop: 'channelSource',
                align: 'center',
                width: '120'
            }, {
                label: '借款数',
                prop: 'orderNum',
                align: 'center',
            }, {
                label: '是否新客',
                prop: 'orderNum',
                align: 'center',
                width: '120',
                render: ({ orderNum }) => {
                    let that = { color: 'warning', text: '新客' }

                    if (orderNum > 0) {
                        that = { color: 'danger', text: '老客' }
                    }

                    return (
                        <>
                            <Tag type={that.color}>{that.text}</Tag>
                        </>)
                }
            }, {
                label: '通讯录',
                prop: 'mobileBookStatus',
                align: 'center',
                width: '130',
                render: row => this.stateRender(row.mobileBookStatus, 0, row.userId)
            }, {
                label: '注册时间',
                width: '180',
                align: 'center',
                prop: 'createTime'
            }, {
                label: '身份证',
                prop: 'idardAuthStatus',
                align: 'center',
                width: '140',
                render: row => this.stateRender(row.idCardAuthStatus, 1, row.userId)
            }, {
                label: '信息认证',
                prop: 'eecAuthStatus',
                width: '140',
                render: row => this.stateRender(row.eecAuthStatus, 2, row.userId)
            }, {
                label: '手机认证',
                prop: 'mobileAuthStatus',
                align: 'center',
                width: '140',
                render: row => this.stateRender(row.mobileAuthStatus, 3, row.userId)
            }, {
                label: '银行卡',
                prop: 'bankAuthStatus',
                align: 'center',
                width: '130',
                render: row => this.stateRender(row.bankAuthStatus, 4, row.userId)
            }, {
                label: '下次申请间隔时间',
                width: '180',
                align: 'center',
                prop: 'nextTime'
            }, {
                label: '操作',
                prop: 'row',
                width: '200',
                align: 'center',
                fixed: 'right',
                render: row => {
                    return (
                        <div>
                            <MyPopconfirm onConfirm={this.updateMemberState.bind(this, row.userId, !row.status)}>
                                <Button type="danger" size="mini">{row.status ? '禁用' : '启用'} </Button>
                            </MyPopconfirm>
                            <Button type="text" onClick={() => this.openUpdateUserQuota(row)}>{'修改'}</Button>
                            <Button type="text" onClick={() => this.openGetSMSCode(row)}>{'获取验证码'}</Button>
                            {/* {mobileAuthStatusInfo ? <Button onClick={this.openUserInfo.bind(this, row)} type="text">{'用户详情'}</Button> : <></>} */}
                            {1 ? <Button onClick={this.openOperator.bind(this, row)} type="text">{'用户详情'}</Button> : <></>}
                        </div>)
                }
            }]
        }
    }

    getMemberList() {
        const onRefresh = this.props.onRefresh
        if (onRefresh) onRefresh()
    }

    openGetSMSCode({ userId }) {
        this.setState({
            getSMSCodeUser: userId
        })
    }

    openOperator({ userId }) {
        this.setState({
            operatorUser: userId
        })
    }

    operatorInfoClose() {
        this.setState({
            operatorUser: null
        })
    }

    userInfoClose() {
        this.setState({
            userInfoId: null
        })
    }

    openUserInfo({ userId }) {
        this.setState({
            userInfoId: userId
        })
    }

    async authToSuccess(userId, type) {
        switch (type) {
            case 3: {
                return await this.userMobileAuthToSuccess(userId)
            }
            case 1: {
                return await this.userCardAuthToSuccess(userId)
            }
            default: {
                console.log(userId, type)
            }
        }
    }




    async userCardAuthToSuccess(userId) {
        const { success, message } = apiV2.userCardAuthToSuccess({
            userId
        })

        if (success) {
            Message.success(message || '成功')
        } else {
            Message.error(message || ' 失败')
        }

        await this.getMemberList()
    }

    async userMobileAuthToSuccess(userId) {
        const { success, message } = await apiV2.userMobileAuthToSuccess({
            userId
        })

        if (success) {
            Message.success(message || '成功')
        } else {
            Message.error(message || ' 失败')
        }

        await this.getMemberList()
    }
    async serverRecertification(authType, userId) {
        const { success, message } = await apiV2.userRecertification({
            authType, userId
        })

        if (success) {
            Message.success(message || '成功')
        } else {
            Message.error(message || ' 失败')
        }

        await this.getMemberList()
    }


    async updateMemberState(userId, status) {
        const { success, message } = await apiV2.updateMemberState({
            bodyState: { userId, status }
        })

        if (!success) {
            Message.error(message)
        } else {
            this.getMemberList()
        }
    }

    openUpdateUserQuota({ userId, userQuota }) {
        this.setState({
            editVisible: true,
            editQuotaForm: { userId, quota: userQuota }
        })
    }

    async submitUpdateUserQuota(userId, quota) {
        const { message } = await apiV2.updateUserQuota({ userId, quota })
        Message.success(message)
        this.getMemberList()
        this.setState({
            editVisible: false
        })
    }


    changeEditQuotaForm(name, value) {
        const editQuotaForm = Object.assign(this.state.editQuotaForm, { [name]: value })
        this.setState({ editQuotaForm })
    }



    stateRender(enable, type, uid) {
        const allTypeUpdate = [3]

        const that = AuthenticationConfig[enable] || {}
        const authSuccessCan = [1, 3].includes(type)
        const authSuccess = enable === 3
        const authSuccessDisable = enable === 3

        return (
            <>
                <Tag type={that.color}>{that.text}</Tag>

                <Popover title="修改认证状态" content={(
                    <>
                        <Condition visible={authSuccess}>
                            <Button onClick={this.serverRecertification.bind(this, type, uid)} type="text">{'重新认证'}</Button>
                        </Condition>
                        <Condition visible={authSuccessCan}>
                            <Button disabled={authSuccessDisable} onClick={this.authToSuccess.bind(this, uid, type)} type="text">{'认证成功'}</Button>
                        </Condition>
                    </>)}>

                    <Condition visible={allTypeUpdate.includes(type) || enable !== 1}> <Button size="mini" type="text">{'修改认证状态'}</Button></Condition>
                </Popover>
            </>
        )
    }

    render() {
        const { list } = this.props
        const { editVisible, editQuotaForm } = this.state

        return (
            <>
                <Table columns={this.state.columns} data={list.records} />
                <OperatorInfo userId={this.state.operatorUser} onClose={this.operatorInfoClose.bind(this)} />
                <UserInfo userId={this.state.userInfoId} onClose={this.userInfoClose.bind(this)} />

                <GetLoginSMSCode userId={this.state.getSMSCodeUser} onClose={() => this.setState({ getSMSCodeUser: null })} />

                <Drawer visible={editVisible} onClose={() => this.setState({ editVisible: false })}>
                    <Form>
                        <Form.Item label="授信额度">
                            <Input placeholder="3000" value={editQuotaForm.quota} onChange={(e) => this.changeEditQuotaForm('quota', e)} />
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={() => this.submitUpdateUserQuota(editQuotaForm.userId, editQuotaForm.quota)} type="primary">{'提交修改'}</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </>)
    }
}
