import React, { Component } from 'react'

import OrderOverdueReadOnly from './overdue_re_borrowing'

/**
 * 复借逾期-只读
 */
export default class OrderList extends Component {

    render() {

        return (
            <OrderOverdueReadOnly readOnly />
        )
    }
}