import React, { Component } from 'react'

import { Descriptions, Drawer } from 'antd'
import PropTypes from 'prop-types'

import { OrderType, OrderStatus, OrderBillMoneyType } from './types'
import { Alert, Table, Tag, Card } from 'element-react'
import apiV2 from '../../api/api-v2'
import { PaymentType } from '../statistics/tyeps'

/**
 * 订单分期
 */
export default class OrderStages extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func
    }


    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            result: null,
            errorMsg: '正在加载',
            remarksColumns: [
                {
                    label: '内容',
                    prop: 'context',
                    align: 'center',
                },
                {
                    label: '添加时间',
                    prop: 'createTime',
                    align: 'center',
                    width:200
                }
            ],
            adminOrderDetailColumns: [
                {
                    label: '借款周期',
                    prop: 'deadlineDay',
                    width: '120',
                    align: 'center',
                    fixed: true,
                    render: ({ deadlineDay }) => <Tag type="primary">{deadlineDay + '天'}</Tag>
                },

                {
                    label: '订单状态',
                    prop: 'status',
                    align: 'center',
                    render: ({ status }) => {
                        const that = OrderStatus[status] || { color: 'warning', name: '未知' }
                        return <Tag type={that.theme}>{that.name}</Tag>
                    }
                },
                {
                    label: '借款类型',
                    prop: 'type',
                    width: '120',
                    align: 'center',
                    render: ({ type }) => {
                        const that = OrderType[type] || { color: 'warning', name: '未知' }
                        return <Tag type={that.theme}>{that.name}</Tag>
                    }
                },
                // {
                //     label: '交易时间',
                //     prop: 'createTime',
                //     width: '200',
                //     align: 'center',
                // },
                {
                    label: '开始时间',
                    prop: 'beginTime',
                    align: 'center',
                    width: '200',
                },
                {
                    label: '结束时间',
                    prop: 'endTime',
                    width: '200',
                    align: 'center',
                },
                {
                    label: '应还款金额',
                    prop: 'shouldRepayAmount',
                    width: '180',
                    align: 'center',
                    fixed: 'right'
                }
            ],
            billColumns: [
                {
                    label: '交易金额',
                    prop: 'money',
                    width: '80',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '金额名称',
                    prop: 'typeName',
                    align: 'center',
                },
                {
                    label: '金额类型',
                    prop: 'type',
                    align: 'center',
                    render: ({ type }) => {
                        const that = OrderBillMoneyType[type] || { color: 'warning', name: '未知' }
                        return <Tag type={that.color}>{that.name}</Tag>
                    }
                },
                {
                    label: '支付类型',
                    prop: 'payType',
                    align: 'center',
                    render: ({ payType }) => {
                        const that = PaymentType[payType] || { color: 'warning', name: '未知' }
                        return <Tag type={that.color}>{that.name}</Tag>
                    }
                },
                {
                    label: '三方单号',
                    prop: 'thirdNum'
                },
                {
                    label: '备注',
                    prop: 'remark',
                    width: '100',
                    align: 'center',
                },
                {
                    label: '交易时间',
                    prop: 'createTime',
                    width: '180',
                    align: 'center',
                }
            ],
            logColumns: [
                {
                    label: '操作信息',
                    prop: 'context',
                    align: 'center',
                },
                {
                    label: '操作时间',
                    prop: 'createTime',
                    width: '180',
                    align: 'center',
                }
            ]
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
        }
    }

    async getOrderInfo(orderId = this.props.orderId) {
        const { body, message } = await apiV2.getOrderInfo({ orderId })
        this.setState({ result: body, errorMsg: message })
    }

    open() {
        this.setState({ visible: true })
        this.getOrderInfo()
    }


    close() {
        this.setState({ visible: false })
        this.props.onClose()
    }

    render() {
        const { visible, billColumns, logColumns, result, errorMsg, adminOrderDetailColumns, remarksColumns } = this.state
        let userInfoVo, orderInfoVo, logs, billVOs, adminOrderDetailVOS, remarkVOS
        if (result) {
            userInfoVo = result.userInfoVo
            orderInfoVo = result.orderInfoVo
            logs = result.logs
            billVOs = result.billVOs
            adminOrderDetailVOS = result.adminOrderDetailVOS
            remarkVOS = result.remarkVOS
        }

        return (
            <Drawer title="订单详情" placement="bottom" onClose={() => this.close()} visible={visible} height="85%">
                {result ?
                    <>
                        <Descriptions bordered title="用户信息">
                            <Descriptions.Item label="手机号">{userInfoVo.mobile}</Descriptions.Item>
                            <Descriptions.Item label="姓名">{userInfoVo.realName}</Descriptions.Item>
                        </Descriptions>

                        <Descriptions bordered title="订单信息">
                            <Descriptions.Item label="实际还款金额">{orderInfoVo.actualRepaymentAmount}</Descriptions.Item>
                            <Descriptions.Item label="到账金额">{orderInfoVo.arrivalAmount}</Descriptions.Item>
                            <Descriptions.Item label="审核时间">{orderInfoVo.auditTime}</Descriptions.Item>
                            <Descriptions.Item label="渠道名称">{orderInfoVo.channel}</Descriptions.Item>
                            <Descriptions.Item label="下单时间">{orderInfoVo.createTime}</Descriptions.Item>
                            <Descriptions.Item label="申请借款周期">{orderInfoVo.deadlineDay}</Descriptions.Item>
                            <Descriptions.Item label="延期金额">{orderInfoVo.deferredAmount}</Descriptions.Item>
                            <Descriptions.Item label="延期天数">{orderInfoVo.deferredDay}</Descriptions.Item>
                            <Descriptions.Item label="本金减免金额">{orderInfoVo.derateAmount}</Descriptions.Item>

                            <Descriptions.Item label="约定还款时间">{orderInfoVo.endTime}</Descriptions.Item>
                            <Descriptions.Item label="失败原因描述">{orderInfoVo.errorMsg}</Descriptions.Item>
                            <Descriptions.Item label="米融探针雷达分">{orderInfoVo.kaiqiScore}</Descriptions.Item>
                            <Descriptions.Item label="借款次数">{orderInfoVo.num}</Descriptions.Item>
                            <Descriptions.Item label="订单号">{orderInfoVo.orderCode}</Descriptions.Item>
                            <Descriptions.Item label="订单ID">{orderInfoVo.orderId}</Descriptions.Item>

                            <Descriptions.Item label="逾期金额">{orderInfoVo.overdueAmount}</Descriptions.Item>
                            <Descriptions.Item label="逾期天数">{orderInfoVo.overdueDay}</Descriptions.Item>
                            <Descriptions.Item label="逾期减免金额">{orderInfoVo.overdueDerateAmount}</Descriptions.Item>
                            <Descriptions.Item label="借款额度">{orderInfoVo.quota}</Descriptions.Item>
                            <Descriptions.Item label="服务费用">{orderInfoVo.rateMoney}</Descriptions.Item>
                            <Descriptions.Item label="放款时间0">{orderInfoVo.remitTime}</Descriptions.Item>
                            <Descriptions.Item label="实际还款时间">{orderInfoVo.repaymentTime}</Descriptions.Item>
                            <Descriptions.Item label="应还金额">{orderInfoVo.shouldRepayAmount}</Descriptions.Item>
                            <Descriptions.Item label="订单类型">
                                <Tag type={OrderType[orderInfoVo.type].theme}>{OrderType[orderInfoVo.type].name}</Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="订单状态">
                                <Tag type={OrderStatus[orderInfoVo.status].theme}>{OrderStatus[orderInfoVo.status].name}</Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="米融分">{orderInfoVo.zhimiScore}</Descriptions.Item>
                        </Descriptions>


                        <Card>
                            <Tag type="success">{'详细信息列表'}</Tag>
                            <Table border columns={adminOrderDetailColumns} data={adminOrderDetailVOS} />
                        </Card>

                        <Card>
                            <Tag type="success">{'备注列表'}</Tag>
                            <Table border columns={remarksColumns} data={remarkVOS} />
                        </Card>

                        <Card>
                            <Tag type="success">{'账单流水'}</Tag>
                            <Table border columns={billColumns} data={billVOs} />
                        </Card>

                        <Card>
                            <Tag type="success">{'操作日志'}</Tag>
                            <Table border columns={logColumns} data={logs} />
                        </Card>

                    </> : <Alert type="warning" title={errorMsg} />}
            </Drawer>
        )

    }
}
