import React, { Component } from 'react'

import PropTypes from 'prop-types'
import apiV2 from '../../api/api-v2'
import { Descriptions } from 'antd'

/**
 * 银行卡管理
 */
export default class UserBankCard extends Component {

    static authDataEmpty = {}

    static propTypes = {
        userId: PropTypes.number
    }

    constructor(props) {
        super(props)

        this.state = {
            authData: UserBankCard.authDataEmpty
        }
    }

    componentDidMount() {
        this.getAuthBank()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.getAuthBank()
        }
    }
    
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async getAuthBank(userId = this.props.userId) {
        const { body } = await apiV2.getAuthBank({ userId })
        this.setState({ authData: body || UserBankCard.authDataEmpty })
    }


    render() {
        const data = this.state.authData
        return (
            <Descriptions bordered>
                <Descriptions.Item label="银行名称">{data.bankName}</Descriptions.Item>
                <Descriptions.Item label="银行卡号">{data.number}</Descriptions.Item>
                <Descriptions.Item label="首次认证时间">{data.createTime}</Descriptions.Item>
                <Descriptions.Item label="最后一次认证时间">{data.uptTime}</Descriptions.Item>
            </Descriptions>
        )
    }

}