import React, { Component } from 'react'

import OrderFinishReadOnly from './today_repaid'

/**
 * 今日已还
 */
export default class OrderList extends Component {



    render() {
        return (
            <OrderFinishReadOnly readOnly />
        )
    }
}