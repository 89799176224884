import moment from 'moment'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'

// 待还款
import OrderRepayment from './order_repayment'
import PropTypes from 'prop-types'

/**
 * 今日到期
 */
export default class OrderList extends Component {
    static TimeFormat = 'YYYY-MM-DD'
    static StartAnd = false

    static propTypes = {
        readOnly: PropTypes.bool
    }


    todayExpireExportExcel() {
        apiV2.todayExpireExportExcel({ isDownload: true })
    }

    render() {
        const now = moment()
        let startDateTime = now.format(OrderList.TimeFormat)
        const endDateTime = now.add(0, 'd').format(OrderList.TimeFormat)

        if (!OrderList.StartAnd) startDateTime = null
        console.log(startDateTime)
        return <OrderRepayment exportClick={() => this.todayExpireExportExcel()} 
            rquestListFunction={apiV2.todayForReimbursementOrderList} orderAllocation
            queryParam={{ repaymentBeginTime: endDateTime, repaymentEndTime: endDateTime }}
            readOnly={this.props.readOnly} />
    }
}
