/*
 * @Author: FATE boss@fellw.com
 * @Date: 2023-11-20 11:52:33
 * @LastEditors: FATE boss@fellw.com
 * @LastEditTime: 2023-12-16 13:55:41
 * @FilePath: \credit-loan-admin-repeat\src\containers\loan\types.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { objectToArray } from '../../utils/format'
import { Tag, Button } from 'element-react'
import { Popover } from 'antd'
import React from 'react'
import { MoneyStatisticsStyle } from '../../utils/format'
import DropDownClick from '../../components/drop-down_click'
import OrderRemarksForm from './order_remarks_form'


// 订单状态 1-审核中 2-失败 3-待打款 4-待还款 5-已完成
export const OrderStatus = {
    1: { name: '审核中', theme: 'gray', gzV2Enum: 'AUDITING' },
    2: { name: '审核拒绝', theme: 'danger', gzV2Enum: 'REJECTED' },
    3: { name: '待还款', theme: 'primary', gzV2Enum: 'REQUIREDREPAYMENT' },
    6: { name: '逾期还款', theme: 'default', gzV2Enum: 'SLIPPAGEREPAYMENTFINISH' },
    5: { name: '已完成', theme: 'success', gzV2Enum: 'REPAYMENTFINISH' },
    4: { name: '审核通过', theme: 'warning', gzV2Enum: 'LOANSUCCEEDED' }
}

// 类型 1-正常  2-展期  3-分期
export const OrderType = {
    1: { name: '正常', theme: 'success' }, 2: { name: '展期', theme: 'primary' },
    3: { name: '分期', theme: 'warning' }
}

export const OrderStatusList = objectToArray(OrderStatus)
export const OrderTypeList = objectToArray(OrderType)

// 去除展期
export const OrderTypeListNoExtension = objectToArray(OrderType).filter(({ key }) => key !== '2')

// 详情列表
export const orderDetailsMenus = (_userId) => [
    // { label: '用户详情', value: { _userId, p: 2 } },
    // { label: '用户详情', value: { _userId, p: 1 } },
]

// 订单详情点击
export const orderDetailsListClick = (page, { p, userId: u }) => {
    if (p === 1) page.setState({ phoneInfoId: u })
    if (p === 2) page.setState({ userInfoId: u })
}


// 延期UI显示
export function delayRepaymentUI(label = '延期', deferredAmount, deferredDay, derateAmount = 0) {
    if (!deferredDay) return (<Tag type="success">{`无${label}`}</Tag>)

    const textStyle = { 'color': 'blue', fontSize: '0.7rem' }
    const unitStyle = { 'color': 'orange', fontSize: '0.7rem', fontWeight: 500 }

    return (
        <Popover title={`另减免金额${derateAmount || 0}元`} trigger="hover">
            <span style={textStyle}>{label}</span><span style={MoneyStatisticsStyle}>{deferredDay}</span><span style={unitStyle}>{'天'}</span>
            <span style={textStyle}>{'产生费用'}</span><span style={MoneyStatisticsStyle}>{deferredAmount}</span><span style={unitStyle}>{'元'}</span>
        </Popover>)
}

// 账单类型
export const OrderBillMoneyType = {
    1: { color: 'success', name: '借款金额' },
    2: { color: 'gray', name: '打款金额' },
    3: { color: 'primary', name: '服务费' },
    4: { color: 'success', name: '展期费用' },
    5: { color: 'warning', name: '逾期金额' },
    6: { color: 'danger', name: '减免金额' },
    7: { color: 'warning', name: '逾期减免金额' },
    8: { color: 'primary', name: '还款金额' }
}


export function orderInfoButtonGroup(reactObj, orderId, userId) {
    return (
        <>
            <DropDownClick onClick={() => reactObj.setState({ orderInfoId: orderId })}
                name="订单详情" list={orderDetailsMenus(userId)} onListClick={(e) => orderDetailsListClick(this, e)} />
            <Button type="warning" onClick={() => reactObj.setState({ phoneInfoId: userId })} size="mini">{'用户详情'}</Button>
            <OrderRemarksForm orderId={orderId} />
        </>
    )
}