import React, { Component } from 'react'

import { Drawer, Alert } from 'antd'
import { Form, Input, Switch, Tabs } from 'element-react'
import { Button } from 'element-react/next'
import PropTypes from 'prop-types'
import Condition from '../../components/Condition'
import apiV2 from '../../api/api-v2'
import {
    AOC_PAY_CLOSE, HFB_PAY_CLOSE, JST_PAY_ENABLE, ONE_PAY_ENABLE, PAY_METHOD,
    QL_PAY_ENABLE, DUOLA_PAY_ENABLE,
    AKing_PAY_ENABLE,
    UDF_PAY_ENABLE,
    BAIJING_PAY_ENABLE
} from '../../utils/app'


/**
 * 订单打款
 */
export default class OrderReduction extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func
    }

    static RemissionAmountEmpty = {
        orderId: null,
        remitStatus: false,
        remark: ''
    }
    static ResultEmpty = { message: '' }

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            addFormData: OrderReduction.RemissionAmountEmpty,
            result: OrderReduction.ResultEmpty,
            resultFt: OrderReduction.ResultEmpty,
            loading: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
        }
    }

    open() {
        this.setState({ visible: true })
    }

    changeAddForm(name, value) {
        const addFormData = Object.assign(this.state.addFormData, { [name]: value })
        this.setState({ addFormData })
    }

    async onSubmit() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            data.orderId = this.props.orderId
            const result = await apiV2.remitOrder({ data })
            this.setState({ result })
        } finally {
            this.setState({ loading: false })
        }
    }

    close() {
        this.setState({ visible: false, result: OrderReduction.ResultEmpty, resultFt: OrderReduction.ResultEmpty })
        this.changeAddForm('remark', '')
        this.changeAddForm('remitStatus', false)
        this.props.onClose()
    }

    async onSubmitFt() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.ftPayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }

    async onSubmitQL() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.qlPayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }

    async onSubmitJST() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.jstPayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }

    async onSubmitHfb() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.hfbPayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }

    async onSubmitOne() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.onePayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }

    async onSubmitDuola() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.duolaPayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }

    async onSubmitAKing() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.aKingPayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }

    async onSubmitBaiJing() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.baiJingPayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }

    async onSubmitU() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            const resultFt = await apiV2.uPayment({ remark: data.remark, orderId: this.props.orderId })
            this.setState({ resultFt })
        } finally {
            this.setState({ loading: false })
        }
    }


    render() {
        const { addFormData, result, visible, resultFt, loading } = this.state

        return (
            <Drawer title="订单打款" placement="right" onClose={() => this.close()} visible={visible} width="40%">
                <Tabs type="card" value={PAY_METHOD}>
                    <Tabs.Pane label="修改打款状态" name="1">
                        <Form>
                            <Form.Item label="是否打款成功">
                                <Switch onText="成功" offText="失败"
                                    value={addFormData.remitStatus} onChange={(e) => this.changeAddForm('remitStatus', e)} />
                            </Form.Item>

                            <Form.Item label="备注">
                                <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                    type="textarea" />
                            </Form.Item>

                            <Form.Item>
                                <Button onClick={() => this.onSubmit()} type="primary">{'确定'}</Button>
                            </Form.Item>

                            <Condition visible={result.message}>
                                <Form.Item>
                                    <Alert message={result.message} type={result.success ? 'success' : 'error'} />
                                </Form.Item>
                            </Condition>
                        </Form>
                    </Tabs.Pane>

                    {!AOC_PAY_CLOSE ?
                        <Tabs.Pane label="奥创代付" name="3" disabled={AOC_PAY_CLOSE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitFt()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}


                    {!HFB_PAY_CLOSE ?
                        <Tabs.Pane label="汇付宝代付" name="2" disabled={HFB_PAY_CLOSE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitHfb()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}


                    {QL_PAY_ENABLE ?
                        <Tabs.Pane label="QL代付" name="4" disabled={!QL_PAY_ENABLE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitQL()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}

                    {JST_PAY_ENABLE ?

                        <Tabs.Pane label="JST代付" name="5" disabled={!JST_PAY_ENABLE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitJST()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}

                    {ONE_PAY_ENABLE ?
                        <Tabs.Pane label="One Pay" name="6" disabled={!ONE_PAY_ENABLE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitOne()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}


                    {DUOLA_PAY_ENABLE ?
                        <Tabs.Pane label="DUOLA" name="7" disabled={!DUOLA_PAY_ENABLE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitDuola()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}

                    {AKing_PAY_ENABLE ?
                        <Tabs.Pane label="王者支付" name="8" disabled={!AKing_PAY_ENABLE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitAKing()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}

                    {BAIJING_PAY_ENABLE ?
                        <Tabs.Pane label="BAIJING" name="9" disabled={!AKing_PAY_ENABLE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitBaiJing()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}

                    {UDF_PAY_ENABLE ?
                        <Tabs.Pane label="U代付" name="10" disabled={!AKing_PAY_ENABLE}>
                            <Form>
                                <Form.Item label="备注">
                                    <Input value={addFormData.remark} onChange={(e) => this.changeAddForm('remark', e)}
                                        type="textarea" />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={loading} disabled={loading} onClick={() => this.onSubmitU()} type="primary">{'确定'}</Button>
                                </Form.Item>

                                <Condition visible={resultFt.message}>
                                    <Form.Item>
                                        <Alert message={resultFt.message} type={resultFt.success ? 'success' : 'error'} />
                                    </Form.Item>
                                </Condition>
                            </Form>
                        </Tabs.Pane>
                        : null}
                </Tabs>
            </Drawer>)
    }
}
