import React, { Component } from 'react'
import { Button, Loading, Table, Dialog, Form, Input, Tree, Notification, Message, Popover } from 'element-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sizeChange, currentChange, initSearch } from '@redux/actions'
import { pageRole, deleteRole, addRole, selectRolemenus, updateRolemenus } from './action'
import MyPagination from '@components/MyPagination'
import apiV2 from '../../../api/api-v2'
class BlackUser extends Component {
	static propTypes = {
		list: PropTypes.object.isRequired,
		treeData: PropTypes.object.isRequired,
		sizeChange: PropTypes.func.isRequired,
		currentChange: PropTypes.func.isRequired,
		initSearch: PropTypes.func.isRequired,
		pageRole: PropTypes.func.isRequired,
		deleteRole: PropTypes.func.isRequired,
		addRole: PropTypes.func.isRequired,
		selectRolemenus: PropTypes.func.isRequired,
		updateRolemenus: PropTypes.func.isRequired,
		btnLoading: PropTypes.bool.isRequired,
		roleData: PropTypes.object,
		fetchPageSize: PropTypes.number,
		fetchPageNumber: PropTypes.number,
		loading: PropTypes.bool,
		perms: PropTypes.array,
		authorityOptions: PropTypes.object,
		authorityKeys: PropTypes.array
	}
	constructor(props) {
		super(props)
		this.state = {
			authorityOptions: {
				children: 'childNodes',
				label: 'permName'
			},
			authorityKeys: [],

			loading: false,
			fetchPageSize: 10,
			fetchPageNumber: 1,
			roleData: { records: [] },
			perms: [],
			treeKey: '',
			roleId: null,
			form: {
				roleName: null
			},
			rules: {
				roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
			},
			dialogVisible: false,
			treeDialogVisible: false,
			columns: [{
				label: '#',
				width: 60,
				render: (a, b, c) => {
					return c + 1
				}
			}, {
				label: '角色名称',
				prop: 'roleName'
			}, {
				label: '操作',
				render: row => {
					return (
						<div>
							<Button type="primary" size="mini" onClick={this.openRules.bind(this, row.id)}>{'权限设置'}</Button>
							{
								(row.id !== 1 && row.roleName !== '催收员') &&
								<Popover placement="right" width="160" trigger="click" visible={this.state.visible} content={(
									<div>
										<p>{'确定删除吗？'}</p>
										<div style={{ textAlign: 'right', margin: 0 }}>
											<Button size="mini" onClick={this.deleteRoleFetch.bind(this, { roleId: row.id, state: 1 })}>{'确定删除'}</Button>
										</div>
									</div>
								)}>
									<Button style={{ marginLeft: '0.5rem' }} type="danger" size="mini" >{'删除'}</Button>
								</Popover>
							}
						</div>
					)
				}
			}]
		}
	}
	componentWillMount() {
		this.props.initSearch()
		this.fetchAllRoleList()
		this.fetchRolePermissionAssignmentSysPerms()
	}
	componentDidMount() {
		this.props.pageRole()
	}
	sizeChange = e => {
		this.props.sizeChange(e)
		this.props.pageRole()

		this.setState({
			fetchPageSize: e
		}, this.fetchAllRoleList)

	}
	onCurrentChange = e => {
		this.props.currentChange(e)
		this.props.pageRole()

		this.setState({
			fetchPageNumber: e
		}, this.fetchAllRoleList)

	}
	onChange(key, value) {
		this.setState({
			form: Object.assign({}, this.state.form, { [key]: value })
		})
	}
	openDialog = e => {
		e.preventDefault()
		this.form.resetFields()
		this.setState({
			dialogVisible: true,
			roleName: null
		})
	}

	async deleteRoleFetch({ roleId }) {
		apiV2.deleteSystemRole({
			roleId,
		}).then(({ message, success }) => {
			if (success) {
				this.fetchAllRoleList()
				Message.success({ message })
			} else {
				Message.error({ message })
			}
		}).catch(err => {
			console.error(err)
		})
	}

	async getAllocationAuthorityKeys(roleId) {
		const authorityKeys = await apiV2.getAllocationAuthorityKeys({ roleId })
		this.setState({
			authorityKeys
		})
	}

	openRules = roleId => {
		// const adminObj = JSON.parse(window.sessionStorage.getItem('adminInfo'))
		const adminObj = { adminName: 'Test', roleId }
		this.props.selectRolemenus({ adminName: adminObj.adminName, roleId: roleId }, this.tree)
		this.setState({
			roleId: roleId,
			treeDialogVisible: true
		})

		this.getAllocationAuthorityKeys(roleId)
	}
	renderTree = () => {
		this.setState({
			treeKey: +new Date()
		})
	}

	async fetchRolePermissionAssignmentSysPerms() {
		const perms = await apiV2.getRolePermissionAssignmentSysPerms()
		this.setState({
			perms
		})
	}

	async fetchAllRoleList() {
		this.setState({
			loading: true
		})
		try {
			const { fetchPageSize, fetchPageNumber } = this.state
			const { body, success } = await apiV2.getAllRoleList({ pageSize: fetchPageSize, pageNum: fetchPageNumber })

			if (success) {
				this.setState({
					roleData: body
				})
			}
		} finally {
			this.setState({
				loading: false
			})
		}
	}

	saveContent = e => {
		e.preventDefault()
		this.form.validate((valid) => {
			if (valid) {

				apiV2.addSystemRole({
					roleName: this.state.form.roleName,
				}).then(({ message, success }) => {
					if (success) {
						this.fetchAllRoleList()
						Message.success({ message })
					} else {
						Message.error({ message })
					}
				}).catch(err => {
					console.error(err)
				})

				this.props.addRole({
					roleName: this.state.form.roleName,
				})
				this.setState({
					dialogVisible: false
				})
			} else {
				console.log('error submit!!')
				return false
			}
		})
	}
	aaa = id => {
		// const r = JSON.parse(window.sessionStorage.getItem('defaultRouter'))
		// console.log(r)
		const tree = this.props.treeData.data
		for (const i in tree) {
			const child = tree[i].children
			for (const j in child) {
				if (child[j].id === id) {
					return tree[i].id
				}
			}
		}
	}


	getCheckedKeys = e => {
		e.preventDefault()
		const brr = this.tree.getCheckedKeys()

		// 查找上级去权限
		brr.forEach(id => {
			this.state.perms.forEach(p => {
				const children = p.childNodes
				if (children) {
					children.forEach(x => {
						if (x.id === id) {
							brr.push(p.id)
						}
					})
				}
			})
		})

		const set = new Set(brr)

		apiV2.allocationAuthority({ roleId: this.state.roleId, authorityKeys: [...set] })
			.catch(err => console.error(err))

		const xx = []
		for (const i in brr) {
			const idd = this.aaa(brr[i])
			xx.push(idd)
		}
		const drr = [...new Set(xx)]
		const grr = brr.concat(drr)
		const frr = []
		for (const r of grr) {
			if (r) {
				frr.push(r)
			}
		}
		// 拼装后台传参格式
		if (frr.length > 0) {
			const arr = []
			for (let i = 0; i < frr.length; i++) {
				arr.push({
					menuId: frr[i],
					roleId: this.state.roleId
				})
			}
			this.props.updateRolemenus({ rolemenus: arr })
			this.setState({
				treeDialogVisible: false
			})
		} else {
			Notification.warning('请勾选权限')
		}
	}
	render() {
		const { btnLoading, treeData } = this.props
		const { roleData: { records: list, total }, loading, fetchPageSize, fetchPageNumber } = this.state
		const { dialogVisible, treeDialogVisible, rules, form,
			perms, authorityOptions, authorityKeys } = this.state
		return (
			<div>
				<Button className="margin-bottom15" type="primary" onClick={e => this.openDialog(e)}>{'添加'}</Button>
				<Loading loading={loading}>
					<Table
						style={{ width: '100%' }}
						columns={this.state.columns}
						data={list}
						border
						stripe
					/>
				</Loading>
				<MyPagination
					total={total}
					pageNumber={fetchPageNumber}
					pageSize={fetchPageSize}
					onSizeChange={this.sizeChange}
					onCurrentChange={this.onCurrentChange}
				/>
				<Dialog
					title="添加角色"
					visible={dialogVisible}
					onCancel={() => this.setState({ dialogVisible: false })}
				>
					<Dialog.Body>
						<Form labelWidth="120" ref={e => { this.form = e }} model={form} rules={rules}>
							<Form.Item label="角色" prop="roleName">
								<Input value={form.roleName} onChange={this.onChange.bind(this, 'roleName')} />
							</Form.Item>
						</Form>
					</Dialog.Body>
					<Dialog.Footer className="dialog-footer">
						<Button onClick={() => this.setState({ dialogVisible: false })}>{'取 消'}</Button>
						<Button type="primary" onClick={this.saveContent} loading={btnLoading}>{'确 定'}</Button>
					</Dialog.Footer>
				</Dialog>

				<Dialog
					title="权限设置"
					visible={treeDialogVisible}
					onCancel={() => this.setState({ treeDialogVisible: false })}
				>
					<Dialog.Body>
						<Loading loading={treeData.loading}>
							<Tree
								ref={e => { this.tree = e }}
								data={perms}
								options={authorityOptions}
								key={authorityKeys}
								isShowCheckbox
								nodeKey="id"
								defaultCheckedKeys={authorityKeys}
								defaultExpandedKeys={treeData.defaultExpandedKeys}
							/>
						</Loading>
					</Dialog.Body>
					<Dialog.Footer className="dialog-footer">
						<Button onClick={() => this.setState({ treeDialogVisible: false })}>{'取 消'}</Button>
						<Button type="primary" onClick={this.getCheckedKeys}>{'确定'}</Button>
					</Dialog.Footer>
				</Dialog>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { list, btnLoading, treeData } = state
	return { list, btnLoading, treeData }
}
const mapDispatchToProps = dispatch => {
	return {
		...bindActionCreators({ sizeChange, currentChange, initSearch, pageRole, deleteRole, addRole, selectRolemenus, updateRolemenus }, dispatch)
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(BlackUser)
