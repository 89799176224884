import React from 'react'
import { Message, Layout, Card } from 'element-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import Time from '@components/setime'
import api from '@api/index'
import '@styles/welcome.less'
import { WEL1 } from '@meta/welcome'
import * as math from 'mathjs'
import apiV2 from '../api/api-v2'
class Welcome extends React.Component {
	static propTypes = {
		history: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired
	}

	static StatisticsDataEmpty = {
		'loanVO': {
			'loanMoney': 0,
			'loanNum': 0,
			'newLoanMoney': 0,
			'newLoanNum': 0,
			'oldLoanMoney': 0,
			'oldLoanNum': 0
		},
		'maturedVO': {
			'sumMoney': 0,
			'sumNum': 0,
			'todayMoney': 0,
			'todayNum': 0
		},
		'repaymentVO': {
			'sumMoney': 0,
			'sumNum': 0,
			'todayMoney': 0,
			'todayNum': 0
		},
		'overdueVO': {
			'sumMoney': 0,
			'sumNum': 0,
			'todayMoney': 0,
			'todayNum': 0
		},
		'paidUpLoanVO': {
			'sumMoney': 0,
			'sumNum': 0,
			'todayMoney': 0,
			'todayNum': 0
		},
		'refinanceVO': {
			'sumMoney': 0,
			'sumNum': 0,
			'todayMoney': 0,
			'todayNum': 0
		},
		'staTodayOrderVo': {
			'applyMoney': 0,
			'applyNum': 0,
			'newApplyMoney': 0,
			'newApplyNum': 0,
			'oldApplyMoney': 0,
			'oldApplyNum': 0
		},
		'todayLoanVO': {
			'loanMoney': 0,
			'loanNum': 0,
			'newLoanMoney': 0,
			'newLoanNum': 0,
			'oldLoanMoney': 0,
			'oldLoanNum': 0
		},
		'todayOrderVo': {
			'actualPayment': 0,
			'borrowerMoney': 0,
			'borrowerNum': 0,
			'registerNum': 0
		},
		'undueVO': {
			'sumMoney': 0,
			'sumNum': 0,
			'todayMoney': 0,
			'todayNum': 0
		},
		userConversionRate: 0,
		riskControlPassRate: 0
	}

	constructor(props) {
		super(props)
		this.state = {
			time: {},
			wel1: [],
			sec1: null,
			sec2: null,
			statisticsData: Welcome.StatisticsDataEmpty
		}
	}
	componentWillMount() {

	}
	componentDidMount() {
		const wel = this.props.router.defaultRouter.filter(item => item.text === '欢迎页')
		if (wel[0].hideInMenu) {
			return false
		}
		this.initData()
		this.initData2()
	}
	search = () => {
		this.initData()
		this.initData2()
	}
	initData = async () => {
		const res = await api.selectHomePageDateApi()
		if (res.success) {
			const arr = []
			let obj = {}
			for (const i in WEL1) {
				obj = {
					id: WEL1[i].id,
					value: res.data[WEL1[i].value] ? res.data[WEL1[i].value] : 0,
					icon: WEL1[i].icon,
					bg: WEL1[i].bg,
					text: WEL1[i].text
				}
				arr.push(obj)
			}
			this.setState({
				wel1: arr,
				sec1: res.data
			}, () => this.getGlobalStatisticsList())
		} else {
			// if(res.msg === '请先登录'){
			// 	// setTimeout(() => {
			// 		this.props.history.push('/login')
			// 	// },1000)
			// }
			// Message.warning(res.msg)
			if (res.msg === '没有权限') {
				console.log('没有权限')
			} else {
				Message.warning(res.msg)
			}
		}


	}

	async getGlobalStatisticsList() {
		const { body } = await apiV2.getGlobalStatisticsList({})
		const statisticsData = body || Welcome.StatisticsDataEmpty
		this.setState({ statisticsData })

		const { registerNum, borrowerNum, borrowerMoney, actualPayment } = statisticsData.todayOrderVo
		const wel1 = this.state.wel1
		wel1[0].value = registerNum
		wel1[1].value = borrowerNum
		wel1[2].value = borrowerMoney
		wel1[3].value = actualPayment
		this.setState({ wel1 })
	}

	initData2 = async () => {
		const res = await api.selectHomePageApi()
		if (res.success) {
			this.setState({
				sec2: res.data
			})
		} else {
			Message.warning(res.msg)
		}
	}
	num = (x, y) => {
		if (x && y) {
			return `${math.round(x / y, 4) * 100}%`
		} else {
			return '0%'
		}
	}
	render() {
		const { router } = this.props
		const { loanVO, maturedVO, overdueVO, paidUpLoanVO, refinanceVO, staTodayOrderVo, repaymentVO,
			todayLoanVO, undueVO, userConversionRate, riskControlPassRate } = this.state.statisticsData

		const { wel1, sec1, sec2, } = this.state

		const wel = router.defaultRouter.filter(item => item.text === '欢迎页')
		if (wel[0].hideInMenu) {
			return ''
		} else {
			return (
				<div>
					{/* <div className="section">
						<Time handleTime={ data => this.setState({ time: data }) }/>
						<Button type="primary" className="margin_left15" onClick={ this.search }>{'查询'}</Button>
					</div> */}
					<div className="section margin-bottom15">
						{/* <h1 className="title">{'统计'}</h1> */}
						<Layout.Row gutter="50" className="margin-bottom15 margin_top15">
							{
								wel1.length > 0 &&
								wel1.map((item) => {
									return (
										<Layout.Col xs="24" sm="24" md="12" lg="6" key={item.id}>
											<div className="sec-bg flex flex-direction_row align-items_center" style={{ 'backgroundImage': `url(${item.bg})` }}>
												<i className={`${item.icon}`} />
												<div className="flex_1">
													<span className="money">{item.value}</span>
													<p className="info">{item.text}</p>
												</div>
											</div>
										</Layout.Col>
									)
								})
							}
						</Layout.Row>
						{
							sec1 !== null &&
							<Layout.Row gutter="20" className="card-row">
								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'今日借款申请'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												{/* 借款申请人数 = 新客申请人数+复借申请人数 */}
												<h1>{staTodayOrderVo.applyNum}</h1>
												<p>{'借款申请人数'}</p>
											</li>
											<li>
												{/* 借款总金额 = 新客借款总金额+复借借款总金额 */}
												<h1>{staTodayOrderVo.applyMoney}</h1>
												<p>{'借款总金额'}</p>
											</li>
											<li>
												<h1>{staTodayOrderVo.newApplyNum}</h1>
												<p>{'新客申请人数'}</p>
											</li>
											<li>
												<h1>{staTodayOrderVo.newApplyMoney}</h1>
												<p>{'新客借款总金额'}</p>
											</li>
											<li>
												<h1>{staTodayOrderVo.oldApplyNum}</h1>
												<p>{'复借申请人数'}</p>
											</li>
											<li>
												<h1>{staTodayOrderVo.oldApplyMoney}</h1>
												<p>{'复借借款总金额'}</p>
											</li>
										</ul>
									</Card>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'所有下单用户'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												{/* 通过订单量 = 新客通过订单量+复借通过订单量 */}
												<h1>{loanVO.loanNum}</h1>
												<p>{'通过订单量'}</p>
											</li>
											<li>
												{/* 通过订单总金额 = 新客通过订单总金额+复借通过订单总金额 */}
												<h1>{loanVO.loanMoney}</h1>
												<p>{'通过订单总金额'}</p>
											</li>
											<li>
												<h1>{loanVO.newLoanNum}</h1>
												<p>{'新客通过订单量'}</p>
											</li>
											<li>
												<h1>{loanVO.newLoanMoney}</h1>
												<p>{'新客通过订单总金额'}</p>
											</li>
											<li>
												<h1>{loanVO.oldLoanNum}</h1>
												<p>{'复借通过订单量'}</p>
											</li>
											<li>
												<h1>{loanVO.oldLoanMoney}</h1>
												<p>{'复借通过订单总金额'}</p>
											</li>
											{/* <li>
												<h1>{sec1.toDayRegisterApply}</h1>
												<p>{'今日注册订单量'}</p>
											</li>
											<li>
												<h1>{sec1.toDayRegisterApplyMoney}</h1>
												<p>{'今日注册订单总金额(包含通过和拒绝)'}</p>
											</li> */}
										</ul>
									</Card>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'今日放款订单 (今日下单用户)'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												{/* 借款申请人数 = 新客申请人数+复借申请人数 */}
												<h1>{todayLoanVO.loanNum}</h1>
												<p>{'借款申请人数'}</p>
											</li>
											<li>
												{/* 借款总金额 = 新客借款总金额+复借借款总金额 */}
												<h1>{todayLoanVO.loanMoney}</h1>
												<p>{'借款总金额'}</p>
											</li>
											<li>
												<h1>{todayLoanVO.newLoanNum}</h1>
												<p>{'新客申请人数'}</p>
											</li>
											<li>
												<h1>{todayLoanVO.newLoanMoney}</h1>
												<p>{'新客借款总金额'}</p>
											</li>
											<li>
												<h1>{todayLoanVO.oldLoanNum}</h1>
												<p>{'复借申请人数'}</p>
											</li>
											<li>
												<h1>{todayLoanVO.oldLoanMoney}</h1>
												<p>{'复借借款总金额'}</p>
											</li>
										</ul>
									</Card>
								</Layout.Col>
							</Layout.Row>
						}
						{sec2 !== null && sec1 !== null &&
							<Layout.Row className="margin-bottom15">
								<Layout.Col xs="24" sm="24" md="12" lg="12">
									<div className="sec-bg flex flex-direction_row align-items_center sec-bg1" style={{ 'width': '60%', 'margin': 'auto' }}>
										<i className="icon iconfont icon-Personal" />
										<div className="flex_1">
											{/* 转化率 = 申请/注册 */}
											<span className="money">{`${userConversionRate}%`}</span>
											<p className="info">{'转化率'}</p>
										</div>
									</div>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="12" lg="12">
									<div className="sec-bg flex flex-direction_row align-items_center sec-bg2" style={{ 'width': '60%', 'margin': 'auto' }}>
										<i className="icon iconfont icon-icon_shakehands" />
										<div className="flex_1">
											<span className="money">{`${riskControlPassRate}%`}</span>
											<p className="info">{'风控通过率 (风控通过数/风控调用数)'}</p>
										</div>
									</div>
								</Layout.Col>
							</Layout.Row>
						}
						{
							sec2 !== null &&
							<Layout.Row gutter="20" className="card-row2">

								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'还款订单'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												<h1>{paidUpLoanVO.todayNum}</h1>
												<p>{'当日还款订单量'}</p>
											</li>
											<li>
												<h1>{paidUpLoanVO.todayMoney}</h1>
												<p>{'当日还款金额'}</p>
											</li>
											<li>
												<h1>{paidUpLoanVO.sumNum}</h1>
												<p>{'总还款订单量'}</p>
											</li>
											<li>
												<h1>{paidUpLoanVO.sumMoney}</h1>
												<p>{'总还款金额'}</p>
											</li>
										</ul>
									</Card>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'续期订单'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												<h1>{refinanceVO.todayNum}</h1>
												<p>{'今日续期订单量'}</p>
											</li>
											<li>
												<h1>{refinanceVO.todayMoney}</h1>
												<p>{'今日续期总金额'}</p>
											</li>
											<li>
												<h1>{refinanceVO.sumNum}</h1>
												<p>{'总续期订单量'}</p>
											</li>
											<li>
												<h1>{refinanceVO.sumMoney}</h1>
												<p>{'总续期总金额'}</p>
											</li>
										</ul>
									</Card>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'线下还款订单'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												<h1>{repaymentVO.todayNum}</h1>
												<p>{'当日线下还款订单量'}</p>
											</li>
											<li>
												<h1>{repaymentVO.todayMoney}</h1>
												<p>{'当日线下还款金额'}</p>
											</li>
											<li>
												<h1>{repaymentVO.sumNum}</h1>
												<p>{'总线下还款订单量'}</p>
											</li>
											<li>
												<h1>{repaymentVO.sumMoney}</h1>
												<p>{'总线下还款金额'}</p>
											</li>
										</ul>
									</Card>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'到期订单'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												<h1>{maturedVO.todayNum}</h1>
												<p>{'当日到期订单数'}</p>
											</li>
											<li>
												<h1>{maturedVO.todayMoney}</h1>
												<p>{'当日到期金额'}</p>
											</li>
											<li>
												<h1>{maturedVO.sumNum}</h1>
												<p>{'总到期订单数'}</p>
											</li>
											<li>
												<h1>{maturedVO.sumMoney}</h1>
												<p>{'总到期金额'}</p>
											</li>
											<li>
												<h1>{maturedVO.newNum}</h1>
												<p>{'新客数量'}</p>
											</li>
											<li>
												<h1>{maturedVO.newMoney}</h1>
												<p>{'新客金额'}</p>
											</li>
										</ul>
									</Card>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'未到期订单'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												<h1>{undueVO.sumNum}</h1>
												<p>{'未到期订单量'}</p>
											</li>
											<li>
												<h1>{undueVO.sumMoney}</h1>
												<p>{'未到期订单金额'}</p>
											</li>
											<li>
												<h1>{undueVO.newNum}</h1>
												<p>{'新客数量'}</p>
											</li>
											<li>
												<h1>{undueVO.newMoney}</h1>
												<p>{'新客金额'}</p>
											</li>
										</ul>
									</Card>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="8" lg="8">
									<Card
										header={
											<div className="clearfix">
												<span>{'逾期订单'}</span>
											</div>
										}
									>
										<ul className="card-body flex flex-direction_row flex-wrap">
											<li>
												<h1>{overdueVO.sumNum}</h1>
												<p>{'逾期订单订单量'}</p>
											</li>
											<li>
												<h1>{overdueVO.sumMoney}</h1>
												<p>{'逾期订单订单金额'}</p>
											</li>
											<li>
												<h1>{overdueVO.newNum}</h1>
												<p>{'新客数量'}</p>
											</li>
											<li>
												<h1>{overdueVO.newMoney}</h1>
												<p>{'新客金额'}</p>
											</li>
										</ul>
									</Card>
								</Layout.Col>
							</Layout.Row>
						}
					</div>
				</div>
			)
		}
	}
}
const mapStateToProps = state => {
	const { router } = state
	return { router }
}
export default connect(mapStateToProps)(Welcome)