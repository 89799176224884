import moment from 'moment'

// 主题配置
export const ThemeConfig = {
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR,
    figureTextColor: process.env.REACT_APP_THEME_FIGURE_TEXT_COLOR,
    figureTextSize: process.env.REACT_APP_THEME_FIGURE_TEXT_SIZE,
    percentageTextColor: process.env.REACT_APP_PERCENTAGE_TEXT_COLOR,
    percentageTextSize: process.env.REACT_APP_PERCENTAGE_TEXT_SIZE,
    // 辅助色
    primaryColorLight: process.env.REACT_APP_PRIMARY_COLOR_LIGHT,
    primaryColorWeigt: process.env.REACT_APP_PRIMARY_COLOR_WEIGHT
}

/**
 * 可视化文件大小
 * @param {*} fileSize 文件大小：字节
 * @returns 文件大小+单位
 */
export function formatFileSize(fileSize) {
    if (fileSize < 1024) {
        return fileSize + 'B'
    } else if (fileSize < (1024 * 1024)) {
        let temp = fileSize / 1024
        temp = temp.toFixed(2)
        return temp + 'KB'
    } else if (fileSize < (1024 * 1024 * 1024)) {
        let temp = fileSize / (1024 * 1024)
        temp = temp.toFixed(2)
        return temp + 'MB'
    } else {
        let temp = fileSize / (1024 * 1024 * 1024)
        temp = temp.toFixed(2)
        return temp + 'GB'
    }
}


/**
 * 枚举对象转list
 * @param {*} object enum
 * @returns  list
 */
export function objectToArray(object) {
    const arr = []
    for (const keyName of Object.keys(object)) {
        const item = object[keyName]
        item.key = keyName
        arr.push(item)
    }
    return arr
}


/**
 * 时长可视化
 * @param {*} durationNumber 时长
 * @param {*} unit 单位：默认秒
 * @returns 长度+单位
 */
export function durationFormat(durationNumber = 0, unit = 'seconds') {
    const duration = moment.duration(durationNumber, unit)

    const years = duration.years()
    const months = duration.months()
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    let string = ''

    if (years) {
        string += `${years}年`
    }

    if (months) {
        string += `${months}月`
    }

    if (days) {
        string += `${days}日`
    }

    if (hours) {
        string += `${hours}时`
    }
    if (minutes) {
        string += `${minutes}分`
    }
    if (seconds) {
        string += `${seconds}秒`
    }

    return string || 0
}


/**
 * 统计金额样式
 */
export const MoneyStatisticsStyle = {
    color: ThemeConfig.percentageTextColor,
    fontSize: ThemeConfig.percentageTextSize
}

export function moneyFormat(value) {
    return value || 0
}


export function formatDatePeriod(dateArr) {
    const format = 'YYYY-MM-DD'
    let start = null, end = null
    if (dateArr && dateArr.length > 1) {
        start = moment(dateArr[0]).format(format)
        end = moment(dateArr[1]).format(format)
    }

    return [start, end]
}

export function datesAdapterElUI(date1String, date2String) {
    if (!date1String || !date2String) return [null, null]
    return [new Date(date1String), new Date(date2String)]
}

export function dateAdapterElUI(date1String) {
    if (!date1String) return null
    return new Date(date1String)
}