import React, { Component } from 'react'

import { Statistic } from 'antd'
import PropTypes from 'prop-types'

/**
 * 认证配置
 */
export default class AuthConfig extends Component {
    static propTypes = {
        title: PropTypes.any,
        value: PropTypes.any,
        prefix: PropTypes.any,
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    render() {
        return (<Statistic title={this.props.title} prefix={this.props.prefix}
            valueStyle={{ fontSize: '0.7rem', fontWeight: 100 }}
            value={this.props.value || ' '} />)
    }
}